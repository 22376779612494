<template>
    <div class="row">
        <div class="col-lg-4 col-md-6 offset-lg-4 offset-md-3">
            <div v-if="error" class="background-pink error mb-2">{{ error }}</div>
            <div v-if="!created" class="card card-dark" @keydown.enter="sendLink()">
                <div class="title">
                    Reset password
                </div>
                <div class="card-body">
                    <form class="form">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <div class="input-group-text">
                                    <fai :icon="['fas', 'envelope']"></fai>
                                </div>
                            </div>
                            <input type="email" class="form-control" placeholder="Your email" v-model="email" autocomplete="email">
                        </div>
                    </form>
                </div>
                <button class="btn btn-primary mt-3" @click="sendLink()">
                    <span v-if="!isWaitingForResult">Send reset link</span>
                    <fai v-if="isWaitingForResult" :icon="['fas', 'spinner']" spin></fai>
                </button>
            </div>
            <div v-if="created" class="card card-dark">
                <div class="card-body">
                    Reset link sent to address: <span class="color-pink">{{ email }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import requests from './../api/requests.js'
    import { setMetadata } from '../utils.js'

    export default {
        name: 'CreatePasswordResetPage',
        data() {
            return {
                error: null,
                email: "",
                isWaitingForResult: false,
                created: false
            }
        },
        methods: {
            async sendLink() {
                if (!this.isWaitingForResult) {
                    this.error = null
                    this.isWaitingForResult = true

                    const result = await requests.UserAccess.PasswordResets.CreatePasswordReset(this.email, this.getResetLink())

                    if (result.isSuccess) {
                        this.created = true
                    } else {
                        this.error = result.error
                    }

                    this.isWaitingForResult = false
                }
            },
            getResetLink() {
                let host = window.location.host.replace("www.", "")

                if (host.includes("localhost"))
                    return "http://localhost:8001/password-reset/"

                return "https://" + host + "/password-reset/"
            }
        },
        async mounted() {
            requests.initialize(this.$store) 
            setMetadata("Reset password | AudioLove", "If you forgot your password, you can change it here.")
        }
    }
</script>

<style scoped>
    .error {
        border-radius: 5px;
        padding: 10px;
    }

    .title {
        color: var(--primary-color);
        font-size: 28px;
        font-weight: 700;
        padding-bottom: 24px;
        letter-spacing: 4px;
    }
</style>