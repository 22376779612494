<template>
    <div class="banner-container">
        <DiscordBanner />
    </div>
    <div class="select-category mt-3">
        Select Category
    </div>
    <div v-if="isWaitingForResult" class="mt-4 center-row">
        <div class="dot-flashing"></div>
    </div>
    <div v-if="categories && genderPreferences" class="categories-table" role="list" aria-label="categories">
        <CategoryCard v-for="genderPreference in genderPreferences" :tag="genderPreference"></CategoryCard>
        <CategoryCard v-for="category in categories" :tag="category"></CategoryCard>
    </div>
</template>

<script>
    import requests from './../api/requests.js'
    import CategoryCard from './CategoryCard.vue'
    import DiscordBanner from './DiscordBanner.vue'
    import { setMetadata } from '../utils.js'

    export default {
        name: 'CategoriesPage',
        components: {
            CategoryCard,
            DiscordBanner
        },
        data() {
            return {
                genderPreferences: null,
                categories: null,
                isWaitingForResult: false
            }
        },
        async mounted() {
            setMetadata("Categories | AudioLove", "Browse through our library of audio porn categories.")

            this.isWaitingForResult = true

            const response = await requests.UserContent.Tag.GetAll()
            if (response.isSuccess) {
                this.genderPreferences = response.tags.filter(x => x.isGenderPreference)
                this.categories = response.tags.filter(x => x.isCategory && !x.isGenderPreference)
            }

            this.isWaitingForResult = false
        }
    }
</script>

<style scoped>
    .categories-table {
        display: flex;
        flex-flow: row wrap;
        row-gap: 1rem;
        column-gap: 2%;
        justify-content: space-around;
    }

        .categories-table div {
            cursor: pointer;
            flex-basis: 49%;
        }

    @media only screen and (min-width: 60rem) {
        .categories-table {
            display: flex;
            flex-flow: row wrap;
        }

            .categories-table div {
                flex-basis: 32%;
            }
    }

    @media only screen and (min-width: 80rem) {
            .categories-table div {
                flex-basis: 23%;
            }
    }

    @media only screen and (min-width: 90rem) {
        .categories-table div {
            flex-basis: 18%;
        }
    }

    .center-row {
        display: flex;
        justify-content: center;
    }

    .select-category {
        font-size: 28px;
        font-weight: 600;
        line-height: 42px;
        margin-bottom: 24px;
    }
</style>