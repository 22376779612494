<template>
    <table>
        <div v-if="reports == null" class="center-row">
            <div class="dot-flashing"></div>
        </div>

        <div v-if="reports != null && reports.length <= 0">
            There are no reports
        </div>

        <tr v-if="reports != null && reports.length > 0">
            <th>Audio</th>
            <th>Author</th>
            <th>Report text</th>
            <th>Date</th>
        </tr>

        <tr v-if="reports != null" v-for="report in reports">
            <td class="cursor-pointer hover-pink" @click="redirectToAudio(report.audio.id)">{{ report.audio.name }}</td>
            <td class="cursor-pointer hover-pink" @click="redirectToProfile(report.audio.profile.handleName)">{{ report.audio.profile.name }}</td>
            <td>{{ report.text }}</td>
            <td>{{ createdDate(report.created) }}</td>
            <td class="center-row">
                <fai class="cursor-pointer hover-pink" :icon="['fas', 'circle-info']" @click="showDetail(report.id)"></fai>
            </td>
        </tr>
    </table>
</template>

<script>
    import requests from './../api/requests.js'
    import { formatDate } from './../utils.js'
    import Swal from 'sweetalert2'

    export default {
        name: 'ReportsTable',
        components: {

        },
        props: {

        },
        data() {
            return {
                reports: null
            }
        },
        methods: {
            createdDate(date) {
                return formatDate(date)
            },
            redirectToAudio(audioId) {
                this.$router.push({ name: 'Audio', params: { id: audioId } })
            },
            redirectToProfile(handle) {
                this.$router.push({ name: 'Profile', params: { handle: handle } })
            },
            showDetail(reportId) {
                const report = this.reports.find(x => x.id == reportId)

                Swal.fire({
                    title: report.audio.name,
                    text: report.text,
                    showCancelButton: true,
                    confirmButtonText: 'Resolved',
                    showLoaderOnConfirm: true,
                    background: "#262639",
                    color: "white",
                    customClass: {
                        confirmButton: 'dialog-btn-primary',
                        denyButton: 'dialog-btn-primary',
                        cancelButton: 'dialog-btn-secondary'
                    },
                    buttonsStyling: false,
                    preConfirm: async () => {
                        const response = await requests.UserContent.Report.ResolveReport(report.id)

                        if (response.isSuccess) {
                            Swal.fire({
                                icon: 'success',
                                timer: 1000,
                                showConfirmButton: false,
                                background: "#262639",
                                color: "white",
                            })

                            await this.loadReports()
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Something went wrong!',
                                background: "#262639",
                                color: "white",
                            })
                        }
                    }
                })
            },
            async loadReports() {
                const response = await requests.UserContent.Report.GetPendingReportsSkipTake(0, 10)
                if (response.isSuccess) {
                    this.reports = response.reports
                }
            }
        },
        computed: {
            
        },
        async mounted() {
            requests.initialize(this.$store)

            await this.loadReports()
        }
    }
</script>

<style scoped>
    table {
        border-collapse: collapse;
        width: 100%;
        padding: 1rem;
    }

        table th {
            color: #e14eca;
        }
    
    .hover-pink {
        transition: color ease-in-out 0.25s;
    }

    .hover-pink:hover {
        color: #e14eca;
    }

    .center-row {
        display: flex;
        justify-content: center;
    }
</style>