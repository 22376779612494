<template>
    <div v-if="error">
        <h3 class="text-center">{{ error }}</h3>
    </div>
    <div v-if="audio">
        <AudioPlayer :audio="audio" :profile="profile"></AudioPlayer>
        <div class="mt-3">
            <DiscordBanner />
            <!--<SurveyBanner />-->
        </div>
        <div class="description card card-dark mt-3">
            <div class="card-body">
                <div class="description-upper">
                    <div>
                        <ul v-if="audio.isInsidePlatform || isAdmin">
                            <li>
                                <img src="/icons/music.svg" alt="music icon" />&nbsp;
                                {{ audio.listenCount }}&nbsp;Listens
                            </li>
                            <li>
                                <img src="/icons/heart.svg" alt="heart icon" />&nbsp;
                                {{ audio.upvoteCount }}&nbsp;Likes
                            </li>
                            <li>
                                <img src="/icons/calendar.svg" alt="calendar icon" />&nbsp;
                                Upload&nbsp;Date: {{ uploaded }}
                            </li>
                        </ul>
                        <div v-if="!audio.isInsidePlatform" class="not-inside-platform-texts">
                            <span class="color-orange">
                                <img src="/icons/lovely-orange.svg" width="24" height="24" alt="AudioLove logo" />
                                This audio is hosted outside of AudioLove. We can't guarantee your experience.
                            </span>
                            <span class="color-orange">
                                <img src="/icons/dollar-circle-orange.svg" width="24" height="24" alt="dollar logo" />
                                If you enjoy this creator's content be sure to support them!
                            </span>
                        </div>
                    </div>

                    <div v-if="hasAnySocial" class="socials">
                        <a v-if="profile.socials.reddit" :href="'https://reddit.com/user/' + profile.socials.reddit" target="_blank"><img src="/icons/reddit.svg" title="Reddit" alt="reddit icon" /></a>
                        <a v-if="profile.socials.throne" :href="'https://throne.com/' + profile.socials.throne" target="_blank"><img src="/icons/throne.svg" title="Throne" alt="throne icon" /></a>
                        <a v-if="profile.socials.youtube" :href="'https://youtube.com/' + profile.socials.youtube" target="_blank"><img src="/icons/youtube.svg" title="Youtube" alt="youtube icon" /></a>
                        <a v-if="profile.socials.instagram" :href="'https://instagram.com/' + profile.socials.instagram" target="_blank"><img src="/icons/instagram.svg" title="Instagram" alt="instagram icon" /></a>
                        <a v-if="profile.socials.tiktok" :href="'https://tiktok.com/' + profile.socials.tiktok" target="_blank"><img src="/icons/tiktok.svg" title="Tiktok" alt="tiktok icon" /></a>
                        <a v-if="profile.socials.twitter" :href="'https://twitter.com/' + profile.socials.twitter" target="_blank"><img src="/icons/twitter.svg" title="Twitter" alt="twitter icon" /></a>
                        <a v-if="profile.socials.patreon" :href="'https://patreon.com/' + profile.socials.patreon" target="_blank"><img src="/icons/patreon.svg" title="Patreon" alt="patreon icon" /></a>
                        <a v-if="profile.socials.discord" :href="'https://discord.com/invite/' + profile.socials.discord" target="_blank"><img src="/icons/discord.svg" title="Discord" alt="discord icon" /></a>
                    </div>
                </div>
                <div class="description-lower">
                    <h3 v-if="audio.description">
                        {{ audio.description }}
                    </h3>
                    <div class="like-btn-container">
                        <button :class="['btn', 'btn-primary', isUserUpvoted ? 'liked' : '']" @click="isLogged ? isUserUpvoted ? unvote() : upvote() : registerPrompt()">
                            {{ (isUserUpvoted ? 'Unlike' : 'Like') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="tags mt-3 card card-dark categories-card" role="group" aria-label="Categories">
            <div v-if="categories && categories.length > 0">
                <span>Categories</span>
                <TagDisplayContainer class="mt-2" :tags="categories" :redirect="true"></TagDisplayContainer>
            </div>
            <div v-if="audio.tags && audio.tags.length > 0" class="mt-2">
                <span>Tags</span>
                <TagDisplayContainer class="mt-2" :tags="audio.tags" :redirect="true"></TagDisplayContainer>
            </div>
        </div>
        <div class="mt-3">
            <CommentContainer :audio="audio"></CommentContainer>
        </div>
    </div>
</template>

<script>
    import AudioPlayer from './AudioPlayer.vue'
    import CommentContainer from './CommentContainer.vue'
    import TagDisplayContainer from './TagDisplayContainer.vue'
    import DiscordBanner from './DiscordBanner.vue'
    import SurveyBanner from './SurveyBanner.vue'
    import requests from './../api/requests.js'
    import Swal from 'sweetalert2'
    import { formatDate, setMetadata } from './../utils.js'

    export default {
        name: 'AudioPage',
        components: {
            AudioPlayer,
            CommentContainer,
            TagDisplayContainer,
            DiscordBanner,
            SurveyBanner
        },
        data() {
            return {
                audio: null,
                categories: null,
                profile: null,
                error: null,
                isUserUpvoted: false
            }
        },
        computed: {
            isLogged() {
                return this.$store.getters.userData != null
            },
            isAdmin() {
                if (this.$store.getters.userData) {
                    return this.$store.getters.userData.user.isAdmin
                }

                return false
            },
            isLoggedUsersAudio() {
                if (this.$store.getters.userData == null) {
                    return false
                }

                return this.$store.getters.userData.userProfileId == this.profile.id
            },
            uploaded() {
                return formatDate(this.audio.created)
            },
            hasAnySocial() {
                return this.profile.socials.reddit ||
                    this.profile.socials.throne ||
                    this.profile.socials.youtube ||
                    this.profile.socials.instagram ||
                    this.profile.socials.tiktok ||
                    this.profile.socials.twitter ||
                    this.profile.socials.patreon ||
                    this.profile.socials.discord
            }
        },
        methods: {
            async getAudio() {
                const response = await requests.UserContent.Audio.GetAudioByIdIncludeProfile(this.$route.params.id)

                if (response.isSuccess) {
                    this.audio = response.audio
                    this.profile = response.userProfile

                    this.categories = this.audio.genderPreferences

                    for (const category of this.audio.categories) {
                        this.categories.push(category)
                    }

                    const keywords = this.categories.map(x => x.name).join(', ')
                    setMetadata(this.audio.name + " | AudioLove", this.audio.description, this.profile.name, keywords)
                } else {
                    this.error = response.error
                }
            },
            async refreshUserHistory() {
                const historyResult = await requests.UserContent.UserHistory.GetUserHistoryById(this.$store.getters.userData.user.id)

                if (historyResult.isSuccess) {
                    this.$store.commit("setUserHistory", historyResult.userHistory)
                }
            },
            async upvote() {
                this.audio.upvoteCount += 1
                this.isUserUpvoted = true
                const result = await requests.UserContent.Audio.UpvoteAudio(this.audio.id)
                this.audio.upvoteCount -= 1

                if (result.isSuccess) {
                    this.audio.upvoteCount += result.upvoteChange
                    await this.refreshUserHistory()
                } else {
                    this.isUserUpvoted = false
                }
            },
            async unvote() {
                this.audio.upvoteCount -= 1
                this.isUserUpvoted = false
                const result = await requests.UserContent.Audio.UnvoteAudio(this.audio.id)
                this.audio.upvoteCount += 1

                if (result.isSuccess) {
                    this.audio.upvoteCount += result.upvoteChange
                    await this.refreshUserHistory()
                } else {
                    this.isUserUpvoted = true
                }
            },
            registerPrompt() {
                Swal.fire({
                    position: 'center',
                    title: 'Register',
                    text: 'You must have an account to like.',
                    confirmButtonText: 'Register',
                    showCancelButton: true,
                    cancelButtonText: 'Cancel',
                    background: "#262639",
                    color: "white",
                    customClass: {
                        confirmButton: 'dialog-btn-primary',
                        denyButton: 'dialog-btn-primary',
                        cancelButton: 'dialog-btn-secondary'
                    },
                    buttonsStyling: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$router.push('/register')
                    }
                })
            }
        },
        async mounted() {
            requests.initialize(this.$store)    

            await this.getAudio()

            if (this.isLogged && this.audio != null) {
                await this.refreshUserHistory()
                this.isUserUpvoted = this.$store.getters.userHistory.upvotes.some(x => x.targetId === this.audio.id && x.count > 0)
            }
        }
    }
</script>

<style scoped>
    .description-upper {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        gap: 16px;
    }

    @media screen and (max-width: 75rem) {
        .description-upper {
            flex-flow: column;
        }
    }

    .tags {
        padding: 1rem;
        margin-top: 0.4rem;
    }

    .description-lower {
        margin-top: 24px;
        color: #ADADCB;
        white-space: pre-wrap;
    }

    .description-lower h3 {
        font-size: 16px;
        font-weight: 400;
    }

    .description-upper ul {
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        list-style-type: none;
        gap: 24px;
    }

        .description-upper ul li {
            display: flex;
            color: #FFFFFF;
            font-size: 16px;
        }

    @media screen and (max-width: 60rem) {
        .description-upper ul {
            flex-direction: column;
        }
    }

    .socials {
        display: flex;
        flex-direction: row;
        justify-content: start;
        gap: 0.5rem;
        margin-bottom: 1rem;
    }

        .socials img {
            width: 24px;
            cursor: pointer;
        }

    .like-btn-container {
        padding-top: 24px;
        display: flex;
        justify-content: flex-end;
    }

        .like-btn-container button {
            width: 160px;
        }

        .like-btn-container button.liked {
            background-color: transparent !important;
            border: 1px var(--primary-color) solid !important;
            color: var(--primary-color) !important;
        }

    @media screen and (max-width: 60rem) {
        .like-btn-container button {
            width: 100%;
        }
    }

    .categories-card {
        color: white;
    }

    .banner-container {

    }

    .not-inside-platform-texts {
        display: flex;
        flex-flow: column;
        gap: 8px;
    }
</style>