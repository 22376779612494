<template>
    <div>
        <div v-show="error" class="background-pink error mb-2">{{ error }}</div>
        <div v-show="!next" class="upload-page">
            <h4>
                Select Audio File
            </h4>
            <div class="card card-dark">
                <div class="card-body">
                    <div @dragenter.prevent="toggleActive()"
                         @dragleave.prevent="toggleActive()"
                         @dragover.prevent=""
                         @drop.prevent="drop"
                         class="dropzone"
                         :class="{ 'dropzone-active': active || file }">

                        <div v-if="!file" class="dropzone-content">
                            <div class="mb-3 dropzone-title">Drag & Drop your audio file</div>
                            <div class="mb-3 dropzone-or">OR</div>
                            <label for="dropzoneFile" class="btn btn-primary dropzone-file">Select To Upload File</label>
                            <input type="file" id="dropzoneFile" @change="change" accept="audio/*" />
                        </div>
                        <div v-if="file" class="dropzone-content">
                            <span>
                                {{ file.name }}
                            </span>
                            <fai :icon="['fas', 'xmark']" size="lg" class="pull-right close-icon" @click="removeFile()"></fai>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="next && !uploading">
            <h4>
                Describe The File
            </h4>
            <div class="card card-dark">
                <div class="card-body">
                    <form class="form">
                        <label class="color-white">Name</label>
                        <div class="form-group mb-1">
                            <input type="text" class="form-control" v-model="name" maxlength="128">
                        </div>
                        <label class="color-white mt-1">Description</label>
                        <div class="form-group mb-3">
                            <textarea class="form-control description" v-model="description" style="min-height: 5.5rem;" maxlength="2000"></textarea>
                        </div>
                        <div class="form-check text-left">
                            <label class="form-check-label">
                                <input class="form-check-input" type="checkbox" v-model="isPrivate">
                                <span class="form-check-sign"></span>
                                Private audio
                            </label>
                        </div>
                    </form>
                </div>
            </div>
            <h4 class="mt-5">
                Pick Tags
            </h4>
            <div class="card card-dark mt-3">
                <div class="card-body">
                    <TagSelectContainer v-if="tags"
                                        :tags="tags"
                                        :maxSelectedGenderPreferencesCount="1"
                                        :maxSelectedCategoriesCount="6"
                                        :maxSelectedTagsCount="15"
                                        @statusesChanged="statusesChanged"></TagSelectContainer>

                    <div v-if="isWaitingForTagResponse" class="mt-2 mb-2 center-row">
                        <div class="dot-flashing"></div>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="uploading">
            <div class="card card-dark">
                <div class="card-header header-twin">
                    <div class="card-title">
                        <div>
                            <h4 :class="{ 'color-primary': progress < 100 && !uploadSuccess }">Upload</h4>
                        </div>
                        <div v-if="progress >= 100">
                            <fai :icon="['fas', 'check']" class="color-primary" size="lg"></fai>
                        </div>
                    </div>
                    <div class="card-title">
                        <h4 :class="{ 'color-pink': progress >= 100 && (!uploadSuccess || status == 1) }">Process</h4>
                        <fai v-if="status == 2" :icon="['fas', 'check']" class="color-primary" size="lg"></fai>
                        <fai v-if="status == 3" :icon="['fas', 'xmark']" class="color-primary" size="lg"></fai>
                    </div>
                </div>
                <div class="card-body">
                    <div v-if="!uploadSuccess && progress < 100">
                        <div class="center mb-2">
                            <span>
                                Uploading the file.
                            </span>
                            <span>
                                Please wait...
                            </span>
                        </div>
                        <div class="progress">
                            <div class="progress-bar" role="progressbar" :style="{ width: progress + '%', height: '20px' }" :aria-valuenow="Math.round(progress)" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                    <div v-if="progress >= 100 && (!status || status == 1)" class="center">
                        <span>
                            Processing the file.
                        </span>
                        <span>
                            You can safely leave now. This can take a while...
                        </span>
                        <div class="mt-2 mb-2">
                            <div class="dot-flashing"></div>
                        </div>
                        <div class="mt-2 mb-2 upload-another">
                            <button class="btn btn-secondary btn-sm" @click="uploadAnother()">Upload another</button>
                        </div>
                    </div>
                    <div v-if="uploadSuccess && status == 2" class="center">
                        <span class="mb-3">
                            Audio successfully uploaded :)
                        </span>
                        <div class="button-row upload-another">
                            <button class="btn btn-primary" @click="goToFile()">Audio</button>
                            <button class="btn btn-secondary" @click="uploadAnother()">Upload another</button>
                        </div>
                    </div>
                    <div v-if="uploadSuccess && status == 3" class="center">
                        <span class="mb-3">
                            Audio processing failed :(
                        </span>
                        <span>
                            Please try again later.
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!isPublicProfile" class="private-profile mt-2">
            <fai :icon="['fas', 'circle-exclamation']" />
            Profile is private. Nobody will see the audio until you set it public in settings.
        </div>

        <div class="button-row mt-3 pb-1">
            <button v-if="!uploading" class="btn btn-primary btn-lg btn-previous" :disabled="!next" @click="next=false">Previous</button>
            <button v-if="next && !uploading" class="btn btn-primary btn-lg" :disabled="selectedGenderPreferenceIds.length < 1" @click="upload()">Upload</button>
            <button v-if="!next && !uploading" class="btn btn-primary btn-lg" :disabled="!file" @click="nextPage()">Next</button>
        </div>
    </div>
</template>

<script>
    import requests from './../api/requests.js'
    import Swal from 'sweetalert2'
    import TagSelectContainer from './TagSelectContainer.vue'
    import { BlockBlobClient } from "@azure/storage-blob";
    import { setMetadata } from '../utils.js'

    export default {
        name: 'UploadPage',
        components: {
            TagSelectContainer
        },
        data() {
            return {
                error: null,
                active: false,
                file: null,
                next: false,
                uploading: false,
                uploadSuccess: null,
                progress: 0,
                name: null,
                description: null,
                uploadedAudioId: null,
                status: null,
                polling: null,
                tags: null,
                isWaitingForTagResponse: false,
                selectedGenderPreferenceIds: [],
                selectedCategoryIds: [],
                selectedTagIds: [],
                isPrivate: false,
                isPublicProfile: true
            }
        },
        computed: {
            user() {
                if (this.$store.getters.userData == null) {
                    return null
                }

                return this.$store.getters.userData.user
            }
        },
        methods: {
            toggleActive() {
                this.active = !this.active
            },
            drop(event) {
                this.error = null
                this.setFile(event.dataTransfer.files[0])
            },
            change(event) {
                this.error = null
                this.setFile(event.target.files[0])
            },
            setFile(file) {
                if (!file.type.includes("audio/")) {
                    this.error = "File must be in audio format."
                    return
                }

                if (file.size > 500_000_000) {
                    this.error = "File cannot exceed 500MB."
                    return
                }

                this.file = file
            },
            removeFile() {
                this.file = null
                this.active = false
            },
            async upload() {
                if (this.selectedGenderPreferenceIds.length < 1) {
                    return
                }

                this.uploading = true                

                const result = await requests.UserContent.Audio.UploadAudio(
                    this.name,
                    this.description,
                    this.file.name,
                    this.file.type,
                    {
                        tag: this.selectedTagIds,
                        category: this.selectedCategoryIds,
                        genderPreference: this.selectedGenderPreferenceIds
                    },
                    !this.isPrivate)

                if (result && result.isSuccess) {
                    const blockBlobClient = new BlockBlobClient(result.uploadUrl)
                    const blockSize = 4 * 1024 * 1024 // 4MB block size
                    const options = {
                        blockSize,
                        onProgress: this.uploadProgress
                    }

                    try {

                        await blockBlobClient.uploadBrowserData(this.file, options)

                    } catch {
                        this.uploadSuccess = false
                        this.error = "Uploading failed."
                        return
                    }
                    
                    this.uploadedAudioId = result.audioId

                    await this.checkStatus()

                    this.polling = setInterval(async () => {
                        await this.checkStatus()
                    }, 3000)

                    this.uploadSuccess = true
                } else if (result && !result.isSuccess) {
                    this.uploadSuccess = false
                    this.error = result.error
                }
            },
            nextPage() {
                this.name = this.file.name
                this.next = true
            },
            uploadProgress(event) {
                this.progress = event.loadedBytes / this.file.size * 100
            },
            goToFile() {
                this.$router.push('/audio/' + this.uploadedAudioId)
            },
            async uploadAnother() {
                this.error = null
                this.active = false
                this.file = null
                this.next = false
                this.uploading = false
                this.uploadSuccess = null
                this.progress = 0
                this.name = null
                this.description = null
                this.uploadedAudioId = null
                this.status = null
                this.isWaitingForTagResponse = false
                this.selectedGenderPreferenceIds = []
                this.selectedCategoryIds = []
                this.selectedTagIds = []
                this.isPrivate = false
                this.isPublicProfile = true

                if (this.polling) {
                    clearInterval(this.polling)
                    this.polling = null
                }

                await this.loadTags()
            },
            async checkStatus() {
                const result = await requests.UserContent.Audio.GetAudioStatusById(this.uploadedAudioId)
                if (result && result.isSuccess) {
                    this.status = result.status

                    if (this.status > 2) {
                        clearInterval(this.polling)
                        this.checkIsCreator()
                    }
                } 
            },
            async checkIsCreator() {
                const result = await requests.UserAccess.Users.GetUserById(this.user.id)
                if (result && result.isSuccess) {
                    const userData = this.$store.getters.userData

                    if (!this.user.isCreator) {
                        userData.user = result.user
                        this.$store.commit("setUserData", userData)

                        if (this.user.isCreator) {
                            Swal.fire({
                                position: 'center',
                                title: 'Analytics unlocked!',
                                text: 'They will become very usefull later ;)',
                                confirmButtonText: 'Check it out',
                                cancelButtonText: 'Later',
                                showCancelButton: true,
                                background: "#262639",
                                color: "white",
                                customClass: {
                                    confirmButton: 'dialog-btn-primary',
                                    denyButton: 'dialog-btn-primary',
                                    cancelButton: 'dialog-btn-secondary'
                                },
                                buttonsStyling: false,
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    this.$router.push('/analytics')
                                }
                            })
                        }
                    }
                }
            },
            statusesChanged(tags, categories, genderPreferences) {
                const selectedGenderPreferenceIds = []
                const selectedCategoryIds = []
                const selectedTagIds = []

                for (const genderPreference of genderPreferences) {
                    if (genderPreference.status == "SELECTED") {
                        selectedGenderPreferenceIds.push(genderPreference.tag.id)
                    }
                }

                for (const category of categories) {
                    if (category.status == "SELECTED") {
                        selectedCategoryIds.push(category.tag.id)
                    }
                }

                for (const tag of tags) {
                    if (tag.status == "SELECTED") {
                        selectedTagIds.push(tag.tag.id)
                    }
                }

                this.selectedGenderPreferenceIds = selectedGenderPreferenceIds
                this.selectedCategoryIds = selectedCategoryIds
                this.selectedTagIds = selectedTagIds
            },
            async loadTags() {
                this.isWaitingForTagResponse = true

                this.tags = null
                const tagsResponse = await requests.UserContent.Tag.GetAll()
                if (tagsResponse.isSuccess) {
                    this.tags = tagsResponse.tags
                }

                this.isWaitingForTagResponse = false
            },
        },        
        async mounted() {
            if (this.user == null) {
                this.$router.push('/')
                return
            }

            requests.initialize(this.$store)
            setMetadata("Upload | AudioLove", "Upload your creations. AudioLove is the best place to host your recordings.")

            await this.loadTags()

            const profileResult = await requests.UserContent.UserProfiles.GetUserProfileByUserId(this.user.id)

            if (profileResult && profileResult.isSuccess) {
                this.isPublicProfile = profileResult.userProfile.isPublic
            } 
        },
        beforeUnmount() {
            if (this.polling) {
                clearInterval(this.polling)
            }
        }
    }
</script>

<style scoped>
    div.card-title {
        display: flex;
        flex-flow: row;
        justify-content: center;
        align-items: center;
        column-gap: 0.2rem;
    }

    div.card-title svg {
        margin-bottom: 0.5rem;
    }

    .dropzone {
        border: 2px dashed var(--secondary-color);
        border-radius: 15px;
        padding: 1rem;
        color: white;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
    }

        .dropzone input {
            display: none;
        }

    .dropzone-title {
        font-size: 24px;
        line-height: 36px;
        font-weight: 600;
    }

    .dropzone-or {
        font-size: 24px;
        line-height: 36px;
        font-weight: 400;
        color: var(--secondary-color)
    }

    .dropzone-file {
        background-color: var(--primary-color-transparent) !important;
        border: 1px var(--primary-color) solid !important;
        color: var(--primary-color) !important;
    }

    .dropzone-content {
        min-height: 330px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
    }

        .dropzone-content span {
            font-size: 24px;
            line-height: 36px;
            font-weight: 600;
            margin-bottom: 16px;
        }

    .dropzone-active,
    .dropzone:hover{
        border: 2px dashed var(--primary-color);
    }

    .close-icon {
        cursor: pointer;
        transition: color ease-in-out 0.2s;
    }

        .close-icon:hover {
            color: var(--primary-color);
        }

    .error {
        border-radius: 5px;
        padding: 10px;
        font-weight: 700;
    }

    .private-profile {
        padding: 10px;
        font-size: 16px;
        font-weight: 400;
        color: var(--color-orange);
    }

    .button-row {
        display: flex;        
        flex-flow: row;
        justify-content: space-between;
        column-gap: 0.5rem;
    }

    .button-row button {
        width: 100%;
    }

    .button-row.upload-another {
        width: 80%;
        padding-top: 16px;
        padding-bottom: 16px;
    }

        .button-row.upload-another button {
            padding: 8px 16px 8px 16px;
        }

    .center {
        display: flex;
        flex-flow: column;
        align-items: center;
        text-align: center;
    }

    .header-twin {
        display: flex;
        flex-flow: row;
        justify-content: space-around;
    }

    .center-row {
        display: flex;
        justify-content: center;
    }

    .progress {
        background-color: var(--primary-background) !important;
    }

    h4 {
        font-size: 28px;
        line-height: 42px;
        font-weight: 600;
        margin-bottom: 24px;
    }

    .btn-previous:not(:disabled) {
        border: 1px var(--primary-color) solid !important;
        background: transparent !important;
        color: var(--primary-color) !important;
    }

    .description {
        min-height: 160px !important;
        max-height: 660px;
    }

    .card-header h4 {
        font-size: 24px;
    }

    .card-title h4 {
        margin-bottom: 0;
    }

    .upload-another .btn-secondary {
        border: 1px var(--primary-color) solid !important;
        border-radius: 50px !important;
    }
</style>