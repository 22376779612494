<template>
    <div v-if="user && user.isAdmin" class="admin-container">
        
    </div>
    <div v-if="user && (user.isCreator || user.isAdmin)">
        <div class="top-cards-container row">
            <div class="col-12 col-xl-6">
                <div class="card card-dark top-card redirect-card" :title="audio ? audio.name : 'Loading audio'" @click="redirectToAudio()">
                    <div class="top-card-body card-body">
                        <img src="/icons/microphone.svg" alt="microphone icon"/>
                        <div class="top-card-amount">
                            <h3 v-if="audio != null" class="rows-1">{{ audio.name }}</h3>
                            <div v-else class="dot-flashing"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6 col-xl-3">
                <div class="card card-dark top-card" :title="audio ? audio.listenCount + ' listens' : 'Loading audio'">
                    <div class="top-card-body card-body">
                        <img src="/icons/music.svg" alt="music icon"/>
                        <div class="top-card-amount">
                            <h3 v-if="audio != null">{{ audio.listenCount }}</h3>
                            <div v-else class="dot-flashing"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6 col-xl-3">
                <div class="card card-dark top-card" :title="audio ? audio.upvoteCount + ' likes' : 'Loading audio'">
                    <div class="top-card-body card-body">
                        <img src="/icons/heart.svg" alt="heart icon"/>
                        <div class="top-card-amount">
                            <h3 v-if="audio != null">{{ audio.upvoteCount }}</h3>
                            <div v-else class="dot-flashing"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row chart-row">
            <div class="col-12">
                <TotalAudioListensByTimeChart :audioId="$route.params.id" />
            </div>
        </div>

        <div class="row chart-row">
            <div class="col-12">
                <TotalAudioListenTimeChart :audioId="$route.params.id" />
            </div>
        </div>

        <div class="row chart-row">
            <div class="col-12">
                <SearchOccurenceByTimeChart :audioId="$route.params.id" />
            </div>
        </div>

        <div class="row chart-row">
            <div class="col-12">
                <ListenHeatmapChart :audioId="$route.params.id" />
            </div>
        </div>

        <div class="row chart-row">
            <div class="col-12 col-xl-6 most-listened-creators-container">
                <TotalAudioListensByWeekdaysChart :audioId="$route.params.id" />
            </div>
            <div class="col-12 col-xl-6 most-listened-creators-container">
                <ListenerGendersChart :audioId="$route.params.id" />
            </div>
        </div>

        <div class="row chart-row">
            <div class="col-12 col-xl-6 most-listened-creators-container">
                <TotalAudioListensByHoursChart :audioId="$route.params.id" />
            </div>
            <div class="col-12 col-xl-6 most-listened-creators-container">

            </div>
        </div>
    </div>
</template>

<script>
    import requests from './../api/requests.js'
    import TotalAudioListensByTimeChart from './TotalAudioListensByTimeChart.vue'
    import TotalAudioListenTimeChart from './TotalAudioListenTimeChart.vue'
    import TotalAudioListensByWeekdaysChart from './TotalAudioListensByWeekdaysChart.vue'
    import TotalAudioListensByHoursChart from './TotalAudioListensByHoursChart.vue'
    import ListenerGendersChart from './ListenerGendersChart.vue'
    import ListenHeatmapChart from './ListenHeatmapChart.vue'
    import SearchOccurenceByTimeChart from './SearchOccurenceByTimeChart.vue'
    import { setMetadata } from '../utils.js'

    export default {
        name: 'AudioAnalyticsPage',
        components: {
            TotalAudioListensByTimeChart,
            TotalAudioListenTimeChart,
            TotalAudioListensByWeekdaysChart,
            TotalAudioListensByHoursChart,
            ListenerGendersChart,
            ListenHeatmapChart,
            SearchOccurenceByTimeChart
        },
        data() {
            return {
                audio: null
            }
        },
        computed: {
            user() {
                if (this.$store.getters.userData == null) {
                    return null
                }

                return this.$store.getters.userData.user
            }
        },
        methods: {
            async loadAudio() {
                const audioResult = await requests.UserContent.Audio.GetAudioByIdIncludeProfile(this.$route.params.id)
                if (audioResult.isSuccess) {
                    this.audio = audioResult.audio
                }
            },
            redirectToAudio() {
                this.$router.push('/audio/' + this.$route.params.id)
            }
        },
        async mounted() {
            if (this.user == null || (!this.user.isCreator && !this.user.isAdmin)) {
                this.$router.push('/')
                return
            }

            requests.initialize(this.$store)
            await requests.tryRefreshToken()
            setMetadata("Audio analytics | AudioLove", "With our detailed analytics you can craft the best content for your audience.")

            await this.loadAudio()
        }
    }
</script>

<style scoped>
    .top-card {
        margin-bottom: 0.5rem;
        padding: 24px 32px 24px 32px !important;
    }

    .top-card-amount {
        margin-right: 0.5rem;
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    .top-card-body {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: white;
    }

        .top-card-body img {
            width: 36px;
        }

    .chart-row div {
        margin-bottom: 1rem;
    }

    .redirect-card {
        cursor: pointer;
        transition: color ease-in-out 0.3s;
    }

        .redirect-card:hover {
            color: var(--primary-color);
        }

    .top-cards-container {
        margin-bottom: 1rem;
    }

    .chart-row {
        display: flex;
        align-items: stretch;
    }

    .rows-1 {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>