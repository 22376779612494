<template>
    <div class="row">
        <div class="col-lg-6 col-md-8 offset-lg-3 offset-md-2">
            <div v-if="page == 'select-type'" class="card card-dark">
                <div class="title">
                    Register
                </div>
                <div class="third-party-buttons">
                    <button @click="continueWithThirdParty('patreon')">
                        <img src="/icons/patreon.svg" title="Register using Patreon" alt="patreon icon" />
                        Continue with Patreon
                    </button>
                    <button @click="continueWithThirdParty('reddit')">
                        <img src="/icons/reddit.svg" title="Register using Reddit" alt="reddit icon" />
                        Continue with Reddit
                    </button>
                </div>
                <div class="text-center mt-4">
                    or
                </div>
                <div class="input-group mt-4">
                    <div class="input-group-prepend">
                        <div class="input-group-text">
                            <fai :icon="['fas', 'envelope']"></fai>
                        </div>
                    </div>
                    <input type="email" placeholder="Email" class="form-control" v-model="email" autocomplete="email">
                </div>
                <div class="text-right mt-4">
                    <button class="btn btn-primary" @click="selectEmailRegistration()">Continue</button>
                </div>
            </div>

            <div v-if="page == 'finish-third-party'" class="card card-dark" @keydown.enter="registerThirdParty">
                <div class="title">
                    Register
                </div>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <div class="input-group-text">
                            <fai :icon="['fas', 'user']"></fai>
                        </div>
                    </div>
                    <input type="text" class="form-control" placeholder="Username" v-model="username" autocomplete="username">
                </div>
                <div v-if="thirdPartyRequiresEmail(thirdParty)" class="input-group">
                    <div class="input-group-prepend">
                        <div class="input-group-text">
                            <fai :icon="['fas', 'envelope']"></fai>
                        </div>
                    </div>
                    <input type="email" placeholder="Email" class="form-control" v-model="email" autocomplete="email">
                </div>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <div class="input-group-text">
                            <fai :icon="['fas', 'venus-mars']"></fai>
                        </div>
                    </div>
                    <select v-model="selectedGender" class="form-select form-control" aria-label="Gender selection">
                        <option class="option" value="" disabled selected>Select your gender</option>
                        <option class="option" value="0">Prefer not to say</option>
                        <option class="option" value="1">Male</option>
                        <option class="option" value="2">Female</option>
                        <option class="option" value="3">Trans Male</option>
                        <option class="option" value="4">Trans female</option>
                        <option class="option" value="5">Non-binary</option>
                    </select>
                </div>
                <div class="form-check text-left">
                    <label class="form-check-label color-secondary">
                        <input class="form-check-input" type="checkbox" v-model="creator">
                        <span class="form-check-sign"></span>
                        I am a creator&nbsp;
                        <fai :icon="['fas', 'info-circle']" class="color-orange" title="This will set your profile as public and unlock creator features. Don't worry you can do this even after registration.'"></fai>
                    </label>
                </div>
                <div class="form-check text-left">
                    <label class="form-check-label color-secondary">
                        <input class="form-check-input" type="checkbox" v-model="agree">
                        <span class="form-check-sign"></span>
                        I agree to the
                        <router-link to="/terms-conditions">
                            terms and conditions
                        </router-link>
                    </label>
                </div>
                <button class="btn btn-primary register-btn mt-4" @click="registerThirdParty()">
                    <span v-if="!isWaitingForResult">Register</span>
                    <fai v-if="isWaitingForResult" :icon="['fas', 'spinner']" spin></fai>
                </button>
                <div class="mt-4 color-orange">By clicking register you confirm that you are at least 18 years old.</div>
            </div>
            <div v-if="page == 'finish-email'" class="card card-dark" @keydown.enter="register">
                <div class="title">
                    Register
                </div>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <div class="input-group-text">
                            <fai :icon="['fas', 'user']"></fai>
                        </div>
                    </div>
                    <input type="text" class="form-control" placeholder="Username" v-model="username" autocomplete="username">
                </div>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <div class="input-group-text">
                            <fai :icon="['fas', 'lock']"></fai>
                        </div>
                    </div>
                    <input type="password" class="form-control" placeholder="Password" v-model="password" autocomplete="new-password">
                </div>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <div class="input-group-text">
                            <fai :icon="['fas', 'venus-mars']"></fai>
                        </div>
                    </div>
                    <select v-model="selectedGender" class="form-select form-control" aria-label="Gender selection">
                        <option class="option" value="" disabled selected>Select your gender</option>
                        <option class="option" value="0">Prefer not to say</option>
                        <option class="option" value="1">Male</option>
                        <option class="option" value="2">Female</option>
                        <option class="option" value="3">Trans Male</option>
                        <option class="option" value="4">Trans female</option>
                        <option class="option" value="5">Non-binary</option>
                    </select>
                </div>
                <div class="form-check text-left">
                    <label class="form-check-label color-secondary">
                        <input class="form-check-input" type="checkbox" v-model="creator">
                        <span class="form-check-sign"></span>
                        I am a creator&nbsp;
                        <fai :icon="['fas', 'info-circle']" class="color-orange" title="This will set your profile as public and unlock creator features. Don't worry you can do this even after registration.'"></fai>
                    </label>
                </div>
                <div class="form-check text-left">
                    <label class="form-check-label color-secondary">
                        <input class="form-check-input" type="checkbox" v-model="agree">
                        <span class="form-check-sign"></span>
                        I agree to the
                        <router-link to="/terms-conditions">
                            terms and conditions
                        </router-link>
                    </label>
                </div>
                <button class="btn btn-primary register-btn mt-4" @click="register()">
                    <span v-if="!isWaitingForResult">Register</span>
                    <fai v-if="isWaitingForResult" :icon="['fas', 'spinner']" spin></fai>
                </button>
                <div class="mt-4 color-orange">By clicking register you confirm that you are at least 18 years old.</div>
            </div>
            <div v-if="errors">
                <div v-for="error in errors" class="color-red error mb-2">{{ error }}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import endpoints from "./../api/endpoints.js"
    import requests from "./../api/requests.js"
    import Swal from 'sweetalert2'
    import { setMetadata } from '../utils.js'

    export default {
        name: 'RegisterPage',
        data() {
            return {
                page: "select-type",
                username: "",
                email: "",
                password: "",
                selectedGender: "",
                agree: false,
                creator: false,
                isWaitingForResult: false,
                errors: null,
                thirdParty: null,
                state: null,
                emailRegex: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
            }
        },
        methods: {
            getRedirectUri(thirdParty) {
                const host = window.location.host.replace("www.", "")

                let uri = "https://" + host + "/register/"

                if (host.includes("localhost"))
                    uri = "http://localhost:8001/register/"

                return uri + thirdParty
            },
            continueWithThirdParty(thirdParty) {
                window.location.href = endpoints.UserAccess.Authorization.OAuth(thirdParty, this.getRedirectUri(thirdParty))
            },
            thirdPartyRequiresEmail(thirdParty) {
                if (thirdParty == "patreon") {
                    return false
                }

                return true
            },
            selectEmailRegistration() {
                if (this.email.length < 1) {
                    this.errors = ["Email must bet set."]
                    return
                }

                if (!this.emailRegex.test(this.email)) {
                    this.errors = ["Email must be in correct format."]
                    return
                }

                this.page = 'finish-email'
                this.errors = null
            },
            async register() {
                if (this.isWaitingForResult) {
                    return
                }

                if (this.username.length < 1 || this.email.length < 1 || this.password.length < 1) {
                    this.errors = ["Username, password and email must bet set."]
                    return
                }

                if (this.selectedGender === "") {
                    this.errors = ["You must select your gender to register."]
                    return
                }

                if (!this.agree) {
                    this.errors = ["You must agree with terms and conditions to register."]
                    return
                }               

                this.errors = null

                this.isWaitingForResult = true

                let result = await requests.UserAccess.UserRegistrations.Register(this.username, this.email, this.password, this.selectedGender, this.creator, this.getConfirmationLink())

                this.isWaitingForResult = false

                if (result && result.isSuccess) {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Success!',
                        text: 'Confirmation link was sent to your email.',
                        timer: 3000,
                        showConfirmButton: false,
                        background: "#262639",
                        color: "white",
                    })

                    this.username = ""
                    this.email = ""
                    this.password = ""
                    this.selectedGender = ""
                    this.agree = false
                    this.creator = false
                    this.page = 'select-type'
                } else if (result && !result.isSuccess) {
                    this.errors = result.errors
                }
            },
            async registerThirdParty() {
                if (!this.thirdParty) {
                    this.errors = ["Registration must contain valid third party."]
                    return
                }

                if (!this.state) {
                    this.errors = ["Registration must contain valid state."]
                    return
                }

                if (this.isWaitingForResult) {
                    return
                }

                if (this.thirdPartyRequiresEmail(this.thirdParty) && (!this.email || !this.emailRegex.test(this.email))) {
                    this.errors = ["Email must be valid."]
                    return
                }

                if (this.username.length < 1) {
                    this.errors = ["Username must bet set."]
                    return
                }

                if (this.selectedGender === "") {
                    this.errors = ["You must select your gender to register."]
                    return
                }

                if (!this.agree) {
                    this.errors = ["You must agree with terms and conditions to register."]
                    return
                }               

                this.errors = null

                this.isWaitingForResult = true

                let result = await requests.UserAccess.UserRegistrations.RegisterWithThirdParty(this.thirdParty, this.state, this.username, this.email, this.selectedGender, this.creator)

                this.isWaitingForResult = false

                if (result && result.isSuccess) {
                    this.username = ""
                    this.email = ""
                    this.password = ""
                    this.selectedGender = ""
                    this.agree = false
                    this.creator = false
                    this.page = 'select-type'

                    const { value: formValue } = await Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Success!',
                        text: 'Your account was successfully created.',
                        timer: 3000,
                        showConfirmButton: false,
                        background: "#262639",
                        color: "white",
                    })

                    await this.tryLoginWithState(this.thirdParty, this.state)
                } else if (result && !result.isSuccess) {
                    this.errors = result.errors
                }
            },
            async tryLoginWithState(thirdParty, state) {
                const result = await this.$store.dispatch("loginWithState", { thirdParty: thirdParty, state: state })
                if (result && result.isAuthenticated) {
                    const historyResult = await requests.UserContent.UserHistory.GetUserHistoryById(this.$store.getters.userData.user.id)
                    if (historyResult.isSuccess) {
                        this.$store.commit("setUserHistory", historyResult.userHistory)
                    }

                    const profileResult = await requests.UserContent.UserProfiles.GetUserProfileByUserId(this.$store.getters.userData.user.id)
                    if (profileResult.isSuccess) {
                        this.$store.commit("setUserProfileId", profileResult.userProfile.id)
                        this.$store.commit("setHandle", profileResult.userProfile.handleName)
                    }

                    this.$router.push('/')
                }
            },
            getConfirmationLink() {
                let host = window.location.host.replace("www.", "")

                if (host.includes("localhost"))
                    return "http://localhost:8001/confirm/"

                return "https://" + host + "/confirm/"
            }
        },
        async mounted() {
            setMetadata("Register | AudioLove", "Register your AudioLove account for the best audio porn experience.")

            if (this.$route.params.thirdParty) {
                this.page = "finish-third-party"
                this.thirdParty = this.$route.params.thirdParty

                const urlParams = new URLSearchParams(window.location.search);

                this.state = urlParams.get('state')

                if (this.thirdParty && this.state) {
                    await this.tryLoginWithState(this.thirdParty, this.state)
                }
            }
        }
    }
</script>

<style scoped>
    .error {
        border-radius: 5px;
        padding: 10px;
    }

    .register-btn {
        width: 140px;
    }

    .title {
        color: var(--primary-color);
        font-size: 28px;
        font-weight: 700;
        padding-bottom: 24px;
        letter-spacing: 4px;
    }

    .option {
        background-color: var(--secondary-background);
    }

    .third-party-buttons {
        display: flex;
        justify-content: space-evenly;
        gap: 12px;
    }

    @media screen and (max-width: 100rem) {
        .third-party-buttons {
            flex-direction: column;
        }
    }

    .third-party-buttons button {
        border: 2px solid var(--secondary-color);
        border-radius: 10px;
        padding: 8px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
    }

        .third-party-buttons button:hover {
            border-color: var(--primary-color);
        }

    .third-party-buttons img {
        width: 28px;
    }
</style>