<template>
    <div>
        <div v-if="!isAccountLoaded && !isAccountApplicationLoaded" class="card card-dark"></div>

        <div v-if="isAccountLoaded && !account && isAccountApplicationLoaded">
            <div class="card card-dark">
                <h2>Apply for Monetization Account</h2>
                <p>
                    <fai :icon="['fas', 'circle-info']" class="color-orange"></fai>
                    Monetization account allows you to earn money from your content.
                </p>
                <p>
                    <fai :icon="['fas', 'circle-info']" class="color-orange"></fai>
                    By having monetization account you are automatically a part of Creator Rewards Program. This means you get rewarded 2$ per 1000 listens.
                </p>
                <div class="apply-button-container">
                    <button :class="['btn', accountApplication ? 'btn-secondary' : 'btn-primary', 'apply-btn']" @click="createAccountApplication()">
                        <span v-if="!accountApplication">Apply Here</span>
                        <span v-else-if="accountApplication.isDenied">
                            <fai :icon="['fas', 'xmark']"></fai>
                            Application Denied
                        </span>
                        <span v-else>
                            <fai :icon="['fas', 'spinner']" spin></fai>
                            Waiting for Approval
                        </span>
                    </button>
                </div>
            </div>
        </div>

        <div v-if="isAccountLoaded && account">
            <div class="card card-dark">
                <h2>Monetization Account</h2>
                <p>
                    <fai :icon="['fas', 'circle-info']" class="color-orange"></fai>
                    This is a early version of monetization account. Later we will add more monetization options.
                </p>
                <p>
                    <fai :icon="['fas', 'circle-info']" class="color-orange"></fai>
                    By having monetization account you are automatically a part of Creator Rewards Program. This means you get rewarded 2$ per 1000 listens.
                </p>
                <div class="row-right pt-24">
                    <h4>
                        Status:
                        <span v-if="!account.disabled" class="color-green">Active</span>
                        <span v-if="account.disabled" class="color-red">Disabled</span>
                    </h4>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-xxl-6 mt-5">
                    <BillingItemWithoutInvoiceTable :userId="user.id" />
                </div>
                <div class="col-12 col-xxl-6 mt-5">
                    <InvoicesTable :userId="user.id" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import requests from './../api/requests.js'
    import { setMetadata } from '../utils.js'
    import BillingItemWithoutInvoiceTable from './BillingItemWithoutInvoiceTable.vue'
    import InvoicesTable from './InvoicesTable.vue'

    export default {
        name: 'MonetizationPage',
        components: {
            BillingItemWithoutInvoiceTable,
            InvoicesTable
        },
        data() {
            return {
                isAccountLoaded: false,
                account: null,
                isAccountApplicationLoaded: false,
                isCreatingAccountApplication: false,
                accountApplication: null
            }
        },
        computed: {
            user() {
                if (this.$store.getters.userData == null) {
                    return null
                }

                return this.$store.getters.userData.user
            }
        },
        methods: {
            async loadAccount() {
                const accountResult = await requests.Monetization.Account.GetAccountByUserId(this.user.id)
                if (accountResult.isSuccess) {
                    this.account = accountResult.account
                }
                this.isAccountLoaded = true
            },
            async loadAccountApplication() {
                const accountApplicationResult = await requests.Monetization.AccountApplication.GetAccountApplicationByUserId(this.user.id)
                if (accountApplicationResult.isSuccess) {
                    this.accountApplication = accountApplicationResult.accountApplication
                }
                this.isAccountApplicationLoaded = true
            },
            async createAccountApplication() {
                if (this.accountApplication || this.isCreatingAccountApplication) {
                    return
                }

                this.isCreatingAccountApplication = true

                const createResult = await requests.Monetization.AccountApplication.CreateAccountApplication(this.user.id)
                if (createResult.isSuccess) {
                    this.accountApplication = createResult.accountApplication
                }

                this.isCreatingAccountApplication = false
            }
        },
        async mounted() {
            requests.initialize(this.$store)
            setMetadata("Monetization | AudioLove")

            await this.loadAccount()

            if (!this.account) {
                await this.loadAccountApplication()
            }
        }
    }
</script>

<style scoped>
    .apply-button-container {
        margin-top: 24px;
        display: flex;
        justify-content: flex-end;
    }

    .apply-btn {
        min-width: 300px;
        min-height: 60px;
        border: 1px solid var(--primary-color) !important;
    }

    h2 {
        color: var(--primary-color);
        letter-spacing: 3px;
        padding-bottom: 8px;
    }

    h4 {
        letter-spacing: 5px;
    }

    .row-right {
        display: flex;
        justify-content: flex-end;
    }
</style>