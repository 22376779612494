<template>
    <div class="card card-dark" :title="tag.description" @click="redirect()" role="listitem" :aria-label="tag.name">
        <span class="name" role="button">{{ tag.name }}</span>
        <span class="audio-count">{{ tag.audioCount }} audios</span>
    </div>
</template>

<script>
    export default {
        name: 'CategoryCard',
        props: {
            tag: {
                default: null,
                type: Object
            }
        },
        methods: {
            redirect() {
                this.$router.push({ name: 'Category', params: { tagId: this.tag.id } })
            }
        }
    }
</script>

<style scoped>
    .card {
        border: 1px var(--primary-color) solid;
        padding: 30px !important;
        border: 1px transparent solid;
        transition: border linear var(--def-transition-duration), background-color linear var(--def-transition-duration);
    }

        .card:hover {
            background-color: #A688FA33 !important;
        }

        .card .name {
            transition: letter-spacing ease-in-out var(--def-transition-duration);
        }

        .card:hover .name {
            letter-spacing: 3px;
        }

    .name {
        font-size: 20px;
        line-height: 30px;
        color: white;
    }

    .audio-count {
        padding-top: 12px;
        font-size: 16px;
        line-height: 24px;
        color: var(--secondary-color);
    }
</style>