<template>
    <div class="card card-dark">
        <div v-if="invoice">
            <h2>{{ invoice.name }}</h2>
            <h4>Date: {{ formatDate(invoice.created) }}</h4>
            <div class="mt-5">
                <v-data-table-virtual :headers="headers"
                                      :items="invoice.billingItems"
                                      item-key="id"
                                      height="500">

                </v-data-table-virtual>
            </div>

            <div class="row-right mt-5">
                <h4>Unique Number: {{ invoice.uniqueNumber }}</h4>
            </div>
            <div class="row-right mt-3">
                <h4 class="color-orange">Total: {{ formatPrice(invoice.price) }}</h4>
            </div>
        </div>
    </div>
</template>

<script>
    import requests from './../api/requests.js'
    import { setMetadata, formatDateTimeNumeric, formatDateNumeric, formatPrice } from '../utils.js'

    export default {
        name: 'InvoiceDetailPage',
        components: {

        },
        data() {
            return {
                invoice: null,
                headers: [
                    { title: 'Created', key: 'created', align: 'end', value: item => formatDateTimeNumeric(item.timestamp) },
                    { title: 'Name', key: 'name' },
                    { title: 'Price', key: 'price', align: 'end', value: item => formatPrice(item.price) }
                ],
            }
        },
        computed: {
            user() {
                if (this.$store.getters.userData == null) {
                    return null
                }

                return this.$store.getters.userData.user
            }
        },
        methods: {
            async loadInvoice() {
                const invoiceResult = await requests.Monetization.Invoice.GetInvoiceById(this.$route.params.id)
                if (invoiceResult.isSuccess) {
                    this.invoice = invoiceResult.invoice
                }
            },
            formatPrice(price) {
                return formatPrice(price)
            },
            formatDate(date) {
                return formatDateNumeric(date)
            }
        },
        async mounted() {
            requests.initialize(this.$store)
            setMetadata("Invoice | AudioLove")

            await this.loadInvoice()
        }
    }
</script>

<style scoped>
    h2 {
        color: var(--primary-color);
        letter-spacing: 3px;
        padding-bottom: 8px;
    }

    h4 {
        letter-spacing: 2px;
    }

    .row-right {
        display: flex;
        justify-content: flex-end;
    }
</style>