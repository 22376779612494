<template>
    <div class="row">
        <div v-if="isWaitingForResult" class="col-lg-4 col-md-6 offset-lg-4 offset-md-3 mt-5">
            <fai :icon="['fas', 'spinner']" class="spinner" size="4x" spin></fai>
        </div>

        <div v-if="!isWaitingForResult && success">
            <h3 class="text-center">Account successfully confirmed!</h3>
            <div class="button-center mt-4">
                <button class="btn btn-primary btn-round btn-lg" @click="login()">
                    <span>Log In</span>
                </button>
            </div>
        </div>

        <div v-if="!isWaitingForResult && !success">
            <h3 class="text-center">Confirmation was unsuccessful!</h3>
        </div>
    </div>
</template>

<script>
    import requests from './../api/requests.js'
    import { setMetadata } from '../utils.js'

    export default {
        name: 'ConfirmPage',
        data() {
            return {
                isWaitingForResult: true,
                success: false
            }
        },
        methods: {
            login() {
                this.$router.push('/login')
            }
        },
        async created() {
            const result = await requests.UserAccess.UserRegistrations.Confirm(this.$route.params.id)

            this.isWaitingForResult = false

            this.success = result.isSuccess
        },
        mounted() {
            setMetadata("Confirm account | AudioLove")
        }
    }
</script>

<style scoped>
    .spinner {
        width: 100%;
        text-align: center;
        color: #ba54f5;
    }

    .text-center {
        width: 100%;
        justify-content: center;
    }

    .button-center {
        display: flex;
    }

        .button-center button {
            margin: auto;
        }
</style>