<template>
    <div class="card card-dark">
        <div class="card-body">
            <h4 class="color-orange pb-24">
                To be invoiced: {{ totalPrice }}
            </h4>
            <v-data-table-virtual :headers="headers"
                                  :items="billingItems"
                                  item-key="id"
                                  density="compact"
                                  height="300">

                <template v-slot:item.type="{ item }">
                    <div class="col kind">
                        <div v-if="item.type == 0" title="Creator Rewards Program">CRP</div>
                    </div>
                </template>

                <template v-slot:item.timestamp="{ item }">
                    <div class="col timestamp">
                        {{ item.dateFormated }}
                    </div>
                </template>

                <template v-slot:item.price="{ item }">
                    <div class="col price">
                        {{ item.priceFormated }}
                    </div>
                </template>

            </v-data-table-virtual>
        </div>
    </div>
    <div v-if="error" class="color-red error mb-2">{{ error }}</div>

</template>

<script>
    import requests from './../api/requests.js'
    import { formatDateTimeNumeric, formatPrice } from './../utils.js'

    export default {
        name: 'BillingItemWithoutInvoiceTable',
        components: {
            
        },
        props: {
            userId: {    
                default: null,
                type: String
            },
        },
        data() {
            return {
                billingItems: [],
                headers: [
                    { title: 'Kind', key: 'type', align: 'end' },
                    { title: 'Date', key: 'timestamp' },
                    { title: 'Name', key: 'name' },
                    { title: 'Price', key: 'price', align: 'end' },
                ],
                error: null,
                totalPrice: "0"
            }
        },
        methods: {
            redirectToProfile(profileHandle) {
                this.$router.push({ name: 'Profile', params: { handle: profileHandle } })
            },
            async loadBillingItems() {
                const response = await requests.Monetization.BillingItem.GetWithoutInvoiceByUserId(this.userId)
                if (!response.isSuccess) {
                    this.error = response.error
                    return
                }

                let billingItems = response.billingItems

                billingItems.forEach(x => x.dateFormated = formatDateTimeNumeric(x.timestamp))
                billingItems.forEach(x => x.priceFormated = formatPrice(x.price))

                this.totalPrice = formatPrice(billingItems.reduce((accumulator, currentItem) => { return accumulator + currentItem.price; }, 0))

                this.billingItems = billingItems
            }     
        },
        async mounted() {
            requests.initialize(this.$store)

            await this.loadBillingItems()
        }
    }
</script>

<style scoped>
    .title {
        margin-top: 32px;
        margin-bottom: 16px;
        letter-spacing: 1px;
    }

    .error {
        border-radius: 5px;
        padding: 10px;
    }

    .col {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
    }

        .col.kind {
            justify-content: flex-end;
        }

        .col.price {
            justify-content: flex-end;
        }
</style>
