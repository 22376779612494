<template>
    <h4 class="title">Accounts</h4>
    <div class="card card-dark">
        <div class="card-body">
            <v-data-table :headers="headers"
                          :items="accounts"
                          item-key="userId"
                          :items-per-page="pageSize"
                          density="compact">

                <template v-slot:item.disabled="{ item }">
                    <div class="col enabled">
                        <div v-if="item.disabled" class="tag disabled">Disabled</div>
                        <div v-else class="tag enabled">Enabled</div>
                    </div>
                </template>

                <template v-slot:item.profile.name="{ item }">
                    <div @click="redirectToProfile(item.profile.handleName)" class="col profile-name">{{ item.profile.name }}</div>
                </template>

                <template v-slot:item.disabledByUserId="{ item }">
                    <div class="col action">
                        <button @click="actions(item)" class="btn btn-sm btn-primary">Actions</button>
                    </div>
                </template>

                <template v-slot:bottom>
                    <div class="text-center pt-2">
                        <v-pagination v-model="page"
                                      :length="Math.ceil(totalAccounts / pageSize)"
                                      :total-visible="6"
                                      @update:modelValue="loadAccounts()"></v-pagination>
                    </div>
                </template>

            </v-data-table>
        </div>
    </div>
    <div v-if="error" class="color-red error mb-2">{{ error }}</div>

</template>

<script>
    import requests from './../api/requests.js'
    import { formatDate, formatPrice } from './../utils.js'
    import Swal from 'sweetalert2'

    export default {
        name: 'AccountsTable',
        components: {

        },
        props: {

        },
        data() {
            return {                
                accounts: [],
                totalAccounts: 0,
                pageSize: 10,
                page: 1,
                headers: [
                    { title: 'Status', value: 'disabled' },
                    { title: 'Profile', value: 'profile.name' },
                    { title: 'Created At', value: 'created' },
                    { title: "Not Invoiced", align: 'end', key: 'billingItems', value: item => formatPrice(item.billingItems.reduce((accumulator, currentObject) => { return accumulator + (currentObject.invoiceId ? 0 : currentObject.price); }, 0)) },
                    { title: 'Action', value: 'disabledByUserId', align: 'center' },
                ],
                error: null
            }
        },
        methods: {
            redirectToProfile(profileHandle) {
                this.$router.push({ name: 'Profile', params: { handle: profileHandle } })
            },
            async loadAccounts() {
                const response = await requests.Monetization.Account.GetAllAccounts((this.page - 1) * this.pageSize, this.pageSize)
                if (!response.isSuccess) {
                    this.error = response.error
                    return
                }

                let accounts = response.accounts
                this.totalAccounts = response.totalCount

                const userProfilesLookupResponse = await requests.UserContent.UserProfiles.LookupByUserIds(accounts.map(x => x.userId))
                if (!userProfilesLookupResponse.isSuccess) {
                    this.error = userProfilesLookupResponse.error
                    return
                }

                const profilesByUserIdsDict = {}
                for (const profile of userProfilesLookupResponse.userProfiles) {
                    profilesByUserIdsDict[profile.userId] = profile
                }

                const billingItemsByUserIdResponse = await requests.Monetization.BillingItem.GetWithoutInvoiceByUserIdsGroupByUserId(accounts.map(x => x.userId))
                if (!billingItemsByUserIdResponse.isSuccess) {
                    this.error = billingItemsByUserIdResponse.error
                    return
                }

                const billingItemsByUserId = billingItemsByUserIdResponse.billingItemsByUserId

                for (const account of accounts) {
                    if (!profilesByUserIdsDict[account.userId]) {
                        account.profile = null
                        continue
                    }

                    account.profile = profilesByUserIdsDict[account.userId]
                    account.created = formatDate(account.created)
                    account.billingItems = billingItemsByUserId[account.userId] ? billingItemsByUserId[account.userId] : []
                }

                this.accounts = accounts
            },
            formatDate(date) {
                return formatDate(date)
            },
            async actions(item) {
                const { value: formValue } = await Swal.fire({
                    title: item.profile.name,
                    focusConfirm: false,
                    background: "#262639",
                    color: "white",
                    customClass: {
                        confirmButton: 'dialog-btn-secondary ' + (item.disabled ? 'color-green' : 'color-red'),
                        denyButton: 'dialog-btn-primary',
                        cancelButton: 'dialog-btn-secondary'
                    },
                    confirmButtonText: item.disabled ? 'Enable' : 'Disable',
                    denyButtonText: 'Draft Invoice',
                    cancelButtonText: 'Cancel',
                    showConfirmButton: true,
                    showDenyButton: true,
                    showCancelButton: true,
                    buttonsStyling: false,
                    preConfirm: () => {
                        return item.disabled ? "ENABLE" : "DISABLE"
                    },  
                    preDeny: () => {
                        return "DRAFT INVOICE"
                    }
                })

                if (formValue == "DISABLE") {
                    const { value: formValue } = await Swal.fire({
                        text: "Are you sure you want to disable " + item.profile.name + "?",
                        focusConfirm: false,
                        background: "#262639",
                        color: "white",
                        customClass: {
                            confirmButton: 'dialog-btn-secondary color-red',
                            cancelButton: 'dialog-btn-secondary'
                        },
                        confirmButtonText: 'Disable',
                        cancelButtonText: 'Cancel',
                        showConfirmButton: true,
                        showDenyButton: false,
                        showCancelButton: true,
                        buttonsStyling: false,
                        preConfirm: () => {
                            return "DISABLE"
                        }
                    })

                    if (formValue == "DISABLE") {
                        const disableResponse = await requests.Monetization.Account.DisableAccountByUserId(item.userId)
                        if (disableResponse.isSuccess) {
                            Swal.fire({
                                icon: 'success',
                                timer: 1000,
                                showConfirmButton: false,
                                background: "#262639",
                                color: "white"
                            })

                            await this.loadAccounts()
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: disableResponse.error,
                                background: "#262639",
                                color: "white"
                            })
                        }
                    }
                }

                if (formValue == "ENABLE") {
                    const { value: formValue } = await Swal.fire({
                        text: "Are you sure you want to enable " + item.profile.name + "?",
                        focusConfirm: false,
                        background: "#262639",
                        color: "white",
                        customClass: {
                            confirmButton: 'dialog-btn-secondary color-green',
                            cancelButton: 'dialog-btn-secondary'
                        },
                        confirmButtonText: 'Enable',
                        cancelButtonText: 'Cancel',
                        showConfirmButton: true,
                        showDenyButton: false,
                        showCancelButton: true,
                        buttonsStyling: false,
                        preConfirm: () => {
                            return "ENABLE"
                        }
                    })

                    if (formValue == "ENABLE") {
                        const enableResponse = await requests.Monetization.Account.EnableAccountByUserId(item.userId)
                        if (enableResponse.isSuccess) {
                            Swal.fire({
                                icon: 'success',
                                timer: 1000,
                                showConfirmButton: false,
                                background: "#262639",
                                color: "white"
                            })

                            await this.loadAccounts()
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: enableResponse.error,
                                background: "#262639",
                                color: "white"
                            })
                        }
                    }
                }

                if (formValue == "DRAFT INVOICE") {
                    const createInvoiceTemplateResponse = await requests.Monetization.InvoiceTemplate.CreateInvoiceTemplate(item.userId)
                    if (!createInvoiceTemplateResponse.isSuccess) {
                        Swal.fire({
                            icon: 'error',
                            title: createInvoiceTemplateResponse.error,
                            background: "#262639",
                            color: "white"
                        })
                        return
                    }

                    this.$router.push({ name: 'Invoice Draft', params: { id: createInvoiceTemplateResponse.invoiceTemplate.id } })
                }
            }
        },
        async mounted() {
            requests.initialize(this.$store)

            await this.loadAccounts()
        }
    }
</script>

<style scoped>
    .title {
        margin-top: 32px;
        margin-bottom: 16px;
        letter-spacing: 1px;
    }

    .error {
        border-radius: 5px;
        padding: 10px;
    }

    .col {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
    }

        .col.profile-name {
            cursor: pointer;
        }

        .col.action {
            display: flex;
            justify-content: center;
        }

            .col.action > button {
                width: 110px;
            }

    .tag {
        border-radius: 6px;
        padding: 4px;
    }

        .tag.disabled {
            background-color: var(--color-red);
        }

        .tag.enabled {
            background-color: var(--color-green);
        }
</style>
