<template>
    <div class="banner" aria-label="Take survey banner">
        <div class="text">
            {{ message }}
        </div>
        <a class="button" href="https://63hzvp979f4.typeform.com/to/s9ABgutn" target="_blank" aria-label="Take survey button">
            Take Survey
        </a>
    </div>
</template>

<script>
    export default {
        name: 'SurveyBanner',
        components: {

        },
        props: {
            message: {
                default: "Rate your experience in 1 minute!",
                type: String
            }
        },
        data() {
            return {

            }
        },
        methods: {

        },
        async mounted() {

        }
    }
</script>

<style scoped>
    .banner {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 60px;
        padding: 16px;
        border-radius: 16px;
        background: linear-gradient(270deg, #32ba54, #1ea6c9, #1e35c9);
        background-size: 2000% 2000%;
        animation: gradientAnimation 25s linear infinite;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

        .banner::before {
            content: "AudioLove";
            position: absolute;
            font-size: 180px;
            color: rgba(255, 255, 255, 0.12);
            z-index: 0;
            pointer-events: none;
            animation: backgroundTextAnimation 25s ease infinite;
        }

        .banner .button {
            width: 120px;
            padding: 4px;
            border-radius: 6px;
            border: 2px solid rgba(255, 255, 255, 0.8);
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: box-shadow linear var(--def-transition-duration);
            color: white !important;
            user-select: none;
        }

            .banner .button:hover {
                box-shadow: white 2px -2px;
            }

    @keyframes gradientAnimation {
        0% {
            background-position: 0% 50%;
        }

        50% {
            background-position: 100% 50%;
        }

        100% {
            background-position: 0% 50%;
        }
    }

    @keyframes backgroundTextAnimation {
        0% {
            transform: rotate(0deg) translate3d(-50px, 0, 0 );
        }

        50% {
            transform: rotate(7deg) translate3d(50px, 0, 0 );
        }

        100% {
            transform: rotate(0deg) translate3d(-50px, 0, 0 );
        }
    }
</style>
