<template>
    <div class="card card-dark">
        <div v-if="audio" class="card-body" @click="redirectToAudio">
            <div class="container">
                <div class="container-content">
                    <h2 class="audio-title mb-1">
                        <span v-if="!audio.isPublic">
                            <fai :icon="['fas', 'lock']" title="Audio is private"></fai>
                            &nbsp;
                        </span>
                        <span class="title" :title="audio.name">{{ audio.name }}</span>
                    </h2>
                    <div v-if="audio.status == 1" class="loading">
                        <span class="mb-1">Audio is being processed</span>
                    </div>
                    <div v-if="audio.status == 2">
                        <p class="audio-description mb-1">
                            {{ audio.description }}
                        </p>
                        <TagDisplayContainer v-if="tagsToDisplay" :tags="tagsToDisplay" class="mt-2"></TagDisplayContainer>
                        
                    </div>
                    <div v-if="audio.status == 3" class="audio-processing">
                        <span class="mb-1">Audio processing failed :(</span>
                    </div>
                </div>
                <div class="container-footer">
                    <div v-if="audio.status == 2" :title="'Uploaded in ' + uploaded + '.'" class="mt-1">
                        <ul>
                            <li v-if="audio.listenCount >= 0">
                                <img src="/icons/music.svg" alt="music icon" />
                                {{ audio.listenCount }} Listens
                            </li>
                            <li>
                                <img src="/icons/calendar.svg" alt="calendar icon" />
                                Upload Date: {{ uploaded }}
                            </li>
                            <li v-if="audio.lengthSeconds > 0">
                                <img src="/icons/waveform.svg" width="24" height="24" alt="waveform icon" />
                                {{ timeLabel }}
                            </li>
                            <li v-if="audio.thirdParty != null">
                                <img src="/icons/lovely-gray.svg" width="24" height="24" alt="AudioLove logo" />
                                Outside AudioLove
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            
        </div>
        <div v-if="audio && showMoreOptions" class="more-options">
            <button v-show="moreOptionsOpen" class="btn btn-secondary color-white" @click="statistics()">
                <fai :icon="['fas', 'chart-simple']"></fai>
                Analytics
            </button>
            <button v-show="moreOptionsOpen" class="btn btn-secondary color-white" @click="editAudio()">
                <fai :icon="['fas', 'pen']"></fai>
                Edit
            </button>
            <button v-show="moreOptionsOpen && audio.status != 1" class="btn btn-secondary color-white" @click="deleteAudio()">
                <fai :icon="['fas', 'trash']"></fai>
                Delete
            </button>
            <button class="btn btn-secondary color-white" @click="moreOptionsOpen=!moreOptionsOpen" title="More options">
                <fai :icon="['fas', moreOptionsOpen ? 'xmark' : 'ellipsis-vertical']"></fai>
            </button>
        </div>
    </div>
</template>

<script>
    import requests from './../api/requests.js'
    import { formatDate, secondsToTimeLabel } from './../utils.js'
    import Swal from 'sweetalert2'
    import TagDisplayContainer from './TagDisplayContainer.vue'

    export default {
        name: 'AudioCard',
        emits: ['deleted'],
        components: {
            TagDisplayContainer
        },
        props: {
            audio: {
                default: null,
                type: Object
            },
            showMoreOptions: {
                default: false,
                type: Boolean
            }
        },
        data() {
            return {
                moreOptionsOpen: false,
                tagsToDisplay: null
            }
        },
        computed: {
            uploaded() {
                return formatDate(this.audio.created)
            },
            timeLabel() {
                return secondsToTimeLabel(this.audio.lengthSeconds - 1)
            }
        },
        methods: {
            redirectToAudio() {
                this.$router.push({ name: 'Audio', params: { id: this.audio.id } })
            },
            editAudio() {
                this.$router.push({ name: 'Edit audio', params: { id: this.audio.id } })
            },
            deleteAudio() {
                Swal.fire({
                    position: 'center',
                    title: this.audio.name,
                    text: 'Are you sure you want to delete the file?',
                    confirmButtonText: 'Delete',
                    showCancelButton: true,
                    cancelButtonText: 'Cancel',
                    background: "#262639",
                    color: "white",
                    customClass: {
                        confirmButton: 'dialog-btn-primary',
                        denyButton: 'dialog-btn-primary',
                        cancelButton: 'dialog-btn-secondary'
                    },
                    buttonsStyling: false
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        const deleteResult = await requests.UserContent.Audio.DeleteAudio(this.audio.id)

                        if (deleteResult.isSuccess) {
                            this.moreOptionsOpen = false
                            this.$emit("deleted", this.audio.id)

                            Swal.fire({
                                icon: 'success',
                                timer: 1000,
                                showConfirmButton: false,
                                background: "#262639",
                                color: "white"
                            })
                        }
                    }
                })
            },
            statistics() {
                this.$router.push({ name: 'Audio analytics', params: { id: this.audio.id } })
            },
        },
        mounted() {
            requests.initialize(this.$store)

            if (this.audio) {
                this.tagsToDisplay = this.audio.genderPreferences

                for (const category of this.audio.categories) {
                    this.tagsToDisplay.push(category)
                }
            }
        }
    }
</script>

<style scoped>
    .card {
        cursor: pointer;
        min-height: 260px;
    }

    .card-body {
        display: flex;
    }

    .audio-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
    }

        .audio-title .title {
            color: var(--primary-color);
        }

    .audio-description {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-top: 16px;
    }

    .loading {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80%;
    }

    .more-options {
        position: absolute;
        bottom: 16px;
        right: 16px;
        display: flex;
        flex-flow: row;
        column-gap: 0.5rem;
    }

        .more-options button {
            min-width: 2.5rem;
            border: 1px gray solid !important;
        }

    .btn-secondary {
        min-width: 2.2rem;
    }

    .audio-processing {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        height: 80%;
    }

    .container {
        flex-grow: 1;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        gap: 1rem;
    }

    .container-content {
        display: flex;
        flex-flow: column;
    }

    .container-footer {
    }

        .container-footer ul {
            padding: 0;
            margin: 0;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            list-style-type: none;
            gap: 24px;
        }

            .container-footer ul li {
                color: #FFFFFF;
                font-size: 16px;
            }

    @media screen and (max-width: 40rem) {
        .container-footer ul {
            flex-direction: column;
        }
    }
</style>