<template>
    <div class="card card-dark final-card">
        <span class="mb-2">{{ text }}</span>
        <button class="btn btn-primary" @click="scroll()">
            Scroll up
        </button>
    </div>
</template>

<script>
    export default {
        name: 'NothingMoreCard',
        props: {
            scroll: {
                default: null,
                type: Function
            },
            text: {
                default: "That's all there is for now.",
                type: String
            }
        },
    }
</script>

<style scoped>
    .final-card {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 8rem;
        background: transparent !important;
        border: #27293d 2px solid;
    }
</style>