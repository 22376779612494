<template>
    <h4 class="title">Profiles</h4>
    <div class="card card-dark">
        <div class="card-body">
            <div class="btn-row">
                <button class="btn btn-primary btn-sm" @click="createProfile('soundgasm')">Create Soundgasm</button>
            </div>
            <v-data-table :headers="headers"
                          :items="profiles"
                          item-key="id"
                          :items-per-page="pageSize"
                          density="compact">

                <template v-slot:item.name="{ item }">
                    <div @click="redirectToProfile(getThirdParty(item.thirdParty), item.handleName)" class="col profile-name">{{ item.name }}</div>
                </template>

                <template v-slot:bottom>
                    <div class="text-center pt-2">
                        <v-pagination v-model="page"
                                      :length="Math.max(1, Math.ceil(totalProfiles / pageSize))"
                                      :total-visible="6"
                                      @update:modelValue="loadProfiles()"></v-pagination>
                    </div>
                </template>

            </v-data-table>
        </div>
    </div>
    <div v-if="error" class="color-red error mb-2">{{ error }}</div>

</template>

<script>
    import requests from './../api/requests.js'
    import { formatDate, getThirdPartyFromEnum } from './../utils.js'
    import Swal from 'sweetalert2'

    export default {
        name: 'ProfilesAdminTable',
        components: {

        },
        props: {

        },
        data() {
            return {
                profiles: [],
                genderPreferences: [],
                totalProfiles: 0,
                pageSize: 10,
                page: 1,
                headers: [
                    { title: 'Name', value: 'name' },
                    { title: 'Handle', value: 'handleName' },
                    { title: 'Created At', value: 'created' },
                    { title: 'Third Party', align: 'end', value: 'thirdPartyName' },
                ],
                error: null
            }
        },
        methods: {
            redirectToProfile(thirdParty, profileHandle) {
                if (thirdParty) {
                    this.$router.push({ name: 'Profile ', params: { thirdParty: thirdParty, handle: profileHandle } })
                } else {
                    this.$router.push({ name: 'Profile', params: { handle: profileHandle } })
                }
            },
            getThirdParty(thirdPartyEnum) {
                return getThirdPartyFromEnum(thirdPartyEnum)
            },
            async loadProfiles() {
                const response = await requests.UserContent.UserProfiles.GetAllUserProfiles((this.page - 1) * this.pageSize, this.pageSize)
                if (!response.isSuccess) {
                    this.error = response.error
                    return
                }

                let profiles = response.profiles
                this.totalProfiles = response.totalCount

                for (const profile of profiles) {
                    profile.thirdPartyName = profile.thirdParty == null
                        ? ""
                        : profile.thirdParty == 0
                            ? "Soundgasm"
                            : "Erocast"
                    profile.created = formatDate(profile.created)
                }

                this.profiles = profiles
            },
            formatDate(date) {
                return formatDate(date)
            },
            async createProfile(thirdParty) {
                let html = '<fieldset>'

                html += '<div class="mt-1">'
                html += '<label class="mb-1">Username</label>'
                html += '<input id="profileUsername" class="form-control" type="text"/>'
                html += '</div>'

                html += '<div class="mt-1">'
                html += '<label class="mb-1">Profile Name</label>'
                html += '<input id="profileProfileName" class="form-control" type="text"/>'
                html += '</div>'

                const genderPreferenceOptions = []

                html += '<div class="mt-1" style="display: flex; flex-flow: row; justify-content: space-between;">'

                for (const genderPreference of this.genderPreferences) {
                    genderPreferenceOptions.push(genderPreference)

                    html += '<div class="gender-preference-radio mt-1">'
                    html += '<input id="genderPreferenceRadio-' + genderPreference.tag.id + '" class="form-check-input" type="radio" name="genderPreferenceCheckbox"/>&nbsp;'
                    html += '<label>' + genderPreference.tag.name + '</label>'
                    html += '</div>'
                }

                html += '</div>'

                html += '</fieldset>'

                const { value: formValue } = await Swal.fire({
                    html: html,
                    focusConfirm: false,
                    background: "#262639",
                    color: "white",
                    customClass: {
                        confirmButton: 'dialog-btn-primary',
                        denyButton: 'dialog-btn-primary',
                        cancelButton: 'dialog-btn-secondary'
                    },
                    buttonsStyling: false,
                    showCancelButton: true,
                    confirmButtonText: 'Create',
                    preConfirm: () => {
                        let selectedGenderPreference
                        for (const option of genderPreferenceOptions) {
                            if (document.getElementById("genderPreferenceRadio-" + option.tag.id).checked) {
                                selectedGenderPreference = option.tag
                            }
                        }

                        if (!$("#profileUsername").val() || $("#profileUsername").val().length < 2) {
                            Swal.fire({
                                icon: 'error',
                                title: "Must specify username.",
                                background: "#262639",
                                color: "white"
                            })
                            return null
                        }

                        if (!selectedGenderPreference) {
                            Swal.fire({
                                icon: 'error',
                                title: "Must select default gender preference.",
                                background: "#262639",
                                color: "white"
                            })
                            return null
                        }

                        return { 
                            username: $("#profileUsername").val(), 
                            profileName: $("#profileProfileName").val(), 
                            defaultGenderPreferenceId: selectedGenderPreference.id 
                        }
                    }
                })

                if (formValue) {
                    const result = await requests.UserContent.UserProfiles.CreateThirdPartyProfile(thirdParty, formValue.username, formValue.profileName, formValue.defaultGenderPreferenceId)
                    
                    if (result.isSuccess) {
                        Swal.fire({
                            icon: 'success',
                            timer: 1000,
                            title: result.error,
                            background: "#262639",
                            color: "white"
                        })
                        await this.loadProfiles()
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: result.error,
                            background: "#262639",
                            color: "white"
                        })
                    }
                }
            },
            async loadGenderPreferences() {
                const response = await requests.UserContent.Tag.GetAll()
                if (response.isSuccess) {
                    this.genderPreferences = []

                    response.tags.filter(x => x.isGenderPreference).forEach(x => {
                        const genderPreferenceWithStatus = { tag: x, status: "DEFAULT" }

                        this.genderPreferences.push(genderPreferenceWithStatus)
                    })
                }
            },
        },
        async mounted() {
            requests.initialize(this.$store)

            await this.loadProfiles()
            await this.loadGenderPreferences()
        }
    }
</script>

<style scoped>
    .title {
        margin-top: 32px;
        margin-bottom: 16px;
        letter-spacing: 1px;
    }

    .error {
        border-radius: 5px;
        padding: 10px;
    }

    .col {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
    }

        .col.profile-name {
            cursor: pointer;
        }

    .btn-row {
        margin-bottom: 16px;
    }
</style>
