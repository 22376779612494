<template>
    <div v-if="showGenderPreferences" role="group" aria-label="Gender preferences">
        <div v-if="maxSelectedGenderPreferencesCount">
            <h5>Selected gender preferences {{ selectedGenderPreferencesCount }}/{{ maxSelectedGenderPreferencesCount }} <span class="color-primary" title="required">*</span></h5>
        </div>
        <div class="container-buttons">
            <div v-if="genderPreferencesWithStatuses" v-for="(array, i) in splitByFirstLetter(genderPreferencesWithStatuses)" class="category-row">
                <div class="letter">
                    {{ array[0].tag.name[0] }}
                </div>
                <TagSelectButton v-for="genderPreference in array"
                                 :tag="genderPreference.tag"
                                 :status="genderPreference.status"
                                 :canBeDeselected="canBeDeselected"
                                 @statusChanged="statusChanged"></TagSelectButton>
            </div>
        </div>
    </div>

    <div class="mt-5" role="group" aria-label="Categories">
        <div v-if="maxSelectedCategoriesCount">
            <hr />
            <h5>Selected categories {{ selectedCategoriesCount }}/{{ maxSelectedCategoriesCount }}</h5>
        </div>
        <div class="container-buttons">
            <div v-if="categoriesWithStatuses" v-for="(array, i) in splitByFirstLetter(categoriesWithStatuses)" class="category-row">
                <div class="letter">
                    {{ array[0].tag.name[0] }}
                </div>
                <TagSelectButton v-for="category in array"
                                 :tag="category.tag"
                                 :status="category.status"
                                 :canBeDeselected="canBeDeselected"
                                 @statusChanged="statusChanged"></TagSelectButton>
            </div>
        </div>
    </div>

    <div class="mt-5" v-if="displayTags" role="group" aria-label="Tags">
        <div v-if="maxSelectedTagsCount">
            <hr />
            <h5>Selected tags {{ selectedTagsCount }}/{{ maxSelectedTagsCount }}</h5>
        </div>
        <div class="container-buttons">
            <div v-if="tagsWithStatuses" v-for="(array, i) in splitByFirstLetter(tagsWithStatuses)" class="category-row">
                <div class="letter">
                    {{ array[0].tag.name[0] }}
                </div>
                <TagSelectButton v-for="tag in array"
                                 :tag="tag.tag"
                                 :status="tag.status"
                                 :canBeDeselected="canBeDeselected"
                                 @statusChanged="statusChanged"></TagSelectButton>
            </div>
        </div>
    </div>
</template>

<script>
    import TagSelectButton from './TagSelectButton.vue'

    export default {
        name: 'TagSelectContainer',
        emits: ['statusesChanged'],
        components: {
            TagSelectButton
        },
        props: {
            tags: {
                default: null,
                type: Array
            },
            statusesByTagIds: {
                default: {},
                type: Object
            },
            canBeDeselected: {
                default: false,
                type: Boolean
            },
            maxSelectedCategoriesCount: {
                default: null,
                type: Number
            },
            maxSelectedTagsCount: {
                default: null,
                type: Number
            },
            maxSelectedGenderPreferencesCount: {
                default: null,
                type: Number
            },
            displayTags: {
                default: true,
                type: Boolean
            },
            showGenderPreferences: {
                default: true,
                type: Boolean
            }
        },
        data() {
            return {
                genderPreferencesWithStatuses: null,
                categoriesWithStatuses: null,
                tagsWithStatuses: null,
                selectedGenderPreferencesCount: 0,
                selectedCategoriesCount: 0,
                selectedTagsCount: 0
            }
        },
        methods: {
            splitByFirstLetter(tagsWithStatuses) {
                const dict = {}

                tagsWithStatuses.forEach(x => {
                    const index = x.tag.name[0].toLowerCase()

                    if (dict[index] == undefined) {
                        dict[index] = []
                    }

                    dict[index].push(x)
                })

                return dict
            },
            statusChanged(tagId, status) {
                if (this.selectedGenderPreferencesCount != this.maxSelectedGenderPreferencesCount || status != "SELECTED") {
                    this.selectedGenderPreferencesCount = 0

                    if (this.canBeDeselected) {
                        for (const genderPreference of this.genderPreferencesWithStatuses) {
                            if (genderPreference.status == "DEFAULT") {
                                genderPreference.status = "DESELECTED"
                            }

                            if (genderPreference.tag.id == tagId) {
                                genderPreference.status = status == "DEFAULT" ? "SELECTED" : status
                            }

                            if (genderPreference.status == "SELECTED") {
                                this.selectedGenderPreferencesCount += 1
                            }
                        }

                        if (this.selectedGenderPreferencesCount == 0) {
                            for (const genderPreference of this.genderPreferencesWithStatuses) {
                                genderPreference.status = "DEFAULT"
                            }                            
                        }
                    }
                    else {
                        for (const genderPreference of this.genderPreferencesWithStatuses) {
                            if (genderPreference.tag.id == tagId) {
                                genderPreference.status = status
                            }
                            if (genderPreference.status == "SELECTED") {
                                this.selectedGenderPreferencesCount += 1
                            }
                        }
                    }
                }

                if (this.selectedCategoriesCount != this.maxSelectedCategoriesCount || status != "SELECTED") {
                    this.selectedCategoriesCount = 0
                    for (const category of this.categoriesWithStatuses) {
                        if (category.tag.id == tagId) {
                            category.status = status
                        }
                        if (category.status == "SELECTED") {
                            this.selectedCategoriesCount += 1
                        }
                    }
                }

                if (this.selectedTagsCount != this.maxSelectedTagsCount || status != "SELECTED") {
                    this.selectedTagsCount = 0
                    for (const tag of this.tagsWithStatuses) {
                        if (tag.tag.id == tagId) {
                            tag.status = status
                        }
                        if (tag.status == "SELECTED") {
                            this.selectedTagsCount += 1
                        }
                    }
                }

                this.$emit("statusesChanged", this.tagsWithStatuses, this.categoriesWithStatuses, this.genderPreferencesWithStatuses)
            },
        },
        mounted() {
            this.genderPreferencesWithStatuses = []
            this.categoriesWithStatuses = []
            this.tagsWithStatuses = []

            if (this.tags == null) {
                return
            }

            this.tags.filter(x => x.isGenderPreference).forEach(x => {
                const genderPreferenceWithStatus = { tag: x, status: this.statusesByTagIds[x.id] ? this.statusesByTagIds[x.id] : "DEFAULT" }

                if (genderPreferenceWithStatus.status == "SELECTED") {
                    this.selectedGenderPreferencesCount += 1
                }

                this.genderPreferencesWithStatuses.push(genderPreferenceWithStatus)
            })

            this.tags.filter(x => x.isCategory && !x.isGenderPreference).forEach(x => {
                const categoryWithStatus = { tag: x, status: this.statusesByTagIds[x.id] ? this.statusesByTagIds[x.id] : "DEFAULT" }

                if (categoryWithStatus.status == "SELECTED") {
                    this.selectedCategoriesCount += 1
                }

                this.categoriesWithStatuses.push(categoryWithStatus)
            })

            this.tags.filter(x => !x.isCategory && !x.isGenderPreference).forEach(x => {
                const tagWithStatus = { tag: x, status: this.statusesByTagIds[x.id] ? this.statusesByTagIds[x.id] : "DEFAULT" }

                if (tagWithStatus.status == "SELECTED") {
                    this.selectedTagsCount += 1
                }

                this.tagsWithStatuses.push(tagWithStatus)
            })

            this.splitByFirstLetter(this.tagsWithStatuses)
        }
    }
</script>

<style scoped>
    .container-buttons {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        padding: 0;
        gap: 0.5rem;
    }

    .category-row {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        gap: 0.3rem;
    }

    .letter {
        color: white;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        width: 30px;
    }

    h5 {
        font-weight: 600;
        font-size: 16px;
        color: white;
        padding-top: 16px;
        padding-bottom: 16px;
    }
</style>