<template>
    <h4 class="title">Applications</h4>
    <div class="card card-dark">
        <div class="card-body">
            <v-data-table :headers="headers"
                          :items="accountApplications"
                          item-key="userId"
                          :items-per-page="pageSize"
                          density="compact">

                <template v-slot:item.isApproved="{ item }">
                    <div class="col status">
                        <div v-if="item.isDenied" class="tag denied">Denied</div>
                        <div v-else-if="item.isApproved" class="tag approved">Approved</div>
                        <div v-else class="tag waiting">Waiting</div>
                    </div>
                </template>

                <template v-slot:item.profile.name="{ item }">
                    <div @click="redirectToProfile(item.profile.handleName)" class="col profile-name">{{ item.profile.name }}</div>
                </template>

                <template v-slot:item.userId="{ item }">
                    <div class="col action">
                        <button v-if="!item.isApproved" @click="decide(item)" class="btn btn-sm btn-primary">Decide</button>
                    </div>
                </template>

                <template v-slot:bottom>
                    <div class="text-center pt-2">
                        <v-pagination v-model="page"
                                      :length="Math.ceil(totalAccountApplications / pageSize)"
                                      :total-visible="6"
                                      @update:modelValue="loadAccountApplications()"></v-pagination>
                    </div>
                </template>

            </v-data-table>
        </div>
    </div>
    <div v-if="error" class="color-red error mb-2">{{ error }}</div>

</template>

<script>
    import requests from './../api/requests.js'
    import { formatDate, dateToTimeAgoString } from './../utils.js'
    import Swal from 'sweetalert2'

    export default {
        name: 'AccountApplicationsTable',
        components: {

        },
        props: {

        },
        data() {
            return {
                accountApplications: [],
                totalAccountApplications: 0,
                pageSize: 10,
                page: 1,
                headers: [
                    { title: 'Status', value: 'isApproved' },
                    { title: 'Profile', value: 'profile.name' },
                    { title: 'Email', value: 'user.email' },
                    { title: 'Created At', value: 'created' },
                    { title: 'Action', value: 'userId', align: 'center' },
                ],
                error: null
            }
        },
        methods: {
            redirectToProfile(profileHandle) {
                this.$router.push({ name: 'Profile', params: { handle: profileHandle } })
            },
            async loadAccountApplications() {
                const response = await requests.Monetization.AccountApplication.GetAllAccountApplications((this.page - 1) * this.pageSize, this.pageSize)
                if (!response.isSuccess) {
                    this.error = response.error
                    return
                }

                let accountApplications = response.accountApplications
                this.totalAccountApplications = response.totalCount

                const userProfilesLookupResponse = await requests.UserContent.UserProfiles.LookupByUserIds(accountApplications.map(x => x.userId))
                if (!userProfilesLookupResponse.isSuccess) {
                    this.error = userProfilesLookupResponse.error
                    return
                }

                const usersLookupResponse = await requests.UserAccess.Users.LookupByIds(accountApplications.map(x => x.userId))
                if (!usersLookupResponse.isSuccess) {
                    this.error = usersLookupResponse.error
                    return
                }

                const profilesByUserIdsDict = {}
                for (const profile of userProfilesLookupResponse.userProfiles) {
                    profilesByUserIdsDict[profile.userId] = profile
                }

                const usersByUserIdsDict = {}
                for (const user of usersLookupResponse.users) {
                    usersByUserIdsDict[user.id] = user
                }

                for (const accountApplication of accountApplications) {
                    accountApplication.profile = !profilesByUserIdsDict[accountApplication.userId]
                        ? null
                        : profilesByUserIdsDict[accountApplication.userId]

                    accountApplication.user = !usersByUserIdsDict[accountApplication.userId]
                        ? null
                        : usersByUserIdsDict[accountApplication.userId]

                    accountApplication.created = formatDate(accountApplication.created)
                }

                this.accountApplications = accountApplications
            },
            formatDate(date) {
                return formatDate(date)
            },
            async decide(accountApplication) {

                let html = '<div>'

                html += '<div>'
                html += '<h4>User Information</h4>'
                html += '<span>Username: ' + accountApplication.user.username + '</span><br/>'
                html += '<span>Email: ' + accountApplication.user.email + '</span><br/><br/>'
                html += '<span>Created: ' + dateToTimeAgoString(accountApplication.user.created, true) + '</span>'
                html += '</div><br/>'
                html += '<h4>Profile Information</h4>'
                html += '<span>Name: ' + accountApplication.profile.name + '</span><br/>'
                html += '<span>Handle: ' + accountApplication.profile.handleName + '</span>'
                html += '</div>'

                html += '</div>'

                const { value: formValue } = await Swal.fire({
                    html: html,
                    focusConfirm: false,
                    background: "#262639",
                    color: "white",
                    customClass: {
                        confirmButton: 'dialog-btn-secondary color-green',
                        denyButton: 'dialog-btn-secondary color-red',
                        cancelButton: 'dialog-btn-secondary'
                    },
                    confirmButtonText: 'Approve',
                    denyButtonText: 'Deny',
                    cancelButtonText: 'Cancel',
                    showConfirmButton: !accountApplication.isApproved,
                    showDenyButton: !accountApplication.isDenied && !accountApplication.isApproved,
                    showCancelButton: true,
                    buttonsStyling: false,
                    preConfirm: async () => {
                        const approveResult = await requests.Monetization.AccountApplication.ApproveAccountApplication(accountApplication.userId)

                        return approveResult.isSuccess ? "CONFIRMED" : approveResult.error
                    },
                    preDeny: async () => {
                        const denyResult = await requests.Monetization.AccountApplication.DenyAccountApplication(accountApplication.userId)

                        return denyResult.isSuccess ? "DENIED" : denyResult.error
                    }
                })
                
                if (formValue == "CONFIRMED" || formValue == "DENIED") {
                    Swal.fire({
                        icon: 'success',
                        timer: 1000,
                        showConfirmButton: false,
                        background: "#262639",
                        color: "white"
                    })

                    await this.loadAccountApplications()
                } else if (formValue) {
                    Swal.fire({
                        icon: 'error',
                        title: formValue,
                        background: "#262639",
                        color: "white"
                    })
                }
            },
        },
        async mounted() {
            requests.initialize(this.$store)

            await this.loadAccountApplications()
        }
    }
</script>

<style scoped>
    .title {
        margin-top: 32px;
        margin-bottom: 16px;
        letter-spacing: 1px;
    }

    .error {
        border-radius: 5px;
        padding: 10px;
    }

    .col {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
    }

        .col.profile-name {
            cursor: pointer;
        }

        .col.status {
            width: 0;
        }

        .col.action {
            display: flex;
            justify-content: center;
        }

            .col.action > button {
                width: 100px;
            }

    .tag {
        border-radius: 6px;
        padding: 4px;
    }

        .tag.denied {
            background-color: var(--color-red);
        }

        .tag.waiting {
            background-color: var(--color-orange);
        }

        .tag.approved {
            background-color: var(--color-green);
        }
</style>
