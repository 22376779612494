<template>
    <div v-if="!importStarted && !importDto && !importTask" class="row">
        <div>
            <h5>
                How it works
            </h5>
            <div class="card card-dark" @keydown.enter="importStarted = true">
                <div class="card-body">
                    <div class="instructions">
                        <div>
                            <span class="step">Step&nbsp;1</span>
                            Insert profile address
                        </div>
                        <div class="arrow">
                            <img src="/icons/arrow-right.svg" alt="arrow right icon"/>
                        </div>
                        <div>
                            <span class="step">Step&nbsp;2</span>
                            Confirm ownership
                        </div>
                        <div class="arrow">
                            <img src="/icons/arrow-right.svg" alt="arrow right icon"/>
                        </div>
                        <div>
                            <span class="step">Step&nbsp;3</span>
                            Automatically import audio's
                        </div>
                        <div class="arrow">
                            <img src="/icons/arrow-right.svg" alt="arrow right icon"/>
                        </div>
                        <div>
                            <span class="step">Step&nbsp;4</span>
                            Done!
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-4 bottom-buttons">
                <button v-if="user == null" class="btn btn-primary" @click="login()">Log In</button>
                <button v-if="user == null" class="btn btn-secondary" @click="register()">Register</button>
                <button v-if="user != null" class="btn btn-primary" @click="importStarted = true">Let's start!</button>
            </div>
        </div>
    </div>
    <div v-if="importDto" class="row">
        <div>
            <h5 :class="[(finishedCount == importDto.audios.length ? 'color-primary' : ''),]">
                Import 
                <span v-if="finishedCount == importDto.audios.length">Successful</span>
                <span v-else>{{ finishedCount }}/{{ importDto.audios.length }}</span>
            </h5>
            <div class="color-orange">
                <fai :icon="['fas', 'circle-info']"></fai>
                Make sure your profile is public. Go to settings.
            </div>
            <div class="color-orange">
                <fai :icon="['fas', 'circle-info']"></fai>
                Don't forget to properly tag your audios. You can do it now by editing them.
            </div>
            <div class="audio-table">
                <div v-for="audio in importDto.audios" class="audio-row">
                    <div class="audio-title page-two" @click="redirectToAudio(audio.id)">
                        {{ audio.name }}
                    </div>
                    <div class="audio-presets page-two">
                        <button class="btn btn-edit btn-sm" @click="editAudio(audio.id)">
                            Edit&nbsp;<img src="/icons/vector.svg" alt="vector icon" />
                        </button>
                        <div class="col-2 text-center">
                            <fai v-if="audio.status == 3" :icon="['fas', 'xmark']"></fai>
                            <fai v-if="audio.status == 2" :icon="['fas', 'check']"></fai>
                            <fai v-if="audio.status == 1" :icon="['fas', 'spinner']" spin></fai>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else-if="importTask" class="row">
        <div>
            <h5>Import Is Being Prepared</h5>
            <div class="card card-dark mt-3">
                <div class="logo-pulse">
                    <img src="images/logo-pulse.gif" width="120" />
                    <span class="mt-4">Preparing your import...</span>
                    <span>This can take a while.</span>
                    <span class="color-orange">You can safely leave and return later.</span>
                </div>
            </div>
        </div>
    </div>
    <div v-if="importStarted && !importDto && !importTask">
        <div v-if="!isValidUsername" class="row">
            <div>
                <h5>
                    Tell us where is your profile
                </h5>
                <div class="card card-dark" @keydown.enter="checkUsername">
                    <div class="card-body">
                        <div class="profile-select-container">
                            <div class="type-select-row">
                                <span :class="['btn', importType == 'SOUNDGASM' ? 'selected' : '']" @click="changeImportType('SOUNDGASM')">
                                    Soundgasm
                                </span>
                                <span :class="['btn', importType == 'EROCAST' ? 'selected' : '']" @click="changeImportType('EROCAST')">
                                    Erocast
                                </span>
                            </div>
                            <div v-if="importType == 'SOUNDGASM'" class="mt-3">
                                <div class="color-orange">
                                    <fai :icon="['fas', 'circle-info']"></fai>
                                    The profile has to have atleast one audio uploaded.
                                </div>
                            </div>
                            <div v-if="importType == 'EROCAST'" class="mt-3">
                                <div class="color-orange">
                                    <fai :icon="['fas', 'circle-info']"></fai>
                                    You will find username in erocast.me/<span class="color-pink">USERNAME</span>.
                                </div>
                            </div>
                            <div class="username-input mt-3">
                                <div v-if="importType == 'SOUNDGASM'" class="soundgasm-url">
                                    soundgasm.net/u/
                                </div>
                                <div v-if="importType == 'EROCAST'" class="soundgasm-url">
                                    erocast.me/
                                </div>
                                <div class="input">
                                    <input type="text" class="form-control" placeholder="username" v-model="username" autocomplete="username" @change="isValidUsername = false" :disabled="isValidUsername" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bottom-buttons mt-4">
                    <div v-if="usernameValidityMessage" class="text-center mt-3 color-red">
                        {{ usernameValidityMessage }}
                    </div>
                    <button class="btn btn-primary" :disabled="isValidUsername || username.length <= 0" @click="checkUsername">
                        <fai v-show="isCheckingUsername" :icon="['fas', 'spinner']" class="spinner" spin></fai>
                        <span v-show="!isCheckingUsername && !usernameValidityMessage">Continue</span>
                        <span v-show="!isCheckingUsername && usernameValidityMessage">Try again</span>
                    </button>
                </div>
            </div>
        </div>

        <div v-if="isValidUsername && !isConfirmed" class="row">
            <div>
                <h5>
                    Confirm That You Own The Profile
                </h5>
                <div v-if="!wasAlreadyConfirmed && !wasConfirmedBySomeoneElse" class="color-orange">
                    <fai :icon="['fas', 'circle-info']"></fai>
                    Upload any audio to your profile and name it {{ confirmCode ? confirmCode : 'XXX' }}. Then click the confirm button.
                </div>
                <div class="card card-dark mt-3">
                    <div class="card-body">
                        <div class="confirm-code-container">
                            <div class="confirm-code">
                                <div v-if="wasAlreadyConfirmed">
                                    Ownership confirmed
                                </div>
                                <div v-if="wasConfirmedBySomeoneElse">
                                    Ownership already confirmed by someone else.
                                </div>
                                <div v-if="confirmCode">
                                    {{ confirmCode }}
                                </div>
                                <div v-if="!confirmCode && !wasAlreadyConfirmed && !wasConfirmedBySomeoneElse">
                                    <div class="dot-flashing"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bottom-buttons mt-4">
                    <div v-if="confirmError" class="text-center mt-3 color-red">
                        {{ confirmError }}
                    </div>
                    <div>
                        <button v-if="!wasConfirmedBySomeoneElse" :class="['btn', isConfirmed  ? 'btn-tertiary' : 'btn-primary', 'check-username-btn']" @click="checkOwnership">
                            <span v-if="!isConfirmed && !isCheckingOwnership && !confirmError">Confirm</span>
                            <span v-if="!isConfirmed && !isCheckingOwnership && confirmError">Try again</span>
                            <fai v-if="isCheckingOwnership" :icon="['fas', 'spinner']" spin></fai>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="isConfirmed && !isConfirmedGenderPreferenceChoice" class="row">
            <div>
                <h5>
                    Specify Default Gender Preference
                </h5>
                <div class="color-orange">
                    <fai :icon="['fas', 'circle-info']"></fai>
                    You can change these per audio in the next step.
                </div>
                <div class="card card-dark mt-3">
                    <div class="card-body">
                        <div>
                            <div class="gender-preference-buttons">
                                <TagSelectButton v-for="genderPreference in genderPreferences"
                                                 :tag="genderPreference.tag"
                                                 :status="genderPreference.status"
                                                 :canBeDeselected="false"
                                                 @statusChanged="statusChanged"></TagSelectButton>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bottom-buttons mt-4">
                    <div>
                        <button :disabled="!defaultGenderPreference" :class="['btn', 'btn-primary']" @click="isConfirmedGenderPreferenceChoice = true">
                            Continue
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="defaultGenderPreference && isConfirmedGenderPreferenceChoice" class="row">
            <div>
                <h5>
                    Select Audios For Import
                </h5>
                <div class="color-orange">
                    <fai :icon="['fas', 'circle-info']"></fai>
                    You can import only once. Choose wisely.
                </div>
                <div v-if="isFailedGettingImportAudios" class="mt-4">
                    <div class="color-red">
                        <fai :icon="['fas', 'circle-info']"></fai>
                        Could not load audios.
                    </div>
                    <button class="btn btn-primary mt-2" @click="loadImportAudios()">
                        Try again
                    </button>
                </div>
                <div v-else-if="importAudios">
                    <div class="audio-table">
                        <div v-for="importObject in importAudios" class="audio-row">
                            <div class="audio-title" :title="importObject.audio.name">
                                <div class="audio-name">
                                    {{ importObject.audio.name }}
                                </div>
                            </div>
                            <div class="audio-presets">
                                <TagSelectButton :tag="importObject.genderPreference"
                                                 :status="'SELECTED'"
                                                 :canBeDeselected="false"></TagSelectButton>
                                <div class="select-gender-preference" @click="selectGenderPreference(importObject.id)">
                                    <fai :icon="['fas', 'plus']"></fai>
                                </div>
                                <input class="form-check-input" type="checkbox" v-model="importObject.shouldImport">
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="text-center mt-4 d-flex justify-content-center">
                    <div class="dot-flashing"></div>
                </div>
                <div class="bottom-buttons center mt-4 mb-4">
                    <button v-if="importAudios" :disabled="isBeginningImport" :class="['btn', autoDetectTags ? 'btn-primary' : 'btn-secondary', 'btn-begin-import']" @click="autoDetectTags = !autoDetectTags">
                        <span v-if="autoDetectTags">Auto detect Tags - On</span>
                        <span v-else>Auto detect Tags - Off</span>
                    </button>
                </div>
                <div class="bottom-buttons center mt-4 mb-4">
                    <button v-if="importAudios" class="btn btn-primary btn-begin-import" @click="beginImport()">
                        <span v-if="!isBeginningImport">Begin import&nbsp;</span>
                        <span v-if="isBeginningImport">
                            <fai :icon="['fas', 'spinner']" spin></fai>
                            &nbsp;This can take a while.
                        </span>
                        <fai v-if="!isBeginningImport" :icon="['fas', 'download']"></fai>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import requests from "../api/requests"
import Swal from 'sweetalert2'
import TagSelectButton from './TagSelectButton.vue'
import { setMetadata } from '../utils.js'


export default {
    name: 'ImportPage',
    components: {
        TagSelectButton
    },
    data() {
        return {
            importType: "SOUNDGASM",
            importStarted: false,
            username: "",
            isValidUsername: false,
            isCheckingUsername: false,
            isCheckingOwnership: false,
            isLoadingConfirmCode: false,
            isConfirmingOwnership: false,
            isConfirmedGenderPreferenceChoice: false,
            isBeginningImport: false,
            isConfirmed: false,
            isFailedGettingImportAudios: false,
            wasAlreadyConfirmed: false,
            wasConfirmedBySomeoneElse: false,
            step: 1,
            selectedGenderPreferencesCount: 0,
            genderPreferences: [],
            defaultGenderPreference: null,
            confirmCode: null,
            importAudios: null,
            usernameValidityMessage: null,
            confirmError: null,
            importDto: null,
            polling: null,
            isLoadingImport: false,
            importTask: null,
            finishedCount: 0,
            autoDetectTags: true
        }
    },
    computed: {
        user() {
            if (this.$store.getters.userData == null) {
                return null
            }
            
            return this.$store.getters.userData.user
        },
        userData() {
            return this.$store.getters.userData
        }
    },
    methods: {
        login() {
            this.$router.push('/login')
        },
        register() {
            this.$router.push('/register')
        },
        changeImportType(importType) {
            if (this.isValidUsername) {
                return
            }

            this.importType = importType
        },
        async checkUsername() {
            if (this.isCheckingUsername) {
                return
            }

            if (this.username.length <= 0) {
                return
            }

            this.isCheckingUsername = true

            const result = await requests.UserContent.Import.CheckProfileExists(this.importType, this.username)
            if (result.isSuccess && result.exists) {
                this.wasConfirmedBySomeoneElse = result.isConfirmedBySomeoneElse

                await this.loadConfirmCode()

                this.usernameValidityMessage = null
                this.isValidUsername = true
            } else {
                this.usernameValidityMessage = "This profile does not exist."
            }

            this.isCheckingUsername = false
        },
        async loadGenderPreferences() {
            const response = await requests.UserContent.Tag.GetAll()
            if (response.isSuccess) {
                this.genderPreferences = []

                response.tags.filter(x => x.isGenderPreference).forEach(x => {
                    const genderPreferenceWithStatus = { tag: x, status: "DEFAULT" }

                    this.genderPreferences.push(genderPreferenceWithStatus)
                })
            }
        },
        async loadConfirmCode() {
            if (this.wasConfirmedBySomeoneElse) {
                return
            }

            this.isLoadingConfirmCode = true

            const result = await requests.UserContent.Import.CreateProfileOwnership(this.importType, this.username)
            if (result.isSuccess) {
                if (result.wasAlreadyConfirmed) {
                    this.isConfirmed = true
                    this.wasAlreadyConfirmed = true
                }

                this.confirmCode = result.confirmCode
            }

            this.isLoadingConfirmCode = false
        },
        async loadImport() {
            if (this.isLoadingImport) {
                return
            }
            this.isLoadingImport = true

            const result = await requests.UserContent.Import.GetImport()
            if (result.isSuccess && result.import) {
                this.importDto = result.import
                this.trySetPolling()

                if (!this.importDto.audios.some(x => x.status == 1)) {
                    clearInterval(this.polling)
                }

                this.finishedCount = this.importDto.audios.filter(x => x.status > 1).length

                if (!this.user.isCreator) {
                    this.user.isCreator = true
                    this.$store.commit("setUserData", this.userData)
                }
            }

            this.isLoadingImport = false
        },
        trySetPolling() {
            if (this.polling) {
                return
            }

            this.polling = setInterval(async () => {
                await this.loadImport()
                if (!this.importTask || !this.importTask.processed) {
                    await this.loadImportTask()
                }
            }, 3000)
        },
        async loadImportTask() {
            const result = await requests.UserContent.Import.GetMostRecentImportTask()
            if (!result.isSuccess) {
                return
            }

            this.importTask = result.importTask
            if (this.importTask) {
                this.trySetPolling()
            }
        },
        async loadImportAudios() {
            this.importAudios = null
            this.isFailedGettingImportAudios = false

            const result = await requests.UserContent.Import.GetImportAudios(this.importType, this.username)
            if (result.isSuccess) {
                this.importAudios = result.audios.map(x => {
                    return {
                        id: x.pageUrl,
                        audio: x,
                        genderPreference: this.defaultGenderPreference,
                        shouldImport: true
                    }
                })
            } else {
                this.isFailedGettingImportAudios = true
            }
        },
        async checkOwnership() {
            if (this.isCheckingOwnership || this.isConfirmed) {
                return
            }
            if (this.wasConfirmedBySomeoneElse) {
                return
            }

            this.isCheckingOwnership = true

            this.confirmError = null
            const result = await requests.UserContent.Import.ConfirmProfileOwnership(this.importType, this.username)
            if (result.isSuccess) {
                if (result.isConfirmed) {
                    this.isConfirmed = true
                } else {
                    this.isConfirmed = false
                    this.confirmError = "Profile was not verified."
                }
            } else {
                this.isConfirmed = false
            }

            this.isCheckingOwnership = false
        },
        async beginImport() {
            if (this.isBeginningImport) {
                return
            }

            this.isBeginningImport = true

            const names = this.importAudios.filter(x => x.shouldImport).map(x => x.audio.name)
            const urls = this.importAudios.filter(x => x.shouldImport).map(x => x.audio.pageUrl)
            const genderPreferenceIds = this.importAudios.filter(x => x.shouldImport).map(x => x.genderPreference.id)

            const result = await requests.UserContent.Import.BeginImport(this.importType, this.username, names, urls, genderPreferenceIds, this.autoDetectTags)
            if (result.isSuccess) {
                await this.loadImportTask()
                this.trySetPolling()
            }

            this.isBeginningImport = false
        },
        async selectGenderPreference(importAudioId) {
            const selectedGenderPreference = this.importAudios.find(x => x.id == importAudioId).genderPreference
            const genderPreferenceOptions = []

            let html = '<fieldset>'
            for (const genderPreference of this.genderPreferences) {
                genderPreferenceOptions.push(genderPreference)

                html += '<div class="gender-preference-radio mt-1">'
                html += '<input id="genderPreferenceRadio-' + genderPreference.tag.id + '" class="form-check-input" type="radio" ' + (selectedGenderPreference.id == genderPreference.tag.id ? 'checked' : '') + ' name="genderPreferenceCheckbox"/>&nbsp;'
                html += '<label>' + genderPreference.tag.name + '</label>'
                html += '</div>'
            }
            html += '</fieldset>'

            const { value: formValue } = await Swal.fire({
                html: html,
                focusConfirm: false,
                background: "#262639",
                color: "white",
                customClass: {
                    confirmButton: 'dialog-btn-primary',
                    denyButton: 'dialog-btn-primary',
                    cancelButton: 'dialog-btn-secondary'
                },
                buttonsStyling: false,
                preConfirm: () => {
                    let selectedGenderPreference
                    for (const option of genderPreferenceOptions) {
                        if (document.getElementById("genderPreferenceRadio-" + option.tag.id).checked) {
                            selectedGenderPreference = option.tag
                        }
                    }

                    return selectedGenderPreference
                }
            })
            if (formValue) {
                const importAudio = this.importAudios.find(x => x.id == importAudioId)
                importAudio.genderPreference = formValue
            }
        },
        async statusChanged(tagId, status) {
            if (this.selectedGenderPreferencesCount >= 1) {
                this.selectedGenderPreferencesCount = 0

                for (const genderPreference of this.genderPreferences) {
                    genderPreference.status = "DEFAULT"
                }
            }

            if (this.selectedGenderPreferencesCount < 1) {
                this.selectedGenderPreferencesCount = 0

                for (const genderPreference of this.genderPreferences) {
                    if (genderPreference.tag.id == tagId) {
                        genderPreference.status = "SELECTED"
                        this.defaultGenderPreference = genderPreference.tag
                    }
                    if (genderPreference.status == "SELECTED") {
                        this.selectedGenderPreferencesCount += 1
                    }
                }
            }

            await this.loadImportAudios()
        },
        redirectToAudio(audioId) {
            this.$router.push({ name: 'Audio', params: { id: audioId } })
        },
        editAudio(audioId) {
            this.$router.push({ name: 'Edit audio', params: { id: audioId } })
        }
    },
    async mounted() {
        requests.initialize(this.$store)
        setMetadata("Import | AudioLove", "You can easily import your Soundgasm or Erocast profiles. It will take just a minute.")

        await this.loadGenderPreferences()
        await this.loadImportTask()
        await this.loadImport()
    },
    beforeUnmount() {
        if (this.polling) {
            clearInterval(this.polling)
        }
    }
}
</script>

<style scoped>
    .instructions {
        display: flex;
        flex-direction: column;      
    }

        .instructions > div {
            display: flex;
            flex-direction: column;
            padding-top: 16px;
        }

    .step {
        font-size: 20px;
        line-height: 30px;
        font-weight: 600;
        padding-bottom: 12px;
        color: white;
    }

    .arrow {
        display: none !important;
    }

    @media only screen and (min-width: 1400px) {
        .instructions {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

            .instructions > div {
                padding-top: 0px;
            }

        .arrow {
            display: flex !important;
        }
    }

    .start-buttons {
        display: flex;
        justify-content: center;
        gap: 1rem;
    }

        .start-buttons button {
            min-width: 8rem;
            min-height: 2.5rem;
        }

    .username-input {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }   

        .username-input div.input {
            width: 100%;
        }

    @media only screen and (min-width: 60rem) {
        .username-input {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }

    .confirm-code-container {
        display: flex;
        justify-content: center;
    }

    .confirm-code {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        padding: 12px 32px 12px 32px;
        background-color: #414161;
        color: white;
        border-radius: 6px;
    }

    .soundgasm-url {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: white;
    }    

    .arrow-next {
        font-size: 1.6rem;
    }

    .gender-preference-buttons {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        padding: 0;
        gap: 0.5rem;
    }

    .audio-table .gender-preference-buttons {
        justify-content: end;
        height: 1rem;
    }

    .audio-table {
        width: 100%;
        padding-top: 16px
    }

    .audio-table .audio-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 4px;
        margin-bottom: 8px;
        border-radius: 10px;
        padding: 4px 8px 4px 8px;
    }

        .audio-table .audio-row:nth-child(even) {
            background-color: var(--secondary-background);
        }

        .audio-table .audio-row:nth-child(odd) {
            background-color: transparent;
            border: 1px #46424F solid;
        }

        .audio-table .audio-row .audio-title {
            width: 100%;
            overflow: hidden;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .audio-table .audio-row .audio-title.page-two {
            cursor: pointer;
            transition: color var(--def-transition-duration) ease-in-out;
        }

            .audio-table .audio-row .audio-title.page-two:hover {
                color: var(--primary-color);
            }

            .audio-table .audio-row .audio-presets {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 4px;
            }

            .audio-table .audio-row .audio-presets.page-two {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 32px;
                padding-right: 16px;
            }

            .audio-table .audio-row .audio-presets .form-check-input {
                margin-top: 0;
                transform: scale(2);
                margin-right: 6px;
                background-color: transparent;
                border: 1px var(--primary-color) solid;
            }

    .select-gender-preference {
        border-radius: 6px;
        border: 1px solid var(--color-green);
        padding-left: 0.3rem;
        padding-right: 0.3rem;
        margin-right: 14px;
        margin-left: 6px;
        cursor: pointer;
        user-select: none;
        height: 100%;
        transform: scale(1.29);
    }

    .type-select-row {
        display: flex;
        justify-content: center;
        gap: 1rem;
        border-radius: 10px;
        background-color: #313148;
        padding: 12px;
    }

        .type-select-row span {
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            background-color: #1E1E2F;
            border: 1px transparent solid;
            color: white;
            transition: all var(--def-transition-duration) ease-in-out;
            width: 100%;
            padding: 16px 24px 16px 24px;
        }

        .type-select-row span.selected {
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            background-color: var(--primary-color-transparent);
            border-color: var(--primary-color);
        }

    h5 {
        font-size: 28px;
        line-height: 42px;
        font-weight: 600;
        padding-bottom: 12px;
    }

    .bottom-buttons {
        display: flex;
        flex-direction: row;
        gap: 16px;
        justify-content: flex-end;
    }

        .bottom-buttons.center {
            justify-content: center;
        }

        .bottom-buttons button {
            width: 250px;
            padding: 16px 24px 16px 24px;
        }

            .bottom-buttons button.btn-secondary {
                border: 1px var(--primary-color) solid !important;
                border-radius: 50px !important;
            }

    @media only screen and (max-width: 40rem) {
        .bottom-buttons {
            flex-direction: column;
        }

            .bottom-buttons button {
                width: 100%;
            }
    }

    .profile-select-container {
        padding: 12px 220px 12px 220px;
    }

    @media only screen and (max-width: 1550px) {
        .profile-select-container {
            padding: 12px 120px 12px 120px;
        }
    }

    @media only screen and (max-width: 1150px) {
        .step-sign {
            display: none;
        }

        .profile-select-container {
            padding: 12px 0px 12px 0px;
        }
    }

    @media only screen and (max-width: 900px) {
        .profile-select-container .type-select-row {
            flex-direction: column;
        }
    }

    .gender-preference-buttons {
        display: flex;
        flex-direction: row;
    }

    @media only screen and (max-width: 900px) {
        .gender-preference-buttons {
            flex-direction: column;
            text-align: center;
        }
    }

    .btn-edit {
        display: flex;
        align-items: center;
        color: white;
        border: 1px #46424F solid;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
    }

    .btn-edit:hover {
        border-color: var(--primary-color);
    }

    .logo-pulse {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .btn-begin-import {
        min-width: 340px;
    }
</style>