<template>
    <div v-if="user && user.isAdmin && $route.params.userId == null" class="admin-container">
        <div class="top-cards-container">
            <div class="card card-dark top-card" :title="admin.totalListenCount + ' listens total'">
                <div class="top-card-body card-body">
                    <img src="/icons/music.svg" alt="music icon"/>
                    <div class="top-card-amount">
                        <h3 v-if="admin.totalListenCount != null">{{ admin.totalListenCount }}</h3>
                        <div v-else class="dot-flashing"></div>
                    </div>
                </div>
            </div>

            <div class="card card-dark top-card" :title="admin.totalUpvoteCount + ' upvotes total'">
                <div class="top-card-body card-body">
                    <img src="/icons/heart.svg" alt="heart icon"/>
                    <div class="top-card-amount">
                        <h3 v-if="admin.totalUpvoteCount != null">{{ admin.totalUpvoteCount }}</h3>
                        <div v-else class="dot-flashing"></div>
                    </div>
                </div>
            </div>

            <div class="card card-dark top-card" :title="admin.publicProfileCount + ' public profiles and ' + admin.privateProfileCount + ' private ones.'">
                <div class="top-card-body card-body">
                    <img src="/icons/profile.svg" alt="profile icon"/>
                    <div class="top-card-amount">
                        <h3 v-if="admin.publicProfileCount != null">{{ admin.publicProfileCount }}<span v-if="admin.privateProfileCount > 0">&nbsp;|&nbsp;{{ admin.privateProfileCount }}</span></h3>
                        <div v-else class="dot-flashing"></div>
                    </div>
                </div>
            </div>

            <div class="card card-dark top-card" :title="admin.publicAudioCount + ' public audios and ' + admin.privateAudioCount + ' private ones.'">
                <div class="top-card-body card-body">
                    <img src="/icons/microphone.svg" alt="microphone icon"/>
                    <div class="top-card-amount">
                        <h3 v-if="admin.publicAudioCount != null">{{ admin.publicAudioCount }}<span v-if="admin.privateAudioCount > 0">&nbsp;|&nbsp;{{ admin.privateAudioCount }}</span></h3>
                        <div v-else class="dot-flashing"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row chart-row">
            <div class="col-12">
                <TotalAudioListensByTimeChart :isAdminChart="true" />
            </div>
        </div>     
        <div class="row chart-row">
            <div class="col-12">
                <TotalAudioListenTimeChart :isAdminChart="true" />
            </div>
        </div>
        <div class="row chart-row">
            <div class="col-12 col-xl-6 most-listened-creators-container">
                <MostListenedCreatorsChart />
            </div>
            <div class="col-12 col-xl-6 most-listened-creators-container">
                <MostListenedAudiosChart :isAdminChart="true" />
            </div>
        </div>
        <div class="row chart-row">
            <div class="col-12">
                <MostListenedCategoriesChart :isAdminChart="true" />
            </div>
        </div>
        <div class="row chart-row">
            <div class="col-12 col-xl-6 most-listened-creators-container">
                <TotalAudioListensByWeekdaysChart :isAdminChart="true" />
            </div>
            <div class="col-12 col-xl-6 most-listened-creators-container">
                <ListenerGendersChart :isAdminChart="true" />
            </div>
        </div>
        <div class="row chart-row">
            <div class="col-12 col-xl-6 most-listened-creators-container">
                <TotalAudioListensByHoursChart :isAdminChart="true" />
            </div>
            <div class="col-12 col-xl-6 most-listened-creators-container">

            </div>
        </div>

        <h3 class="mt-3">End of administration</h3>
    </div>
    <div v-if="user && user.isCreator">
        <div class="top-cards-container">
            <div class="card card-dark top-card username-card" title="Profile name">
                <div class="top-card-body card-body">
                    <img src="/icons/profile.svg" alt="profile icon"/>
                    <div class="top-card-amount">
                        <h3 v-if="creator.profile" class="rows-1">{{ creator.profile.name }}</h3>
                        <div v-else class="dot-flashing"></div>
                    </div>
                </div>
            </div>

            <div class="card card-dark top-card" :title="creator.totalListenCount + ' listens total'">
                <div class="top-card-body card-body">
                    <img src="/icons/music.svg" alt="music icon"/>
                    <div class="top-card-amount">
                        <h3 v-if="creator.totalListenCount != null">{{ creator.totalListenCount }}</h3>
                        <div v-else class="dot-flashing"></div>
                    </div>
                </div>
            </div>

            <div class="card card-dark top-card" :title="creator.totalUpvoteCount + ' upvotes total'">
                <div class="top-card-body card-body">
                    <img src="/icons/heart.svg" alt="heart icon"/>
                    <div class="top-card-amount">
                        <h3 v-if="creator.totalUpvoteCount != null">{{ creator.totalUpvoteCount }}</h3>
                        <div v-else class="dot-flashing"></div>
                    </div>
                </div>
            </div>

            <div class="card card-dark top-card" :title="creator.publicAudioCount + ' public audios and ' + creator.privateAudioCount + ' private ones.'">
                <div class="top-card-body card-body">
                    <img src="/icons/microphone.svg" alt="microphone icon"/>
                    <div class="top-card-amount">
                        <h3 v-if="creator.publicAudioCount != null">{{ creator.publicAudioCount }}<span v-if="creator.privateAudioCount > 0">&nbsp;|&nbsp;{{ creator.privateAudioCount }}</span></h3>
                        <div v-else class="dot-flashing"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row chart-row">
            <div class="col-12">
                <TotalAudioListensByTimeChart v-if="creator.profile" :isAdminChart="false" :userId="creator.profile.userId" />
            </div>
        </div>

        <div class="row chart-row">
            <div class="col-12">
                <TotalAudioListenTimeChart v-if="creator.profile" :isAdminChart="false" :userId="creator.profile.userId" />
            </div>
        </div>

        <div class="row chart-row">
            <div class="col-12 col-xl-6 most-listened-creators-container">
                <SearchOccurenceByTimeChart v-if="creator.profile" :userId="creator.profile.userId"/>
            </div>
            <div class="col-12 col-xl-6 most-listened-creators-container">
                <MostListenedAudiosChart v-if="creator.profile" :isAdminChart="false" :userId="creator.profile.userId"/>
            </div>
        </div>
        <div class="row chart-row">
            <div class="col-12 col-xl-6 most-listened-creators-container">
                <MostListenedCategoriesChart v-if="creator.profile" :isAdminChart="false" :userId="creator.profile.userId" />
            </div>
            <div class="col-12 col-xl-6 most-listened-creators-container">
                <TotalAudioListensByWeekdaysChart v-if="creator.profile" :isAdminChart="false" :userId="creator.profile.userId" />
            </div>
        </div>
        <div class="row chart-row d-flex">
            <div class="col-12 col-xl-6 most-listened-creators-container">
                <ListenerGendersChart v-if="creator.profile" :isAdminChart="false" :userId="creator.profile.userId" />
            </div>
            <div class="col-12 col-xl-6 most-listened-creators-container">
                <TotalAudioListensByHoursChart v-if="creator.profile" :isAdminChart="false" :userId="creator.profile.userId" />
            </div>
        </div>
    </div>
</template>

<script>
    import requests from './../api/requests.js'
    import TotalAudioListensByTimeChart from './TotalAudioListensByTimeChart.vue'
    import TotalAudioListenTimeChart from './TotalAudioListenTimeChart.vue'
    import TotalAudioListensByWeekdaysChart from './TotalAudioListensByWeekdaysChart.vue'
    import TotalAudioListensByHoursChart from './TotalAudioListensByHoursChart.vue'
    import MostListenedCreatorsChart from './MostListenedCreatorsChart.vue'
    import MostListenedAudiosChart from './MostListenedAudiosChart.vue'
    import MostListenedCategoriesChart from './MostListenedCategoriesChart.vue'
    import ListenerGendersChart from './ListenerGendersChart.vue'
    import SearchOccurenceByTimeChart from './SearchOccurenceByTimeChart.vue'
    import { setMetadata } from '../utils.js'

    export default {
        name: 'AnalyticsPage',
        components: {
            TotalAudioListensByTimeChart,
            TotalAudioListenTimeChart,
            TotalAudioListensByWeekdaysChart,
            TotalAudioListensByHoursChart,
            MostListenedCreatorsChart,
            MostListenedAudiosChart,
            MostListenedCategoriesChart,
            ListenerGendersChart,
            SearchOccurenceByTimeChart
        },
        data() {
            return {
                admin: {
                    totalListenCount: null,
                    totalUpvoteCount: null,
                    publicProfileCount: null,
                    privateProfileCount: null,
                    publicAudioCount: null,
                    privateAudioCount: null,
                },
                creator: {
                    totalListenCount: null,
                    totalUpvoteCount: null,
                    profile: null,
                    publicAudioCount: null,
                    privateAudioCount: null,
                }
            }
        },
        computed: {
            user() {
                if (this.$store.getters.userData == null) {
                    return null
                }
                
                return this.$store.getters.userData.user
            }
        },
        methods: {
            getExpiration() {
                if (!this.$store) {
                    return
                }

                return this.$store.getters.userData
                    ? this.$store.getters.userData.expiration
                    : new Date()
            },
            async loadTotalListenCountAdmin() {
                const totalListenCountResult = await requests.UserContent.Audio.TotalListenCount()
                if (totalListenCountResult.isSuccess) {
                    this.admin.totalListenCount = totalListenCountResult.totalListenCount
                }
            },
            async loadTotalUpvoteCountAdmin() {
                const totalUpvoteCountResult = await requests.UserContent.Audio.TotalUpvoteCount()
                if (totalUpvoteCountResult.isSuccess) {
                    this.admin.totalUpvoteCount = totalUpvoteCountResult.totalUpvoteCount
                }
            },
            async loadProfileCountAdmin() {
                const profileCountResult = await requests.UserContent.UserProfiles.GetUserProfileCount()
                if (profileCountResult.isSuccess) {
                    this.admin.publicProfileCount = profileCountResult.publicCount
                    this.admin.privateProfileCount = profileCountResult.privateCount
                }
            },
            async loadAudioCountAdmin() {
                const audioCountResult = await requests.UserContent.Audio.Count()
                if (audioCountResult.isSuccess) {
                    this.admin.publicAudioCount = audioCountResult.publicCount
                    this.admin.privateAudioCount = audioCountResult.privateCount
                }
            },
            async loadUserProfileCreator() {
                const userId = this.$route.params.userId
                    ? this.$route.params.userId
                    : this.user.id

                const userProfileResult = await requests.UserContent.UserProfiles.GetUserProfileByUserId(userId)
                if (userProfileResult.isSuccess) {
                    this.creator.profile = userProfileResult.userProfile
                }
            },
            async loadTotalListenCountCreator() {
                const totalListenCountResult = await requests.UserContent.Audio.TotalListenCount(this.creator.profile.id)
                if (totalListenCountResult.isSuccess) {
                    this.creator.totalListenCount = totalListenCountResult.totalListenCount
                }
            },
            async loadTotalUpvoteCountCreator() {
                const totalUpvoteCountResult = await requests.UserContent.Audio.TotalUpvoteCount(this.creator.profile.id)
                if (totalUpvoteCountResult.isSuccess) {
                    this.creator.totalUpvoteCount = totalUpvoteCountResult.totalUpvoteCount
                }
            },
            async loadAudioCountCreator() {
                const audioCountResult = await requests.UserContent.Audio.Count(this.creator.profile.id)
                if (audioCountResult.isSuccess) {
                    this.creator.publicAudioCount = audioCountResult.publicCount
                    this.creator.privateAudioCount = audioCountResult.privateCount
                }
            },
        },
        async mounted() {
            if (this.user == null || (!this.user.isCreator && !this.user.isAdmin)) {
                this.$router.push('/')
                return
            }

            requests.initialize(this.$store)
            await requests.tryRefreshToken()
            setMetadata("Analytics | AudioLove", "With our detailed analytics you can craft the best content for your audience.")

            if (this.user.isAdmin) {
                await Promise.all([
                    this.loadTotalListenCountAdmin(),
                    this.loadTotalUpvoteCountAdmin(),
                    this.loadProfileCountAdmin(),
                    this.loadAudioCountAdmin()
                ])
            }

            await Promise.all([
                this.loadUserProfileCreator()
            ])

            await Promise.all([
                this.loadAudioCountCreator(),
                this.loadTotalListenCountCreator(),
                this.loadTotalUpvoteCountCreator()
            ])
        }
    }
</script>

<style scoped>
    .top-card {
        margin-bottom: 0.5rem;
        padding: 24px 32px 24px 32px !important;
    }

    .top-card-amount {
        margin-right: 0.5rem;
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    .top-card-body {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: white;
    }

    .top-card-body img {
        width: 36px;
    }

    .username-card {
        flex-grow: 2;
    }

    .top-cards-container {
        display: flex;
        align-items: center;
        flex-flow: row wrap;
        margin-bottom: 2rem;
        column-gap: 0.5rem;
    }

        .top-cards-container > div {
            flex-basis: 48%;
        }

            .top-cards-container > div.single-column {
                flex-basis: 100%;
            }

    @media only screen and (min-width: 70rem) {
        .top-cards-container {
            justify-content: flex-start;
        }

            .top-cards-container > div {
                flex-basis: 32%;
            }

                .top-cards-container > div.single-column {
                    flex-basis: 100%;
                }
    }

    @media only screen and (min-width: 90rem) {
        .top-cards-container > div {
            flex-basis: 20%;
        }

            .top-cards-container > div.single-column {
                flex-basis: 100%;
            }
    }

    .chart-row {
        display: flex;
        align-items: stretch;
    }

    .chart-row div {
        margin-bottom: 1rem;
    }

    .rows-1 {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>