<template>
    <div :style="['padding-left: ' + replyLevel * 2 + 'rem']" role="group" aria-label="Comment">
        <div class="profile" @click="redirectToProfile()">
            <img src="/icons/profile-circle.svg" alt="profile icon"/>&nbsp;
            <span class="color-white">
                {{ comment.userProfile.name }}
            </span>&nbsp;&nbsp;
            <span class="color-secondary">
                {{ comment.userProfile.handleName }}
            </span>&nbsp;&nbsp;
            <span class="color-secondary">
                {{ created }}
            </span>&nbsp;&nbsp;
            <span v-if="replyToProfile" class="color-white">
                - replying to {{ replyToProfile.name }}
            </span>
        </div>
        <div class="mt-2 comment-text">
            {{ comment.text }}
        </div>

        <div v-if="isLogged" class="reply pull-right mt-1 color-secondary" @click="replyTo()">
            Reply
            <fai :icon="['fas', 'reply']"></fai>
        </div>
        <div v-if="isLogged && canDelete" class="reply pull-right mt-1 color-secondary" @click="deleteComment()">
            Delete
            <fai :icon="['fas', 'trash']"></fai>
        </div>
    </div>
</template>

<script>
    import requests from './../api/requests.js'
    import Swal from 'sweetalert2'
    import { dateToTimeAgoString } from './../utils.js'

    export default {
        name: 'CommentCard',
        emits: ['reply-to', 'refresh-comments'],
        props: {
            comment: {
                default: null,
                type: Object
            },
            replyToProfile: {
                default: null,
                type: Object
            },
            audioAuthorId: {
                default: null,
                type: String
            },
            replyLevel: {
                default: 0,
                type: Number
            }
        },
        data() {
            return {
            }
        },
        methods: {
            redirectToProfile() {
                this.$router.push({ name: 'Profile', params: { handle: this.comment.userProfile.handleName } })
            },
            replyTo() {
                this.$emit("reply-to", this.comment.id)
            },
            async deleteComment() {
                Swal.fire({
                    position: 'center',
                    title: 'Are you sure?',
                    text: 'This will permanently delete the comment and its replies.',
                    confirmButtonText: 'Delete',
                    showCancelButton: true,
                    cancelButtonText: 'Cancel',
                    background: "#262639",
                    color: "white",
                    customClass: {
                        confirmButton: 'dialog-btn-primary',
                        denyButton: 'dialog-btn-primary',
                        cancelButton: 'dialog-btn-secondary'
                    },
                    buttonsStyling: false,
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        const deleteResult = await requests.UserContent.Audio.DeleteComment(this.comment.id, this.comment.id)

                        if (deleteResult.isSuccess) {
                            this.$emit("refresh-comments")
                        }
                    }
                })
            }
        },
        computed: {
            isLogged() {
                return this.$store.getters.userData != null
            },
            created() {
                return dateToTimeAgoString(this.comment.created)
            },
            canDelete() {
                return this.$store.getters.userData != null
                    && (this.$store.getters.userData.userProfileId == this.comment.userProfile.id
                        || this.$store.getters.userData.userProfileId == this.audioAuthorId
                        || this.$store.getters.userData.user.isAdmin)
            }
        },
        async mounted() {
            requests.initialize(this.$store)
        }
    }
</script>

<style scoped>
    .profile {
        cursor: pointer;
    }

    .reply {
        cursor: pointer;
        padding-left: 1rem;
        transition: color ease-in-out var(--def-transition-duration);
    }

        .reply:hover {
            color: var(--primary-color);
        }

    .comment-text {
        color: #ADADCB;
    }
</style>