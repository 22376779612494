<template>
    <div class="top-buttons">
        <button class="btn btn-secondary" @click="forgetAllSasTokens">Forget all SAS tokens</button>
        <button class="btn btn-secondary" @click="reindexSearch">Reindex search</button>
    </div>
    <h2>Reports</h2>
    <div class="card card-dark">
        <div class="card-body">
            <ReportsTable />
        </div>
    </div>
    <div>
        <ProfilesAdminTable />
    </div>
</template>

<script>
    import requests from './../api/requests.js'
    import ReportsTable from './ReportsTable.vue'
    import ProfilesAdminTable from './ProfilesAdminTable.vue'
    import Swal from 'sweetalert2'
    import { setMetadata } from '../utils.js'

    export default {
        name: 'AdministrationPage',
        components: {
            ReportsTable,
            ProfilesAdminTable
        },
        data() {
            return {
                
            }
        },
        computed: {
            isAdmin() {
                if (this.$store.getters.userData) {
                    return this.$store.getters.userData.user.isAdmin
                }

                return false
            }
        },
        methods: {
            async forgetAllSasTokens() {
                Swal.fire({
                    title: 'Forget all SAS tokens?',
                    showCancelButton: true,
                    confirmButtonText: 'Continue',
                    showLoaderOnConfirm: true,
                    background: "#262639",
                    color: "white",
                    customClass: {
                        confirmButton: 'dialog-btn-primary',
                        denyButton: 'dialog-btn-primary',
                        cancelButton: 'dialog-btn-secondary'
                    },
                    buttonsStyling: false,
                    preConfirm: async () => {
                        const response = await requests.UserContent.Audio.ForgetAllSasTokens()

                        if (response.isSuccess) {
                            Swal.fire({
                                icon: 'success',
                                timer: 1000,
                                showConfirmButton: false,
                                background: "#262639",
                                color: "white",
                            })
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Something went wrong!',
                                background: "#262639",
                                color: "white",
                            })
                        }
                    }
                })
            },
            async reindexSearch() {
                Swal.fire({
                    title: 'Reindex search?',
                    text: 'This can take up to a few minutes to take effect.',
                    showCancelButton: true,
                    confirmButtonText: 'Continue',
                    showLoaderOnConfirm: true,
                    background: "#262639",
                    color: "white",
                    customClass: {
                        confirmButton: 'dialog-btn-primary',
                        denyButton: 'dialog-btn-primary',
                        cancelButton: 'dialog-btn-secondary'
                    },
                    buttonsStyling: false,
                    preConfirm: async () => {
                        const response = await requests.UserContent.SearchContent.ReindexSearch()

                        if (response.isSuccess) {
                            Swal.fire({
                                icon: 'success',
                                timer: 1000,
                                showConfirmButton: false,
                                background: "#262639",
                                color: "white"
                            })
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Something went wrong!',
                                background: "#262639",
                                color: "white"
                            })
                        }
                    }
                })
            }
        },
        async mounted() {
            requests.initialize(this.$store)
            setMetadata("Administration | AudioLove")

            if (!this.isAdmin) {
                this.$router.push('/')
            }
        }
    }
</script>

<style scoped>
    .top-buttons {
        margin-bottom: 1rem;
        display: flex;
        justify-content: flex-start;
        gap: 1rem;
    }
</style>