<template>
    <div class="banner-container">
        <DiscordBanner />
    </div>
    <div ref="searchContainer">
        <SearchBox @search="search" @togglePreferences="togglePreferences()"></SearchBox>
    </div>
    <div class="filters" style="height: 0px;" ref="filters">
        <div class="mt-3">
            <div class="filters-tip">Click <span class="color-green">once</span> to select what you like, click <span class="color-red">twice</span> to select what you don't.</div>
            <TagSelectContainer v-if="tagSave.tags"
                                :tags="tagSave.tags"
                                :statusesByTagIds="statusesByTagIds"
                                :canBeDeselected="true"
                                :displayTags="false"
                                @statusesChanged="statusesChanged"></TagSelectContainer>
        </div>
    </div>
    <div class="searching-by-title mt-4" style="transition: width linear 1s;">
        Searching By {{ (searchType == 'popularity' ? 'Popularity' : 'Time') }}
        <fai class="swap-searchtype-icon" :icon="['fas', 'right-left']" @click="(searchType == 'popularity' ? setSearchType('time') : setSearchType('popularity'))" :title="searchType == 'popularity' ? 'Search By Time' : 'Search By Popularity'"></fai>
    </div>
    <div :class="['mt-4']">
        <SearchContentContainer :searchContents="searchContents"
                                :isLoadingMore="isLoadingMore"
                                :isSingleColumn="isSingleColumn"
                                :loadedAllTime="loadedAllTime"
                                :scrollUpFunc="scrollUp"
                                :saveSearchPositionFunc="saveSearchPosition"
                                :statusesByTagIds="statusesByTagIds"
                                @load-more="loadMore"></SearchContentContainer>
    </div>
</template>

<script>
    import requests from './../api/requests.js'
    import SearchContentCard from './SearchContentCard.vue'
    import NothingMoreCard from './NothingMoreCard.vue'
    import SearchBox from './SearchBox.vue'
    import SearchContentContainer from './SearchContentContainer.vue'
    import TagSelectContainer from './TagSelectContainer.vue'
    import DiscordBanner from './DiscordBanner.vue'
    import { setMetadata } from '../utils.js'

    export default {
        name: 'SearchPage',
        components: {
            SearchContentCard,
            NothingMoreCard,
            SearchBox,
            SearchContentContainer,
            TagSelectContainer,
            DiscordBanner
        },
        data() {
            return {
                searchContents: null,
                loadCount: 15,
                loadedAllTime: null,
                isLoadingMore: false,
                query: "",
                searchType: "popularity",
                blockSaveSearchPosition: true,
                showFilters: false
            }
        },
        computed: {
            isSingleColumn() {
                return this.query != ""
            },
            searchPosition() {
                return this.$store.getters.searchPosition
            },
            tagSave() {
                return this.$store.getters.tagSave
            },
            statusesByTagIds() {
                const statusesByTagIds = {}

                for (const selectedId of this.$store.getters.tagSave.selectedTagIds) {
                    statusesByTagIds[selectedId] = "SELECTED"
                }
                for (const deselectedId of this.$store.getters.tagSave.deselectedTagIds) {
                    statusesByTagIds[deselectedId] = "DESELECTED"
                }

                return statusesByTagIds
            },
            filteredSelectedTagIds() {
                const filtered = []

                const genderPreferenceIds = new Set()
                if (this.tagSave.tags) {
                    for (const tag of this.tagSave.tags) {
                        if (tag.isGenderPreference) {
                            genderPreferenceIds.add(tag.id)
                        }
                    }
                }

                for (const selectedId of this.tagSave.selectedTagIds) {
                    if (!genderPreferenceIds.has(selectedId)) {
                        filtered.push(selectedId)
                    }
                }

                return filtered
            }
        },
        methods: {
            async search(query) {
                window.location.hash = "searched"

                this.query = query

                this.searchContents = null
                this.isLoadingMore = true
                this.loadedAllTime = null

                this.saveSearchPosition()

                const response = await requests.UserContent.SearchContent.SearchSkipTake(
                    this.query,
                    0,
                    this.loadCount * 2,
                    this.filteredSelectedTagIds,
                    this.$store.getters.tagSave.deselectedTagIds,
                    this.searchType
                )

                if (response.isSuccess) {
                    this.searchContents = response.results

                    if (response.results.length < this.loadCount * 2) {
                        this.loadedAllTime = new Date()
                    } 
                }

                this.isLoadingMore = false
            },
            async loadMore() {
                const currentTime = new Date()
                if (!this.isLoadingMore && Math.abs(currentTime - this.loadedAllTime) > 5000 && !this.loadedAllTime) {
                    this.isLoadingMore = true

                    const response = await requests.UserContent.SearchContent.SearchSkipTake(
                        this.query,
                        this.searchContents.length,
                        this.loadCount,
                        this.filteredSelectedTagIds,
                        this.$store.getters.tagSave.deselectedTagIds,
                        this.searchType
                    )

                    if (response.isSuccess) {
                        this.searchContents.push(...response.results)

                        if (response.results.length < this.loadCount) {
                            this.loadedAllTime = new Date()
                        } else {
                            this.loadedAllTime = null
                        }
                    }

                    this.isLoadingMore = false
                }
            },
            async loadTags() {
                const response = await requests.UserContent.Tag.GetAll()
                if (response.isSuccess) {
                    this.tagSave.tags = response.tags
                    this.$store.commit("setTagSave", this.tagSave)
                }
            },
            scrollUp() {
                this.$refs.searchContainer.scrollIntoView({ behavior: "smooth" })
            },
            saveSearchPosition() {
                if (!this.blockSaveSearchPosition) {
                    this.searchPosition.query = this.query
                    this.searchPosition.searchContents = this.searchContents
                    this.searchPosition.posY = window.scrollY
                }
            },
            loadSearchPosition() {
                this.query = this.searchPosition.query
                this.searchContents = this.searchPosition.searchContents
                window.scroll(0, this.searchPosition.posY)
            },
            async statusesChanged(tags, categories, genderPreferences) {
                const selectedTagIds = []
                const deselectedTagIds = []

                for (const genderPreference of genderPreferences) {

                    if (genderPreference.status == "SELECTED") {
                        selectedTagIds.push(genderPreference.tag.id)
                    } else if (genderPreference.status == "DESELECTED") {
                        deselectedTagIds.push(genderPreference.tag.id)
                    }                    
                }                

                for (const category of categories) {
                    if (category.status == "SELECTED") {
                        selectedTagIds.push(category.tag.id)
                    } else if (category.status == "DESELECTED") {
                        deselectedTagIds.push(category.tag.id)
                    }
                }

                for (const tag of tags) {
                    if (tag.status == "SELECTED") {
                        selectedTagIds.push(tag.tag.id)
                    } else if (tag.status == "DESELECTED") {
                        deselectedTagIds.push(tag.tag.id)
                    }
                }

                this.tagSave.selectedTagIds = selectedTagIds
                this.tagSave.deselectedTagIds = deselectedTagIds

                this.$store.commit("setTagSave", this.tagSave)

                await this.search(this.query)
            },
            async setSearchType(type) {
                this.searchType = type

                await this.search(this.query)
            },
            togglePreferences() {
                var filters = this.$refs.filters

                if (filters.style.height && filters.style.height !== '0px') {
                    filters.style.height = '0px'
                    filters.style.opacity = '0.2'
                } else {
                    var wrapper = filters.firstElementChild;
                    filters.style.height = (wrapper.scrollHeight + 20) + 'px'
                    filters.style.opacity = '1'
                }
            }
        },
        async mounted() {
            requests.initialize(this.$store)
            setMetadata(false, "AudioLove is audio porn community platform for erotic recordings, femdom and mommy content for every goon from gonewildaudio.")

            this.loadTags()

            if (window.location.hash != "#searched" || this.searchPosition.searchContents == null) {
                await this.search("")
            } else {
                this.loadSearchPosition()
            }

            this.blockSaveSearchPosition = false
        }
    }
</script>

<style scoped>    
    .filters-button {
        cursor: pointer;
        width: 100%;
        text-align: center;
    }

    .center-row {
        display: flex;
        justify-content: center;
    }

    .searching-by-title {
        font-size: 28px;
        font-weight: 600;
        line-height: 42px;
    }

    .swap-searchtype-icon {
        font-size: 18px;
        padding-left: 8px;
        transition: color ease-in-out var(--def-transition-duration);
        cursor: pointer;
    }

    .swap-searchtype-icon:hover {
        color: var(--primary-color);
    }

    .filters {
        overflow: hidden;
        transition: height var(--def-transition-duration) ease-out, opacity var(--def-transition-duration) ease-out;
    }

    .filters-tip {
        color: var(--secondary-color);
        padding-bottom: 16px;
    }

    .banner-container {
        padding-bottom: 22px;
    }
</style>