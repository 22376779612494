<template>
    <div class="row align-items-center">
        <div class="col-xl-4 pt-2">
            <div class="card card-dark card-invoices" @click="redirectToInvoiceTemplates()">
                <div class="card-top">
                    <h2>Invoices </h2>
                </div>
                <div class="card-bottom">
                    <h4><fai :icon="['fas', 'pen']"></fai></h4>
                </div>
            </div>
        </div>
        <div class="col-xl-4 pt-2">
            <div class="card card-dark">
                <div class="card-top">
                    <h2>To be invoiced: </h2>
                </div>
                <div class="card-bottom">
                    <h4 class="color-orange">{{ toBeInvoicedSum }}</h4>
                </div>
            </div>
        </div>
        <div class="col-xl-4 pt-2">
            <div class="card card-dark">
                <div class="card-top">
                    <h2>Total invoiced: </h2>
                </div>
                <div class="card-bottom">
                    <h4>{{ alreadyInvoicedSum }}</h4>
                </div>
            </div>
        </div>
    </div>
    <div>
        <AccountApplicationsTable />
    </div>
    <div>
        <AccountsTable />
    </div>
</template>

<script>
    import requests from './../api/requests.js'
    import AccountsTable from './AccountsTable.vue'
    import AccountApplicationsTable from './AccountApplicationsTable.vue'
    import { setMetadata, formatPrice } from '../utils.js'

    export default {
        name: 'MonetizationAdminPage',
        components: {
            AccountsTable,
            AccountApplicationsTable
        },
        data() {
            return {
                toBeInvoicedSum: "0",
                alreadyInvoicedSum: "0"
            }
        },
        computed: {
            isAdmin() {
                if (this.$store.getters.userData) {
                    return this.$store.getters.userData.user.isAdmin
                }

                return false
            }
        },
        methods: {
            redirectToInvoiceTemplates() {
                this.$router.push({ name: 'Invoice Admin' })
            },
            async loadPriceSums() {
                const sumsResult = await requests.Monetization.BillingItem.GetPriceSums()
                if (!sumsResult.isSuccess) {
                    this.toBeInvoicedSum = "fail"
                    this.alreadyInvoicedSum = "fail"
                    return
                }

                this.toBeInvoicedSum = formatPrice(sumsResult.toBeInvoicedPriceSum)
                this.alreadyInvoicedSum = formatPrice(sumsResult.invoicedPriceSum)
            }
        },
        async mounted() {
            requests.initialize(this.$store)
            setMetadata("Monetization Admin | AudioLove")

            if (!this.isAdmin) {
                this.$router.push('/')
            }

            await this.loadPriceSums()
        }
    }
</script>

<style scoped>
    .btn-primary {
        width: 200px;
    }

    .row .card {
        padding: 24px !important;
        padding-top: 16px !important;
        padding-bottom: 16px !important;
    }

    .card > .card-top {
        display: flex;
        justify-content: flex-start;
        padding-bottom: 42px;
    }

    .card-top > h2 {
        color: var(--primary-color);
        letter-spacing: 3px;
    }

    .card > .card-bottom {
        display: flex;
        justify-content: flex-end;
    }

    .card.card-dark.card-invoices {
        background-color: var(--primary-color) !important;
        color: var(--primary-background) !important;
        cursor: pointer;
    }

        .card.card-dark.card-invoices h2 {
            transition: letter-spacing linear var(--def-transition-duration);
        }

        .card.card-dark.card-invoices:hover h2 {
            letter-spacing: 10px;
        }

        .card.card-dark.card-invoices h2 {
            background-color: var(--primary-color) !important;
            color: var(--primary-background) !important;
        }
</style>