<template>
    <h1>Invoice Draft</h1>

    <div v-if="error" class="card card-dark color-red">
        {{ error }}
    </div>

    <div v-else>
        <div class="card card-dark">
            <div v-if="invoiceTemplate">
                <div class="mb-2">
                    <label>Created By</label>
                    <div class="form-group">
                        <span>
                            {{ invoiceTemplate.createdBy }}
                        </span>
                    </div>
                </div>
                <div class="mb-2">
                    <label>Profile</label>
                    <div class="form-group">
                        <span class="profile-name" @click="redirectToProfile()">
                            {{ profile.name }} @{{ profile.handleName }}
                        </span>
                    </div>
                </div>
                <div>
                    <label>Name</label>
                    <div class="form-group mb-1">
                        <input type="text" class="form-control" v-model="invoiceTemplate.name" aria-label="Invoice draft name">
                    </div>
                </div>

                <div class="mt-2">
                    <label>Not Invoiced Items</label>
                    <v-data-table-virtual v-model="selected"
                                          :headers="headers"
                                          :items="billingItems"
                                          item-key="id"
                                          height="500"
                                          showSelect>

                    </v-data-table-virtual>
                </div>
                <div class="row-right">
                    <h4 class="">Unique Number: {{ invoiceTemplate.uniqueNumber }}</h4>
                </div>
                <div class="row-right">
                    <h3 class="color-orange">Price: {{ invoicePrice }}</h3>
                </div>
            </div>
        </div>
        <div v-if="invoiceTemplate">
            <div class="row-right">
                <button :class="['btn', !canSave ? 'btn-primary' : 'btn-secondary']" @click="saveOrCreateInvoice()">
                    <span v-show="canSave && !isSaving">Save</span>
                    <fai v-show="canSave && isSaving" :icon="['fas', 'spinner']" class="spinner" spin></fai>
                    <span v-show="!canSave && canCreate && !isCreating">Create Invoice</span>
                    <fai v-show="!canSave && canCreate && isCreating" :icon="['fas', 'spinner']" class="spinner" spin></fai>
                    <span v-show="!canSave && !canCreate">Can not create empty invoice</span>
                </button>
                <button class="btn btn-secondary" @click="deleteInvoiceTemplate()">
                    <fai :icon="['fas', 'trash']"></fai>
                </button>
            </div>
            <div class="row-right" style="padding-top: 0;">
                <div v-if="savingError" class="color-red error">{{ savingError }}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import requests from './../api/requests.js'
    import { setMetadata, formatDateTimeNumeric, formatPrice } from '../utils.js'
    import Swal from 'sweetalert2'

    export default {
        name: 'InvoiceTemplatePage',
        components: {

        },
        data() {
            return {
                error: null,
                selected: [],
                billingItems: null,
                invoiceTemplate: null,
                profile: null,
                headers: [
                    { title: 'Created', key: 'created', align: 'end', value: item => formatDateTimeNumeric(item.timestamp) },
                    { title: 'Name', key: 'name' },
                    { title: 'Price', key: 'price', align: 'end', value: item => formatPrice(item.price) }
                ],
                originalInvoiceTemplateName: null,
                originalSelected: null,
                invoicePrice: 0,
                isSaving: false,
                isCreating: false,
                savingError: null
            }
        },
        computed: {
            isAdmin() {
                if (this.$store.getters.userData) {
                    return this.$store.getters.userData.user.isAdmin
                }

                return false
            },
            canSave() {
                return !this.areArraysEqualNoOrder(this.originalSelected, this.selected) || this.originalInvoiceTemplateName != this.invoiceTemplate.name
            },
            canCreate() {
                return this.selected.length > 0
            }
        },
        methods: {
            areArraysEqualNoOrder(arr1, arr2) {
                if (arr1.length !== arr2.length) {
                    return false;
                }
                const sortedArr1 = [...arr1].sort();
                const sortedArr2 = [...arr2].sort();
                for (let i = 0; i < sortedArr1.length; i++) {
                    if (sortedArr1[i] !== sortedArr2[i]) {
                        return false;
                    }
                }
                return true;
            },
            redirectToProfile() {
                this.$router.push({ name: 'Profile', params: { handle: this.profile.handleName } })
            },
            async loadInvoiceTemplate() {
                const invoiceTemplateResponse = await requests.Monetization.InvoiceTemplate.GetInvoiceTemplatesById(this.$route.params.id)
                if (!invoiceTemplateResponse.isSuccess) {
                    this.error = invoiceTemplateResponse.error
                    return
                }

                const profileResponse = await requests.UserContent.UserProfiles.GetUserProfileByUserId(invoiceTemplateResponse.invoiceTemplate.userId)
                if (!profileResponse.isSuccess) {
                    this.error = profileResponse.error
                    return
                }

                const billingItemsWithoutInvoiceResponse = await requests.Monetization.BillingItem.GetWithoutInvoiceByUserId(invoiceTemplateResponse.invoiceTemplate.userId)
                if (!billingItemsWithoutInvoiceResponse.isSuccess) {
                    this.error = billingItemsWithoutInvoiceResponse.error
                    return
                }

                this.profile = profileResponse.userProfile
                this.billingItems = billingItemsWithoutInvoiceResponse.billingItems
                this.invoiceTemplate = invoiceTemplateResponse.invoiceTemplate
                this.originalInvoiceTemplateName = this.invoiceTemplate.name

                this.selected = this.invoiceTemplate.billingItems.filter(x => !x.invoiceId).map(x => x.id)
                this.originalSelected = this.selected
            },
            async saveOrCreateInvoice() {
                if (this.canSave) {
                    if (this.isSaving) {
                        return
                    }
                    this.savingError = null
                    this.isSaving = true

                    const editResult = await requests.Monetization.InvoiceTemplate.EditInvoiceTemplate(this.invoiceTemplate.id, this.invoiceTemplate.name, this.selected)
                    if (!editResult.isSuccess) {
                        this.savingError = editResult.error
                        this.isSaving = false
                        return
                    }

                    await this.loadInvoiceTemplate()

                    this.isSaving = false
                } else if (this.canCreate) {
                    if (this.isCreating) {
                        return
                    }

                    const { value: formValue } = await Swal.fire({
                        title: "Create " + this.invoiceTemplate.name,
                        text: "Unique Number: " + this.invoiceTemplate.uniqueNumber,
                        focusConfirm: false,
                        background: "#262639",
                        color: "white",
                        customClass: {
                            confirmButton: 'dialog-btn-primary',
                            cancelButton: 'dialog-btn-secondary'
                        },
                        confirmButtonText: 'Create Invoice',
                        cancelButtonText: 'Cancel',
                        showConfirmButton: true,
                        showDenyButton: false,
                        showCancelButton: true,
                        buttonsStyling: false,
                        preConfirm: () => {
                            return "CREATE"
                        }
                    })

                    if (formValue != "CREATE") {
                        return
                    } 

                    this.savingError = null
                    this.isCreating = true

                    const createResult = await requests.Monetization.Invoice.CreateInvoiceFromTemplate(this.invoiceTemplate.id)
                    if (!createResult.isSuccess) {
                        this.savingError = createResult.error
                        this.isCreating = false
                        return
                    }

                    Swal.fire({
                        title: 'Invoice created!',
                        icon: 'success',
                        timer: 2000,
                        background: "#262639",
                        color: "white",
                        showCancelButton: false,
                        showDenyButton: false,
                        showConfirmButton: false,
                        didClose: () => {
                            this.isCreating = false
                            this.$router.go(-1)
                        }
                    });

                }
            },
            async deleteInvoiceTemplate() {
                const { value: formValue } = await Swal.fire({
                    title: "Are you sure?",
                    focusConfirm: false,
                    background: "#262639",
                    color: "white",
                    customClass: {
                        confirmButton: 'dialog-btn-primary',
                        cancelButton: 'dialog-btn-secondary'
                    },
                    confirmButtonText: 'Delete',
                    cancelButtonText: 'Cancel',
                    showConfirmButton: true,
                    showDenyButton: false,
                    showCancelButton: true,
                    buttonsStyling: false,
                    preConfirm: () => {
                        return "DELETE"
                    }
                })

                if (formValue != "DELETE") {
                    return
                } 

                const deleteResponse = await requests.Monetization.InvoiceTemplate.DeleteInvoiceTemplate(this.invoiceTemplate.id)
                if (!deleteResponse.isSuccess) {
                    this.savingError = deleteResponse.error
                    return
                }

                this.$router.go(-1)
            }
        },
        async mounted() {
            requests.initialize(this.$store)
            setMetadata("Invoice Draft | AudioLove")

            if (!this.isAdmin) {
                this.$router.push('/')
            }

            this.$watch("selected", () => {
                const selectedBillingItems = []
                const selected = new Set(this.selected)

                for (const billingItem of this.billingItems) {
                    if (selected.has(billingItem.id)) {
                        selectedBillingItems.push(billingItem)
                    }
                }

                this.invoicePrice = formatPrice(selectedBillingItems.reduce((accumulator, currentObject) => { return accumulator + (currentObject.invoiceId ? 0 : currentObject.price); }, 0))
            })

            await this.loadInvoiceTemplate()
        }
    }
</script>

<style scoped>
    h1 {
        font-weight: 600;
        letter-spacing: 4px;
        color: var(--primary-color);
        margin-bottom: 24px;
    }

    label {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        color: white;
    }

    .profile-name {
        cursor: pointer;
    }

        .profile-name:hover {
            color: var(--primary-color);
        }

    .row-right {
        padding-top: 16px;
        display: flex;
        justify-content: flex-end;
        gap: 8px;
    }

        .row-right .btn-secondary {
            padding: 16px 24px 16px 24px;
            border: 1px solid var(--primary-color) !important;
        }

    .error {
        border-radius: 5px;
        padding: 10px;
    }
</style>