<template>
    <h1>Edit Audio</h1>

    <div v-if="audio">
        <div v-if="error" class="background-pink error mb-2">{{ error }}</div>
        <div class="description card card-dark">
            <div class="card-body">
                <div class="description-upper">
                    <div v-if="audio.status != 3">
                        <img src="/icons/music.svg" alt="music icon"/>
                        {{ audio.listenCount }} listens
                    </div>
                    <div v-if="audio.status != 3">
                        <img src="/icons/heart.svg" alt="heart icon"/>
                        {{ audio.upvoteCount }} likes
                    </div>
                    <div v-if="audio.status == 3">
                        <fai :icon="['fas', 'xmark']"></fai>
                        Audio processing failed
                    </div>
                    <div>
                        <img src="/icons/calendar.svg" alt="calendar icon"/>
                        Upload&nbsp;date:&nbsp;{{ uploaded }}
                    </div>
                </div>
            </div>
        </div>
        <div v-if="audio.status != 3 && (!audio.isEditingLocked || isAdmin)" class="card card-dark mt-3">
            <div class="card-body">
                <form class="form">
                    <label>Name</label>
                    <div class="form-group mb-1">
                        <input type="text" class="form-control" v-model="audio.name" maxlength="128">
                    </div>
                    <label>Description</label>
                    <div class="form-group mb-3">
                        <textarea class="form-control" v-model="audio.description" style="min-height: 360px;" maxlength="2000"></textarea>
                    </div>
                    <div class="form-check text-left">
                        <label class="form-check-label">
                            <input class="form-check-input" type="checkbox" v-model="isPrivate">
                            <span class="form-check-sign"></span>
                            Private audio
                        </label>
                    </div>
                    <div v-if="isAdmin" class="form-check text-left">
                        <label class="form-check-label">
                            <input class="form-check-input" type="checkbox" v-model="audio.isEditingLocked">
                            <span class="form-check-sign"></span>
                            Editing locked
                        </label>
                    </div>
                </form>
                <div class="mt-4 mb-2">
                    <TagSelectContainer v-if="tags"
                                        :tags="tags"
                                        :statusesByTagIds="statusesByTagIds"
                                        :maxSelectedGenderPreferencesCount="1"
                                        :maxSelectedCategoriesCount="6"
                                        :maxSelectedTagsCount="15"
                                        @statusesChanged="statusesChanged"></TagSelectContainer>

                    <div v-if="isWaitingForTagResponse" class="center-row">
                        <div class="dot-flashing"></div>
                    </div>
                </div>
                <div class="pull-right">
                    <button :disabled="!((audio.name != savedName || audio.description != savedDescription || !tagsEqual || isPrivate != savedIsPrivate || audio.isEditingLocked != savedIsEditingLocked) && selectedGenderPreferenceIds.length >= 1)" :class="['btn', 'btn-primary']" @click="saveChanges()">
                        <fai v-if="isWaitingForResult" :icon="['fas', 'spinner']" spin></fai>
                        <div v-else>
                            Save
                        </div>
                    </button>
                </div>
            </div>
        </div>
        <div v-else class="card card-dark mt-3">
            <div class="card-body">
                <div class="center-row">
                    <fai :icon="['fas', 'lock']" size="xl"></fai>
                </div>
                <div class="center-row mt-2">
                    Editing is locked.
                </div>
            </div>
        </div>
        <div class="mt-3">
            <button class="btn btn-secondary color-white pull-right delete-button" @click="deleteAudio()">
                <fai :icon="['fas', 'trash']"></fai>
            </button>
        </div>
    </div>
</template>

<script>
    import requests from './../api/requests.js'
    import { formatDate } from './../utils.js'
    import Swal from 'sweetalert2'
    import TagSelectContainer from './TagSelectContainer.vue'
    import { setMetadata } from '../utils.js'

    export default {
        name: 'EditAudioPage',
        components: {
            TagSelectContainer
        },
        data() {
            return {
                audio: null,
                error: null,
                isWaitingForResult: false,
                savedName: null,
                savedDescription: null,
                savedGenderPreferenceIds: null,
                savedCategoryIds: null,
                savedTagIds: null,
                savedIsPrivate: null,
                savedIsEditingLocked: null,
                isPrivate: null,
                isWaitingForTagResponse: false,
                selectedGenderPreferenceIds: [],
                selectedCategoryIds: [],
                selectedTagIds: [],
                statusesByTagIds: {},
                tags: null
            }
        },
        computed: {
            isLogged() {
                return this.$store.getters.userData != null
            },
            isLoggedUsersAudio() {
                if (this.$store.getters.userData == null) {
                    return false
                }

                return this.$store.getters.userData.userProfileId == this.audio.profile.id
            },
            isAdmin() {
                if (this.$store.getters.userData) {
                    return this.$store.getters.userData.user.isAdmin
                }

                return false
            }, 
            uploaded() {
                return formatDate(this.audio.created)
            },
            tagsEqual() {
                if (this.savedGenderPreferenceIds.length !== this.selectedGenderPreferenceIds.length) {
                    return false
                }
                if (this.savedCategoryIds.length !== this.selectedCategoryIds.length) {
                    return false
                }
                if (this.savedTagIds.length !== this.selectedTagIds.length) {
                    return false
                }

                const savedGenderPreferenceSet = new Set()
                this.savedGenderPreferenceIds.forEach(x => savedGenderPreferenceSet.add(x))

                for (const genderPreferenceId of this.selectedGenderPreferenceIds) {
                    if (!savedGenderPreferenceSet.has(genderPreferenceId)) {
                        return false
                    }
                }

                const savedCategorySet = new Set()
                this.savedCategoryIds.forEach(x => savedCategorySet.add(x))

                for (const categoryId of this.selectedCategoryIds) {
                    if (!savedCategorySet.has(categoryId)) {
                        return false
                    }
                }

                const savedTagSet = new Set()
                this.savedTagIds.forEach(x => savedTagSet.add(x))

                for (const tagId of this.selectedTagIds) {
                    if (!savedTagSet.has(tagId)) {
                        return false
                    }
                }

                return true
            }
        },
        methods: {
            async saveChanges() {
                if (this.audio.name == this.savedName
                    && this.audio.description == this.savedDescription
                    && this.tagsEqual
                    && this.isPrivate == this.savedIsPrivate
                    && this.audio.isEditingLocked == this.savedIsEditingLocked) {
                    return
                }

                if (this.selectedGenderPreferenceIds.length < 1) {
                    return
                }

                if (!this.isWaitingForResult) {
                    this.isWaitingForResult = true

                    const response = await requests.UserContent.Audio.EditAudio(
                        this.$route.params.id,
                        this.audio.name,
                        this.audio.description,
                        {
                            tag: this.selectedTagIds,
                            category: this.selectedCategoryIds,
                            genderPreference: this.selectedGenderPreferenceIds
                        },
                        !this.isPrivate,
                        this.audio.isEditingLocked)
                    
                    if (response.isSuccess) {
                        this.savedName = this.audio.name
                        this.savedDescription = this.audio.description
                        this.savedIsPrivate = this.isPrivate
                        this.savedIsEditingLocked = this.audio.isEditingLocked

                        this.savedGenderPreferenceIds = []
                        for (const genderPreferenceId of this.selectedGenderPreferenceIds) {
                            this.savedGenderPreferenceIds.push(genderPreferenceId)
                        }

                        this.savedCategoryIds = []
                        for (const categoryId of this.selectedCategoryIds) {
                            this.savedCategoryIds.push(categoryId)
                        }

                        this.savedTagIds = []
                        for (const tagId of this.selectedTagIds) {
                            this.savedTagIds.push(tagId)
                        }
                    } else {
                        this.error = response.error
                    }

                    this.isWaitingForResult = false
                }
            },
            async getAudio() {
                const response = await requests.UserContent.Audio.GetAudioByIdIncludeProfile(this.$route.params.id)

                if (response.isSuccess) {
                    this.audio = response.audio
                    this.isPrivate = !response.audio.isPublic
                    this.profile = response.userProfile
                } else {
                    this.error = response.error
                }
            },
            async getTags() {
                this.isWaitingForTagResponse = true

                const tagsResponse = await requests.UserContent.Tag.GetAll()
                if (tagsResponse.isSuccess) {
                    this.tags = tagsResponse.tags
                }

                this.isWaitingForTagResponse = false
            },
            deleteAudio() {
                Swal.fire({
                    position: 'center',
                    title: this.audio.name,
                    text: 'Are you sure you want to delete the file?',
                    confirmButtonText: 'Delete',
                    showCancelButton: true,
                    cancelButtonText: 'Cancel',
                    background: "#262639",
                    color: "white",
                    customClass: {
                        confirmButton: 'dialog-btn-primary',
                        denyButton: 'dialog-btn-primary',
                        cancelButton: 'dialog-btn-secondary'
                    },
                    buttonsStyling: false,
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        const deleteResult = await requests.UserContent.Audio.DeleteAudio(this.audio.id)

                        if (deleteResult.isSuccess) {
                            this.$router.back()
                        }
                    }
                })
            },
            statusesChanged(tags, categories, genderPreferences) {
                const selectedGenderPreferenceIds = []
                const selectedCategoryIds = []
                const selectedTagIds = []

                for (const genderPreference of genderPreferences) {
                    if (genderPreference.status == "SELECTED") {
                        selectedGenderPreferenceIds.push(genderPreference.tag.id)
                    }
                }

                for (const category of categories) {
                    if (category.status == "SELECTED") {
                        selectedCategoryIds.push(category.tag.id)
                    }
                }

                for (const tag of tags) {
                    if (tag.status == "SELECTED") {
                        selectedTagIds.push(tag.tag.id)
                    }
                }

                this.selectedGenderPreferenceIds = selectedGenderPreferenceIds
                this.selectedCategoryIds = selectedCategoryIds
                this.selectedTagIds = selectedTagIds
            }
        },
        async mounted() {
            requests.initialize(this.$store)    
            setMetadata("Edit audio | AudioLove", "Edit your audio.")

            await this.getAudio()

            if (!this.isLoggedUsersAudio && !this.isAdmin) {
                this.$router.push({ name: 'Search' })
            }

            this.savedName = this.audio.name
            this.savedDescription = this.audio.description
            this.savedIsPrivate = !this.audio.isPublic
            this.savedIsEditingLocked = this.audio.isEditingLocked

            this.savedGenderPreferenceIds = []
            this.savedCategoryIds = []
            this.savedTagIds = []

            this.statusesByTagIds = {}
            this.selectedTagIds = []

            for (const genderPreference of this.audio.genderPreferences) {
                this.statusesByTagIds[genderPreference.id] = "SELECTED"
                this.savedGenderPreferenceIds.push(genderPreference.id)
                this.selectedGenderPreferenceIds.push(genderPreference.id)
            }

            for (const category of this.audio.categories) {
                this.statusesByTagIds[category.id] = "SELECTED"
                this.savedCategoryIds.push(category.id)
                this.selectedCategoryIds.push(category.id)
            }

            for (const tag of this.audio.tags) {
                this.statusesByTagIds[tag.id] = "SELECTED"
                this.savedTagIds.push(tag.id)
                this.selectedTagIds.push(tag.id)
            }

            await this.getTags()
        }
    }
</script>

<style scoped>
    .description-upper {
        display: flex;
        flex-flow: row;
        justify-content: space-around;
        font-size: 18px;
        gap: 6px;
    }

        .description-upper div {
            color: white;
        }

        .description-upper div span {
            color: white;
        }

    .description-lower {
        padding: 1rem;
        margin-top: 0.4rem;
    }

    .center-row {
        display: flex;
        justify-content: center;
    }

    .error {
        border-radius: 5px;
        padding: 10px;
        font-weight: 700;
    }

    @media only screen and (max-width: 60rem) {
        .description-upper {
            flex-flow: column;
        }
    }

    .title {
        font-size: 28px;
        font-weight: 600;
        line-height: 42px;
    }

    .delete-button {
        padding: 12px 16px 12px 16px;
        margin-bottom: 12px;
    }

    h1 {
        font-weight: 600;
        letter-spacing: 4px;
        color: var(--primary-color);
        margin-bottom: 24px;
    }
</style>