<template>
    <div class="box" role="button" :title="tag.description" @click="toggleSelect()" :aria-label="status == 'SELECTED' ? tag.name + ' - selected' : status == 'DESELECTED' ? tag.name + ' - deselected' : tag.name + ' - does not care'" :aria-pressed="status == 'SELECTED' ? 'true' : status == 'DESELECTED' ? 'false' : 'mixed'" :class="[status == 'SELECTED' ? 'selected' : '', status == 'DESELECTED' ? 'deselected' : '']">
        <span class="text">{{ tag.name }}</span>
    </div>
</template>

<script>
    export default {
        name: 'TagSelectButton',
        emits: ['statusChanged'],
        props: {
            tag: {
                default: null,
                type: Object
            },
            status: {
                default: "DEFAULT",
                type: String
            },
            canBeDeselected: {
                default: false,
                type: Boolean
            }
        },
        methods: {
            toggleSelect() {
                if (this.status == "DEFAULT") {
                    this.$emit("statusChanged", this.tag.id, "SELECTED")
                } else if (this.status == "SELECTED") {
                    this.$emit("statusChanged", this.tag.id, this.canBeDeselected ? "DESELECTED" : "DEFAULT")
                } else if (this.status == "DESELECTED") {
                    this.$emit("statusChanged", this.tag.id, "DEFAULT")
                }
            }
        }
    }
</script>

<style scoped>
    .box {
        border-radius: 6px;
        border: 1px solid #46424F;
        padding: 5px 10px 5px 10px;
        cursor: pointer;
        user-select: none;
        transition: background linear .2s;
    }

    .text {
        font-size: 16px;
        line-height: 24px;
        color: #ADADCB;
        transition: color linear .2s;
    }

    .selected {
        border-color: var(--color-green);
        background: var(--color-green-transparent);
    }

    .deselected {
        border-color: var(--color-red);
        background: var(--color-red-transparent);
        color: #FFFFFF;
    }

        .deselected .text {
            color: var(--color-red);
        }  
</style>