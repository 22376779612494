<template>
    <div class="row">
        <div class="col-lg-4 col-md-6 offset-lg-4 offset-md-3">
            <div v-if="!confirmed" class="card card-dark" @keydown.enter="changePassword()">
                <div class="card-body">
                    <form class="form">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <div class="input-group-text">
                                    <fai :icon="['fas', 'lock']"></fai>
                                </div>
                            </div>
                            <input type="password" class="form-control" placeholder="New password" v-model="newPassword" autocomplete="new-password">
                        </div>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <div class="input-group-text">
                                    <fai :icon="['fas', 'lock']"></fai>
                                </div>
                            </div>
                            <input type="password" class="form-control" placeholder="Confirm password" v-model="confirmPassword" autocomplete="new-password">
                        </div>
                    </form>
                </div>
                <button class="btn btn-primary mt-3" @click="changePassword()">
                    <span v-if="!isWaitingForResult">Change password</span>
                    <fai v-if="isWaitingForResult" :icon="['fas', 'spinner']" spin></fai>
                </button>
            </div>
            <div v-if="error" class="color-red error mb-2">{{ error }}</div>
            <div v-if="confirmed" class="card card-dark">
                <div class="card-body">
                    Password successfully reset.&nbsp;
                    <button class="btn btn-primary mt-3" @click="goToLogin()">Log In</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import requests from './../api/requests.js'
    import { setMetadata } from '../utils.js'

    export default {
        name: 'ConfirmPasswordResetPage',
        data() {
            return {
                error: null,
                isWaitingForResult: false,
                newPassword: "",
                confirmPassword: "",
                confirmed: false
            }
        },
        methods: {
            async changePassword() {
                if (this.isWaitingForResult) {
                    return
                }

                if (this.newPassword.length < 1) {
                    this.error = "Password must not be empty."
                    return
                }

                if (this.newPassword !== this.confirmPassword) {
                    this.error = "Passwords must be the same."
                    return
                }

                this.isWaitingForResult = true
                this.error = null

                const response = await requests.UserAccess.PasswordResets.ConfirmPasswordReset(this.$route.params.id, this.newPassword, this.confirmPassword)

                if (response.isSuccess) {
                    this.confirmed = true
                } else {
                    this.error = response.error
                }

                this.isWaitingForResult = false
            },
            goToLogin() {
                this.$router.push('/login')
            },
            async mounted() {
                requests.initialize(this.$store)
                setMetadata("Confirm password reset | AudioLove")
            }
        }
    }
</script>

<style scoped>

</style>