<template>
    <div class="navbar">
        <div>
            <div class="logo" @click="toIndexPage()">
                <span>
                    <img src="/icons/lovely.svg" alt="AudioLove logo"/>
                    AudioLove
                </span>
            </div>
        </div>
        <div>
            <div class="dropdown navbar-profile">
                <fai class="sidebar-toggle" :icon="['fas', isSidebarOpen ? 'xmark' : 'bars']" @click="toggleSidebar()"></fai>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'NavBar',
  components: {
  },
  data() {
      return {
          isOpen: false
      }
  },
  computed: {
      isSidebarOpen() {
          return this.$store.getters.layout.isSidebarOpen
      }
  },
  methods: {
      toggleSidebar() {
          this.$store.state.layout.isSidebarOpen = !this.isSidebarOpen
      },
      toIndexPage() {
          this.$router.push({ name: 'Search' })
      },
  }
}
</script>

<style scoped>
    .navbar {
        position: sticky;
        top: 0;
        z-index: 100;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.9rem 0.9375rem;
        padding-top: 1.5rem;
        background-image: linear-gradient(to bottom, #1e1e2f, transparent);
    }

    .navbar-header {
        text-transform: uppercase;
        font-size: 1.2rem;
    }

    .navbar-profile {
        cursor: pointer;
    }

    .sidebar-toggle {
        cursor: pointer;
        padding-right: 1rem;
    }

    @media screen and (min-width: 40rem) {
        .sidebar-toggle {
            visibility: hidden;
        }
    }

    .dropdown {
        position: relative;
        display: flex;
    }

    .dropdown-icon {
        padding-left: 10px;
        padding-right: 10px;
    }

    .dropdown:hover .dropdown-icon {
        color: #e14eca;
        transition: all ease-in-out 0.3s;
    }

    .logo {
        font-weight: 500;
        font-size: 23px;
        line-height: 27px;
        font-family: "Gotham Rounded", sans-serif;
    }

        .logo img {
            width: 27px;
            margin-bottom: 6px;
        }

        .logo span {
            cursor: pointer;
        }
</style>