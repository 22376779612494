<template>
    <div class="row">
        <div class="col-lg-6 col-md-8 offset-lg-3 offset-md-2">
            <div class="card card-dark" @keydown.enter="login">
                <div class="card-body">
                    <div class="title">
                        Log In
                    </div>
                    <form class="form">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <div class="input-group-text">
                                    <fai :icon="['fas', 'user']"></fai>
                                </div>
                            </div>
                            <input type="text" class="form-control" placeholder="Username" v-model="username" autocomplete="username">
                        </div>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <div class="input-group-text">
                                    <fai :icon="['fas', 'lock']"></fai>
                                </div>
                            </div>
                            <input type="password" class="form-control" placeholder="Password" v-model="password" autocomplete="current-password">
                        </div>
                    </form>
                    <button class="btn btn-primary btn-round btn-lg w-100 mb-4 mt-2" @click="login()">
                        <span v-if="!isWaitingForResult">Log In</span>
                        <fai v-if="isWaitingForResult" :icon="['fas', 'spinner']" spin></fai>
                    </button>
                    <div class="create-forgot mb-4">
                        <div>
                            <router-link to="/register" class="link">
                                CREATE ACCOUNT
                            </router-link>
                        </div>
                        <div class="text-right">
                            <router-link to="/password-reset" class="link">
                                FORGOT PASSWORD?
                            </router-link>
                        </div>
                    </div>
                    
                    <div class="row third-party-buttons mb-4">
                        <button @click="continueWithThirdParty('patreon')">
                            <img src="/icons/patreon.svg" title="Register using Patreon" alt="patreon icon" />
                            Log In with Patreon
                        </button>
                        <button @click="continueWithThirdParty('reddit')">
                            <img src="/icons/reddit.svg" title="Register using Reddit" alt="reddit icon" />
                            Log In with Reddit
                        </button>
                    </div>

                </div>
            </div>
            <div v-if="error" class="color-red error mb-2">{{ error }}</div>
        </div>
    </div>
</template>

<script>
import endpoints from "../api/endpoints"
import requests from "../api/requests"
import { setMetadata } from '../utils.js'

    export default {
        name: 'LoginPage',
        data() {
            return {
                username: "",
                password: "",
                isWaitingForResult: false,
                error: null
            }
        },
        methods: {
            getRedirectUri(thirdParty) {
                const host = window.location.host.replace("www.", "")

                let uri = "https://" + host + "/login/"

                if (host.includes("localhost"))
                    uri = "http://localhost:8001/login/"

                return uri + thirdParty
            },
            continueWithThirdParty(thirdParty) {
                window.location.href = endpoints.UserAccess.Authorization.OAuth(thirdParty, this.getRedirectUri(thirdParty))
            },
            async login(thirdParty, state) {
                if (!thirdParty && !state) {
                    if (this.isWaitingForResult) {
                        return
                    }

                    if (this.username.length < 1 || this.password.length < 1) {
                        this.error = "Username and password must bet set."
                        return
                    }
                }

                this.error = null

                this.isWaitingForResult = true

                const result = thirdParty && state
                    ? await this.$store.dispatch("loginWithState", { thirdParty: thirdParty, state: state })
                    : await this.$store.dispatch("login", { username: this.username, password: this.password })

                if (result && result.isAuthenticated) {
                    const historyResult = await requests.UserContent.UserHistory.GetUserHistoryById(this.$store.getters.userData.user.id)
                    if (historyResult.isSuccess) {
                        this.$store.commit("setUserHistory", historyResult.userHistory)
                    }

                    const profileResult = await requests.UserContent.UserProfiles.GetUserProfileByUserId(this.$store.getters.userData.user.id)
                    if (profileResult.isSuccess) {
                        this.$store.commit("setUserProfileId", profileResult.userProfile.id)
                        this.$store.commit("setHandle", profileResult.userProfile.handleName)
                    }

                    this.$router.push('/')
                } else if (result && !result.isAuthenticated) {
                    this.error = result.error
                }

                this.isWaitingForResult = false
            }
        },
        async mounted() {
            requests.initialize(this.$store)
            setMetadata("Login | AudioLove", "Log in to your AudioLove account.")

            if (this.$route.params.thirdParty) {
                const urlParams = new URLSearchParams(window.location.search);

                if (urlParams.get('state')) {
                    await this.login(this.$route.params.thirdParty, urlParams.get('state'))
                }
            }
        }
    }
</script>

<style scoped>
    .error {
        border-radius: 5px;
        padding: 10px;
    }

    .title {
        color: var(--primary-color);
        font-size: 28px;
        font-weight: 700;
        padding-bottom: 24px;
        letter-spacing: 4px;
    }

    .third-party-buttons {
        display: flex;
        justify-content: space-evenly;
        gap: 12px;
    }

    @media screen and (max-width: 100rem) {
        .third-party-buttons {
            flex-direction: column;
        }
    }

    .third-party-buttons button {
        border: 2px solid var(--secondary-color);
        border-radius: 10px;
        padding: 8px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
    }

        .third-party-buttons button:hover {
            border-color: var(--primary-color);
        }

    .third-party-buttons img {
        width: 28px;
    }

    .create-forgot {
        display: flex;
        justify-content: space-between;
    }
</style>