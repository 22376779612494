<template>
    <div v-if="follows && follows.length <= 0" class="tip">
        <fai :icon="['fas', 'circle-info']" />
        On this page you'll see people you follow.
    </div>
    <div class="content-table" ref="scrollElement">
        <FollowCard v-for="follow in follows" :follow="follow" @unfollow="unfollow"></FollowCard>
        <NothingMoreCard :scroll="scrollUp" :text="follows.length > 0 ? 'That\'s all the people you follow.' : 'You don\'t follow anyone yet.'"></NothingMoreCard>
    </div>
</template>

<script>
    import requests from '../api/requests.js'
    import FollowCard from './FollowCard.vue'
    import NothingMoreCard from './NothingMoreCard.vue'
    import { setMetadata } from '../utils.js'

    export default {
        name: 'PeoplePage',
        components: {
            FollowCard,
            NothingMoreCard
        },
        data() {
            return {
                follows: [],
                waitingForResponse: false,
            }
        },
        computed: {
            user() {
                if (this.$store.getters.userData == null) {
                    return null
                }

                return this.$store.getters.userData.user
            }
        },
        methods: {
            async unfollow(followId) {
                if (!this.waitingForResponse) {
                    this.waitingForResponse = true

                    const response = await requests.UserContent.Follow.Unfollow(followId)

                    if (response.isSuccess) {
                        await this.loadFollows()
                    }

                    this.waitingForResponse = false
                }
            },
            async loadFollows() {
                const followsResult = await requests.UserContent.UserProfiles.GetFollowsByProfileId(this.$store.getters.userProfileId)
                if (followsResult.isSuccess) {
                    this.follows = followsResult.follows
                }
            },
            scrollUp() {
                this.$refs.scrollElement.scrollIntoView({ behavior: "smooth" })
            }
        },
        async mounted() {
            if (this.user == null) {
                this.$router.push('/')
                return
            }

            requests.initialize(this.$store)
            setMetadata("People | AudioLove", "Follow your favourite audio porn voice actors.")

            await this.loadFollows()
        }
    }
</script>

<style scoped>
    .content-table {
        display: flex;
        flex-flow: column;
        row-gap: 1rem;
    }

        .content-table div {
            cursor: pointer;
        }

    @media only screen and (min-width: 70rem) {
        .content-table {
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;
            column-gap: 1%;
        }

            .content-table div {
                flex-basis: 49%;
            }

                .content-table div.single-column {
                    flex-basis: 100%;
                }
    }

    @media only screen and (min-width: 90rem) {
        .content-table div {
            flex-basis: 32.5%;
        }

            .content-table div.single-column {
                flex-basis: 100%;
            }
    }

    .center-row {
        display: flex;
        justify-content: center;
    }

    .tip {
        color: lightgray;
        font-size: 17px;
        margin-bottom: 0.5rem;
    }
</style>