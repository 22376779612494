<template>
    <div class="banner-container">
        <DiscordBanner />
    </div>
    <div ref="searchContainer">
        <SearchBox @search="search" :showPreferenceButton="false"></SearchBox>
    </div>
    <div class="title mt-4">
        Searching For {{ categoryName }}
    </div>
    <!--<div class="mt-2">
        <button v-show="searchType == 'popularity'" class="btn btn-tertiary btn-sm" @click="setSearchType('time')">Searching by popularity</button>
        <button v-show="searchType == 'time'" class="btn btn-tertiary btn-sm" @click="setSearchType('popularity')">Searching by time</button>
    </div>-->
    <div :class="[isSingleColumn ? 'col-lg-10 col-xl-8 offset-lg-1 offset-xl-2' : '', 'mt-4']">
        <SearchContentContainer :searchContents="searchContents"
                                :isLoadingMore="isLoadingMore"
                                :isSingleColumn="isSingleColumn"
                                :loadedAllTime="loadedAllTime"
                                :scrollUpFunc="scrollUp"
                                :saveSearchPositionFunc="saveSearchPosition"
                                :statusesByTagIds="statusesByTagIds"
                                @load-more="loadMore"></SearchContentContainer>
    </div>
</template>

<script>
    import requests from './../api/requests.js'
    import SearchContentCard from './SearchContentCard.vue'
    import NothingMoreCard from './NothingMoreCard.vue'
    import SearchBox from './SearchBox.vue'
    import SearchContentContainer from './SearchContentContainer.vue'
    import TagSelectContainer from './TagSelectContainer.vue'
    import DiscordBanner from './DiscordBanner.vue'
    import { setMetadata } from '../utils.js'

    export default {
        name: 'SearchTagPage',
        components: {
            SearchContentCard,
            NothingMoreCard,
            SearchBox,
            SearchContentContainer,
            TagSelectContainer,
            DiscordBanner
        },
        data() {
            return {
                searchContents: null,
                loadCount: 15,
                loadedAllTime: null,
                isLoadingMore: false,
                query: "",
                searchType: "popularity",
                blockSaveSearchPosition: true,
                showFilters: false
            }
        },
        computed: {
            isSingleColumn() {
                return this.query != ""
            },
            searchPosition() {
                return this.$store.getters.searchPosition
            },
            tagSave() {
                return this.$store.getters.tagSave
            },
            statusesByTagIds() {
                const statusesByTagIds = {}
                statusesByTagIds[this.$route.params.tagId] = "SELECTED"

                return statusesByTagIds
            },
            filteredSelectedTagIds() {
                return [this.$route.params.tagId]
            },
            categoryName() {
                if (!this.tagSave.tags) {
                    return ""
                }

                for (const tag of this.tagSave.tags) {
                    if (tag.id == this.$route.params.tagId) {
                        return tag.name
                    }
                }

                return ""
            }
        },
        methods: {
            async search(query) {
                window.location.hash = "searched"

                this.query = query

                this.searchContents = null
                this.isLoadingMore = true
                this.loadedAllTime = null

                this.saveSearchPosition()

                const response = await requests.UserContent.SearchContent.SearchSkipTake(
                    this.query,
                    0,
                    this.loadCount * 2,
                    this.filteredSelectedTagIds,
                    [],
                    this.searchType)

                if (response.isSuccess) {
                    this.searchContents = response.results

                    if (response.results.length < this.loadCount * 2) {
                        this.loadedAllTime = new Date()
                    }
                }

                this.isLoadingMore = false
            },
            async loadMore() {
                const currentTime = new Date()
                if (!this.isLoadingMore && Math.abs(currentTime - this.loadedAllTime) > 5000 && !this.loadedAllTime) {
                    this.isLoadingMore = true

                    const response = await requests.UserContent.SearchContent.SearchSkipTake(
                        this.query,
                        this.searchContents.length,
                        this.loadCount,
                        this.filteredSelectedTagIds,
                        [],
                        this.searchType)

                    if (response.isSuccess) {
                        this.searchContents.push(...response.results)

                        if (response.results.length < this.loadCount) {
                            this.loadedAllTime = new Date()
                        } else {
                            this.loadedAllTime = null
                        }
                    }

                    this.isLoadingMore = false
                }
            },
            async loadTags() {
                const response = await requests.UserContent.Tag.GetAll()
                if (response.isSuccess) {
                    this.tagSave.tags = response.tags
                    this.$store.commit("setTagSave", this.tagSave)
                }
            },
            scrollUp() {
                this.$refs.searchContainer.scrollIntoView({ behavior: "smooth" })
            },
            saveSearchPosition() {
                if (!this.blockSaveSearchPosition) {
                    this.searchPosition.query = this.query
                    this.searchPosition.searchContents = this.searchContents
                    this.searchPosition.posY = window.scrollY
                }
            },
            loadSearchPosition() {
                this.query = this.searchPosition.query
                this.searchContents = this.searchPosition.searchContents
                window.scroll(0, this.searchPosition.posY)
            },
            async setSearchType(type) {
                this.searchType = type

                await this.search(this.query)
            }
        },
        async mounted() {
            requests.initialize(this.$store)
            setMetadata(this.categoryName + " | AudioLove", "Discover the best " + this.categoryName + " audio porn.")

            await this.loadTags()
            setMetadata(this.categoryName + " | AudioLove", "Discover the best " + this.categoryName + " audio porn.")

            if (window.location.hash != "#searched" || this.searchPosition.searchContents == null) {
                await this.search("")
            } else {
                this.loadSearchPosition()
            }

            this.blockSaveSearchPosition = false
        }
    }
</script>

<style scoped>
    .filters-button {
        cursor: pointer;
        width: 100%;
        text-align: center;
    }

    .center-row {
        display: flex;
        justify-content: center;
    }

    .title {
        font-size: 28px;
        font-weight: 600;
        line-height: 42px;
        margin-bottom: 24px;
    }

    .banner-container {
        padding-bottom: 22px;
    }
</style>