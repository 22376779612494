<template>
    <div class="display-container" role="list" aria-label="Categories">
        <TagCard role="listitem" :tag="tag.tag" :status="tag.status" v-for="tag in tagsWithStatuses" :redirect="redirect"></TagCard>
    </div>
</template>

<script>
    import TagCard from './TagCard.vue'

    export default {
        name: 'TagDisplayContainer',
        components: {
            TagCard
        },
        props: {
            tags: {
                default: null,
                type: Array
            },
            statusesByTagIds: {
                default: {},
                type: Object
            },
            redirect: {
                default: false,
                type: Boolean
            }
        },
        data() {
            return {
                tagsWithStatuses: null
            }
        },
        methods: {
            
        },
        mounted() {
            this.tagsWithStatuses = []

            this.tags.forEach(x => {
                const tagWithStatus = { tag: x, status: this.statusesByTagIds[x.id] ? this.statusesByTagIds[x.id] : "DEFAULT" }

                this.tagsWithStatuses.push(tagWithStatus)
            })
        }
    }
</script>

<style scoped>
    .display-container {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        padding: 0;
        gap: 0.5rem;
    }
</style>