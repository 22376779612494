<template>
    <h4 class="title">Invoice Drafts</h4>
    <div class="card card-dark">
        <div class="card-body">
            <v-data-table-virtual :headers="headers"
                                  :items="invoiceTemplates"
                                  item-key="id"
                                  height="500">

                <template v-slot:item.profile.name="{ item }">
                    <div @click="redirectToProfile(item.profile.handleName)" class="col profile-name">{{ item.profile.name }}</div>
                </template>

                <template v-slot:item.id="{ item }">
                    <div class="col action">
                        <button @click="detail(item)" class="btn btn-sm btn-primary">Detail</button>
                    </div>
                </template>

            </v-data-table-virtual>
        </div>
    </div>
    <div v-if="error" class="color-red error mb-2">{{ error }}</div>

</template>

<script>
    import requests from './../api/requests.js'
    import { formatDateTimeNumeric, formatPrice } from './../utils.js'

    export default {
        name: 'InvoiceTemplatesTable',
        components: {

        },
        data() {
            return {
                invoiceTemplates: [],
                headers: [
                    { title: 'Created', key: 'created', align: 'end', value: item => formatDateTimeNumeric(item.created) },
                    { title: 'Invoice', key: 'name' },
                    { title: 'Profile', key: 'profile.name' },
                    {
                        title: 'Price', key: 'price', align: 'end',
                        value: item => formatPrice(item.billingItems.reduce((accumulator, currentObject) => { return accumulator + (currentObject.invoiceId ? 0 : currentObject.price); }, 0))
                    },
                    { title: 'Action', value: 'id', align: 'center' },
                ],
                error: null
            }
        },
        methods: {
            redirectToProfile(profileHandle) {
                this.$router.push({ name: 'Profile', params: { handle: profileHandle } })
            },
            detail(item) {
                this.$router.push({ name: 'Invoice Draft', params: { id: item.id } })
            },
            formatDateNumeric(date) {
                return formatDateTimeNumeric(date)
            },
            async loadInvoiceTemplates() {
                const response = await requests.Monetization.InvoiceTemplate.GetAllInvoiceTemplatesWithoutInvoice()
                if (!response.isSuccess) {
                    this.error = response.error
                    return
                }

                let invoiceTemplates = response.invoiceTemplates

                const userProfilesLookupResponse = await requests.UserContent.UserProfiles.LookupByUserIds(invoiceTemplates.map(x => x.userId))
                if (!userProfilesLookupResponse.isSuccess) {
                    this.error = userProfilesLookupResponse.error
                    return
                }

                const profilesByUserIdsDict = {}
                for (const profile of userProfilesLookupResponse.userProfiles) {
                    profilesByUserIdsDict[profile.userId] = profile
                }

                for (const invoiceTemplate of invoiceTemplates) {
                    if (!profilesByUserIdsDict[invoiceTemplate.userId]) {
                        invoiceTemplate.profile = null
                        continue
                    }

                    invoiceTemplate.profile = profilesByUserIdsDict[invoiceTemplate.userId]
                }

                this.invoiceTemplates = invoiceTemplates
            }
        },
        async mounted() {
            requests.initialize(this.$store)

            await this.loadInvoiceTemplates()
        }
    }
</script>

<style scoped>
    .title {
        margin-top: 32px;
        margin-bottom: 16px;
        letter-spacing: 1px;
    }

    .error {
        border-radius: 5px;
        padding: 10px;
    }

    .col {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
    }

        .col.profile-name {
            cursor: pointer;
        }

        .col.action {
            justify-content: center;
        }

            .col.action > button {
                width: 100px;
            }

    .new-draft-row {
        display: flex;
        justify-content: flex-end;
        margin-top: 24px;
    }

</style>
