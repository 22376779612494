<template>
    <div>
        <InvoiceTemplatesTable />
    </div>
    <div>
        <InvoicesTable />
    </div>
</template>

<script>
    import requests from './../api/requests.js'
    import InvoiceTemplatesTable from './InvoiceTemplatesTable.vue'
    import InvoicesTable from './InvoicesTable.vue'
    import { setMetadata } from '../utils.js'

    export default {
        name: 'InvoicesAdminPage',
        components: {
            InvoiceTemplatesTable,
            InvoicesTable
        },
        data() {
            return {

            }
        },
        computed: {
            isAdmin() {
                if (this.$store.getters.userData) {
                    return this.$store.getters.userData.user.isAdmin
                }

                return false
            }
        },
        methods: {

        },
        async mounted() {
            requests.initialize(this.$store)
            setMetadata("Invoice Admin | AudioLove")

            if (!this.isAdmin) {
                this.$router.push('/')
            }
        }
    }
</script>

<style scoped>
</style>