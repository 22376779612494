<template>
    <h4 class="title">Listen Heatmap</h4>
    <div class="card card-dark">
        <div class="card-body">
            <label class="tip"><fai :icon="['fas', 'circle-info']" /> Shows most listened parts of your audio.</label>
            <div class="chart-container">
                <div v-if="!chartData" class="dot-flashing"></div>
                <div v-else-if="notEnoughData">Not enough data. Try again tomorrow.</div>
                <canvas v-show="chartData && !notEnoughData" ref="chart" />
            </div>
        </div>
    </div>
</template>

<script>
    import requests from './../api/requests.js'
    import { chartDataFromChartPoints } from '../utils.js'
    import Chart from 'chart.js/auto'

    export default {
        name: 'ListenHeatmapChart',
        props: {
            audioId: {
                default: null,
                type: String
            }
        },
        data() {
            return {
                audio: null,
                selectedTime: "2",
                chartData: null,
                notEnoughData: false,
            }
        },
        computed: {
            user() {
                if (this.$store.getters.userData == null) {
                    return null
                }

                return this.$store.getters.userData.user
            }
        },
        methods: {
            async loadAudio() {
                const audioResult = await requests.UserContent.Audio.GetAudioByIdIncludeProfile(this.$route.params.id)
                if (audioResult.isSuccess) {
                    this.audio = audioResult.audio
                }
            },
            async loadData() {
                const result = await requests.Statistics.Charts.ListenHeatmap(
                    this.audioId,
                    this.audio.lengthSeconds)

                if (!result.isSuccess) {
                    return
                }

                this.chartData = chartDataFromChartPoints(result.chartPoints, "#A688FA", undefined, true)

                this.notEnoughData = this.chartData.datasets[0].data.every(x => x <= 0)

                if (!this.$refs.chart) {
                    return
                }

                if (this.chart) {
                    this.chart.data = this.chartData

                    this.chart.update()
                    return
                }

                const ctx = this.$refs.chart.getContext('2d');

                const config = {
                    type: 'line',
                    data: this.chartData,
                    options: {
                        plugins: {
                            legend: {
                                display: false
                            }
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        interaction: {
                            intersect: false,
                            mode: 'index',
                        },
                        scales: {
                            y: {
                                display: false,
                                min: 0,
                                max: 100
                            }
                        }
                    },
                }

                this.chart = new Chart(ctx, config);
            }
        },
        async mounted() {
            requests.initialize(this.$store)

            await this.loadAudio()
            await this.loadData()
        }
    }
</script>

<style scoped>
    .title {
        margin-top: 32px;
        margin-bottom: 16px;
        letter-spacing: 1px;
    }

    .time-select {
        width: 12rem;
        margin-bottom: 2rem;
    }

    .chart-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 10rem;
    }

    @media only screen and (min-width: 70rem) {
        .chart-container {
            height: 20rem;
        }
    }

    .tip {
        color: var(--color-orange);
        padding-bottom: 16px;
    }
</style>