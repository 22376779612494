<template>
    <div class="card card-dark">
        <div class="container" @click="redirect()">
            <div class="container-content">
                <div :title="follow.toProfile.name">
                    <h5 class="color-primary rows-2">
                        {{ follow.toProfile.name }}
                    </h5>
                    <span class="rows-1">
                        {{ follow.toProfile.followerCount }} followers
                    </span>
                    <span class="rows-2 mt-1">
                        {{ follow.toProfile.description }}
                    </span>
                    <span>Followed on {{ followedOn }}</span>
                </div>
            </div>
        </div>
        <div class="more-options">
            <button v-show="moreOptionsOpen" class="btn btn-secondary color-white" @click="unfollow()">
                <fai :icon="['fas', 'user-minus']"></fai>
                Unfollow
            </button>
            <button class="btn btn-secondary color-white" @click="moreOptionsOpen=!moreOptionsOpen">
                <fai :icon="['fas', moreOptionsOpen ? 'xmark' : 'ellipsis-vertical']"></fai>
            </button>
        </div>
    </div>
</template>

<script>
    import { formatDate } from './../utils.js'

    export default {
        name: 'FollowCard',
        emits: ['unfollow'],
        props: {
            follow: {
                default: null,
                type: Object
            },
        },
        data() {
            return {
                moreOptionsOpen: false
            }
        },
        computed: {
            followedOn() {
                return formatDate(this.follow.created)
            },
        },
        methods: {
            redirect() {
                if (this.follow.toProfile.id != "00000000-0000-0000-0000-000000000000") {
                    this.$router.push({ name: 'Profile', params: { handle: this.follow.toProfile.handleName } })
                }
            },
            async unfollow() {
                this.$emit("unfollow", this.follow.id)             
            }
        }
    }
</script>

<style scoped>
    .card {
        cursor: pointer;
        min-height: 8rem;
        padding: 1rem 0.5rem;
    }

    .container {
        display: flex;
        flex-flow: row;
        gap: 1rem;
        min-height: 7.5rem;
    }

        .container.higher {
            min-height: 8.75rem;
        }

    .card:hover .container-icon {
        color: #e14eca;
    }

    .container-icon {
        display: flex;
        flex-flow: row;
        align-items: center;
        transition: color ease-out 0.3s;
    }

        .container-icon:after {
            content: " ";
            height: 90%;
            width: 2px;
            background-color: gray;
            margin-left: 1rem;
        }

    .container-content {
        display: flex;
        flex-flow: row;
    }

    .profile-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .rows-2 {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .rows-1 {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .btn-secondary {
        min-width: 2.2rem;
    }

    .tag {
        font-size: 0.8rem;
        border: 1px solid gray;
        color: gray;
        border-radius: 5px;
        padding-left: 5px;
        padding-right: 5px;
    }

    .more-options {
        position: absolute;
        bottom: 0.5rem;
        right: 0.5rem;
        display: flex;
        flex-flow: row;
        column-gap: 0.5rem;
    }
</style>