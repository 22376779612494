<template>
    <div :class="['sidebar', isSidebarOpen ? '' : 'closed']">
        <div class="logo" @click="toIndexPage()">
            <span>
                <img src="/icons/lovely.svg" alt="AudioLove logo"/>
                AudioLove
            </span>
        </div>
        <nav class="sidebar-wrapper">
            <div class="sidebar-item">
                <router-link to="/" :class="['link', $route.name === 'Search' ? 'active' : '']" @click="close()">
                    <img src="/icons/search.svg" alt="search icon" />
                    Search
                </router-link>
            </div>

            <div class="sidebar-item" v-if="isLogged">
                <router-link to="/follows" :class="['link', $route.name === 'Follows' ? 'active' : '']" @click="close()">
                    <img src="/icons/heart.svg" alt="heart icon" />
                    Follows
                </router-link>
            </div>

            <div class="sidebar-item">
                <router-link to="/categories" :class="['link', $route.name === 'Categories' ? 'active' : '']" @click="close()">
                    <img src="/icons/clipboard.svg" alt="clipboard icon" />
                    Categories
                </router-link>
            </div>

            <div class="sidebar-item" v-if="isLogged">
                <router-link to="/people" :class="['link', $route.name === 'People' ? 'active' : '']" @click="close()">
                    <img src="/icons/people.svg" alt="people icon" />
                    People
                </router-link>
            </div>

            <hr v-if="!isLogged" />

            <div class="sidebar-item" v-if="!isLogged">
                <router-link to="/login" :class="['link', $route.name === 'Login' ? 'active' : '']" @click="close()">
                    <img src="/icons/profile.svg" alt="profile icon" />
                    Log In
                </router-link>
            </div>
            <div class="sidebar-item" v-if="!isLogged">
                <router-link to="/register" :class="['link', $route.name === 'Register' ? 'active' : '']" @click="close()">
                    <img src="/icons/clipboard.svg" alt="clipboard icon" />
                    Register
                </router-link>
            </div>

            <hr v-if="!isLogged" />

            <div class="sidebar-item" v-if="isCreator || isAdmin">
                <router-link to="/analytics" :class="['link', $route.name === 'Analytics' ? 'active' : '']" @click="close()">
                    <img src="/icons/chart.svg" alt="chart icon" />
                    Analytics
                </router-link>
            </div>

            <div class="sidebar-item" v-if="isLogged && handle">
                <router-link :to="{ name: 'Profile', params: { handle: handle } }" :class="['link', $route.name === 'Profile' ? 'active' : '']" @click="close()">
                    <img src="/icons/profile.svg" alt="profile icon" />
                    Profile
                </router-link>
            </div>

            <hr v-if="isLogged" />

            <div class="sidebar-item">
                <a :class="['link', $route.name === 'Upload' ? 'active' : '']" @click="upload()">
                    <img src="/icons/upload.svg" alt="upload icon" />
                    Upload
                </a>
            </div>

            <div class="sidebar-item">
                <router-link :to="{ name: 'Import' }" :class="['link', $route.name === 'Import' ? 'active' : '']" @click="close()">
                    <img src="/icons/download.svg" alt="download icon" />
                    Import
                </router-link>
            </div>

            <div class="sidebar-item" v-if="isAdmin">
                <router-link to="/administration" :class="['link', $route.name === 'Administration' ? 'active' : '']" @click="close()">
                    <img src="/icons/user-octagon.svg" alt="admin icon" />
                    Admin
                </router-link>
            </div>

            <div class="sidebar-item" v-if="isAdmin">
                <router-link to="/monetization" :class="['link', $route.name === 'Monetization' ? 'active' : '']" @click="close()">
                    <img src="/icons/dollar-circle.svg" alt="dollar icon" />
                    Monetization
                </router-link>
            </div>

            <div class="sidebar-item" v-if="isAdmin">
                <router-link to="/monetization-admin" :class="['link', $route.name === 'Monetization Admin' ? 'active' : '']" @click="close()">
                    <img src="/icons/dollar-circle.svg" alt="dollar icon" />
                    M. Admin
                </router-link>
            </div>

            <hr v-if="isLogged" />

            <div class="sidebar-item" v-if="isLogged">
                <router-link :to="{ name: 'Settings' }" :class="['link', $route.name === 'Settings' ? 'active' : '']" @click="close()">
                    <img src="/icons/settings.svg" alt="settings icon" />
                    Settings
                </router-link>
            </div>

            <div class="sidebar-item" v-if="isLogged">
                <span class="link" @click="logout()">
                    <img src="/icons/logout.svg" alt="logout icon" />
                    Log Out
                </span>
            </div>
        </nav>
    </div>
</template>

<script>
import Swal from 'sweetalert2'

export default {
    name: 'SideBar',
    computed: {
        isSidebarOpen() {
            return this.$store.getters.layout.isSidebarOpen
        },
        isLogged() {
            return this.$store.getters.userData != null
        },
        handle() {
            return this.$store.getters.userData.handle
        },
        isAdmin() {
            if (this.$store.getters.userData) {
                return this.$store.getters.userData.user.isAdmin
            }

            return false
        },
        isCreator() {
            if (this.$store.getters.userData) {
                return this.$store.getters.userData.user.isCreator
            }

            return false
        }
    },
    methods: {
        close() {
            this.$store.state.layout.isSidebarOpen = false
        },
        upload() {
            if (!this.isLogged) {
                Swal.fire({
                    position: 'center',
                    title: 'Account required.',
                    confirmButtonText: 'Log In',
                    denyButtonText: 'Register',
                    showDenyButton: true,
                    cancelButtonText: 'Cancel',
                    showCancelButton: true,
                    background: "#262639",
                    color: "white",
                    customClass: {
                        confirmButton: 'dialog-btn-primary',
                        denyButton: 'dialog-btn-primary',
                        cancelButton: 'dialog-btn-secondary'
                    },
                    buttonsStyling: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$router.push('/login')
                    } else if (result.isDenied) {
                        this.$router.push('/register')
                    }
                })
                return
            }

            this.$router.push('/upload')
            this.close()
        },
        logout() {
            this.$store.dispatch("logout")
            this.$router.push('/login')
        },
        toIndexPage() {
            this.$router.push({ name: 'Search' })
        },
    }
}
</script>

<style scoped>
    .sidebar {
        min-height: 100%;
        background-color: var(--secondary-background);
        position: fixed;
        width: 220px;
        height: 100%;
        padding: 15px;
        top: 0;
        display: block;
        overflow: auto;
        z-index: 10000;
        font-family: "Gotham Rounded", sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        transition: transform ease-in-out var(--def-transition-duration)
    }

    .sidebar-wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;
        opacity: 1;
        transition: opacity ease-in-out var(--def-transition-duration);
    }

    @media screen and (max-width: 40rem) {
        .closed {
            transform: translateX(-230px);
        }

            .closed .sidebar-wrapper {
                opacity: 0;
            }
    }

    @media screen and (min-width: 40rem) {
        .hide-menu {
            opacity: 0;
        }
    }

    .sidebar-item {
        cursor: pointer;
        border-radius: 6px;
    }

        .sidebar-item .link {
            text-decoration: none;
            color: white !important;
            display: block;
            width: 100%;
            height: 100%;
            padding: 12px 16px 12px 16px;
            font-size: 14px;
            line-height: 17px;  
            letter-spacing: 1px;
        }

            .sidebar-item img {
                margin-right: 6px;
            }

        .sidebar-item:hover {
            background-color: #414161;
        }

        .sidebar-item a.active {
            background-color: #414161;
            border-radius: 6px;
        }

    .logo {
        font-weight: 500;
        font-size: 23px;
        line-height: 27px;
        padding-top: 6px;
        padding-bottom: 45px;
    }

        .logo span {
            cursor: pointer;
        }

    .logo img {
        width: 27px;
        margin-bottom: 6px;
    }

    .hide-menu {
        display: flex;
        justify-content: flex-end;
        padding-right: 12px;
        transition: color ease-in-out var(--def-transition-duration);
    }

        .hide-menu:hover {
            cursor: pointer;
            color: var(--primary-color)
        }
</style>