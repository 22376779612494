<template>    
    <div ref="searchContainer">
        <div v-if="searchContents && searchContents.length <= 0" class="tip">
            <fai :icon="['fas', 'circle-info']" />
            On this page you'll see content of people you follow.
        </div>

        <SearchContentContainer :searchContents="searchContents"
                                :isLoadingMore="isLoadingMore"
                                :loadedAllTime="loadedAllTime"
                                :scrollUpFunc="scrollUp"
                                :saveSearchPositionFunc="saveSearchPosition"
                                @load-more="loadMore"></SearchContentContainer>
    </div>
</template>

<script>
    import requests from './../api/requests.js'
    import SearchContentCard from './SearchContentCard.vue'
    import NothingMoreCard from './NothingMoreCard.vue'
    import SearchBox from './SearchBox.vue'
    import SearchContentContainer from './SearchContentContainer.vue'
    import { setMetadata } from '../utils.js'

    export default {
        name: 'FollowsPage',
        components: {
            SearchContentCard,
            NothingMoreCard,
            SearchBox,
            SearchContentContainer
        },
        data() {
            return {
                searchContents: null,
                loadCount: 15,
                loadedAllTime: null,
                isLoadingMore: false,
                blockSaveSearchPosition: true
            }
        },
        computed: {
            searchPosition() {
                return this.$store.getters.searchPosition
            },
            user() {
                if (this.$store.getters.userData == null) {
                    return null
                }

                return this.$store.getters.userData.user
            }
        },
        methods: {
            async search(query) {
                window.location.hash = "loaded"

                this.query = query

                this.searchContents = null
                this.isLoadingMore = true
                this.loadedAllTime = null

                this.saveSearchPosition()

                const response = await requests.UserContent.UserProfiles.GetHomeContent(this.$store.getters.userProfileId, 0, this.loadCount * 2)

                if (response.isSuccess) {
                    this.searchContents = response.results

                    if (response.results.length < this.loadCount * 2) {
                        this.loadedAllTime = new Date()
                    }
                }

                this.isLoadingMore = false
            },
            async loadMore() {
                const currentTime = new Date()
                if (!this.isLoadingMore && Math.abs(currentTime - this.loadedAllTime) > 5000 && !this.loadedAllTime) {
                    this.isLoadingMore = true

                    const response = await requests.UserContent.UserProfiles.GetHomeContent(this.$store.getters.userProfileId, this.searchContents.length, this.loadCount)

                    if (response.isSuccess) {
                        this.searchContents.push(...response.results)

                        if (response.results.length < this.loadCount) {
                            this.loadedAllTime = new Date()
                        } else {
                            this.loadedAllTime = null
                        }
                    }

                    this.isLoadingMore = false
                }
            },
            scrollUp() {
                this.$refs.searchContainer.scrollIntoView({ behavior: "smooth" })
            },
            saveSearchPosition() {
                if (!this.blockSaveSearchPosition) {
                    this.searchPosition.query = this.query
                    this.searchPosition.searchContents = this.searchContents
                    this.searchPosition.posY = window.scrollY
                }
            },
            loadSearchPosition() {
                this.query = this.searchPosition.query
                this.searchContents = this.searchPosition.searchContents
                window.scroll(0, this.searchPosition.posY)
            }
        },
        async mounted() {
            if (this.user == null) {
                this.$router.push('/')
                return
            }

            requests.initialize(this.$store)
            setMetadata("Follows | AudioLove", "Follow your favourite audio porn voice actors.")

            if (window.location.hash != "#loaded" || this.searchPosition.searchContents == null) {
                await this.search("")
            } else {
                this.loadSearchPosition()
            }

            this.blockSaveSearchPosition = false
        }
    }
</script>

<style scoped>
    .tip {
        color: lightgray;
        font-size: 17px;
        margin-bottom: 0.5rem;
    }
</style>