<template>
    <div class="card card-dark mb-2" ref="scrollElement">
        <div class="card-body">
            <div v-if="!comments" class="center-row mt-4">
                <div class="dot-flashing"></div>
            </div>
            <div v-if="comments">
                <span class="color-white">
                    {{ comments.length }} Comments
                </span>
                <span v-if="commentReplyToName" class="color-white">
                    - replying to {{ commentReplyToName }}
                    <fai :icon="['fas', 'xmark']" class="close" @click="clearReply()"></fai>
                </span>
                <div v-if="error" class="background-pink error mb-2">{{ error }}</div>
                <div v-if="isLogged" class="create-comment mb-3">
                    <textarea ref="commentTextArea" class="form-control" v-model="commentText" maxlength="512" aria-label="Comment content"></textarea>
                </div>
                <div v-if="isLogged" class="add-comment-button">
                    <button class="btn btn-primary" @click="createComment()" aria-label="Create comment">
                        <span v-if="!isWaitingForResult">Add Comment</span>
                        <fai v-if="isWaitingForResult" :icon="['fas', 'spinner']" spin></fai>
                    </button>
                </div>
                <CommentCard v-for="comment in comments"
                             :comment="comment.comment"
                             :replyToProfile="comment.replyToProfile"
                             :replyLevel="comment.comment.replyToCommentId ? 1 : 0"
                             :audioAuthorId="authorId"
                             class="mt-3"
                             @reply-to="replyTo"
                             @refresh-comments="getComments">
                </CommentCard>
            </div>
        </div>
    </div>    
</template>

<script>
    import requests from './../api/requests.js'
    import CommentCard from './CommentCard.vue'

    export default {
        name: 'CommentContainer',
        components: {
            CommentCard,
        },
        props: {
            audio: {
                default: null,
                type: Object
            },
        },
        data() {
            return {
                error: null,
                comments: null,
                commentText: "",
                commentReplyToId: null,
                commentReplyToName: null,
                isWaitingForResult: false,
                authorId: null
            }
        },
        methods: {
            async getProfile() {
                const response = await requests.UserContent.UserProfiles.GetUserProfileById(this.audio.profileId)

                if (response.isSuccess) {
                    this.authorId = response.userProfile.id
                }
            },
            async getComments() {
                const response = await requests.UserContent.Audio.GetCommentsByAudioId(this.audio.id)

                if (response.isSuccess) {
                    this.comments = this.orderComments(response.comments)
                }
            },
            async createComment() {
                if (!this.isWaitingForResult) {
                    this.isWaitingForResult = true
                    this.error = null

                    const response = await requests.UserContent.Audio.CreateComment(this.audio.id, this.commentText, this.commentReplyToId)

                    if (response.isSuccess) {
                        this.commentText = ""
                        this.commentReplyToId = null

                        await this.getComments()
                    } else {
                        this.error = response.error
                    }

                    this.commentReplyToId = null
                    this.commentReplyToName = null

                    this.isWaitingForResult = false
                }
            },
            replyTo(id) {
                this.commentReplyToId = id
                this.commentReplyToName = this.comments.find(x => x.comment.id == id).comment.userProfile.name

                this.$refs.scrollElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
                this.$refs.commentTextArea.focus()
            },
            clearReply() {
                this.commentReplyToId = null
                this.commentReplyToName = null
            },
            orderComments(comments) {
                const replyDict = {}

                comments.forEach(x => {
                    replyDict[x.id] = x.replyToCommentId
                })

                const firstReplyDict = {}

                for (const [key, value] of Object.entries(replyDict)) {
                    let firstReplyId = null;
                    let currentReplyId = key
                    while (firstReplyId == null) {
                        const currentValue = replyDict[currentReplyId]
                        if (currentValue == null) {
                            firstReplyId = currentReplyId
                        } else {
                            currentReplyId = currentValue
                        }
                    }

                    firstReplyDict[key] = firstReplyId
                }

                const firstReplyDictReversed = {}

                for (const [key, value] of Object.entries(firstReplyDict)) {
                    if (!firstReplyDictReversed.hasOwnProperty(value)) {
                        firstReplyDictReversed[value] = [key]
                    } else {
                        firstReplyDictReversed[value].push(key)
                    }
                }

                const commentsByIdDict = {}
                comments.forEach(x => commentsByIdDict[x.id] = x)

                const ordered = []

                for (const [key, value] of Object.entries(firstReplyDictReversed)) {
                    for (let i = value.length - 1; i >= 0; i--) {
                        ordered.push({
                            comment: commentsByIdDict[value[i]],
                            replyToProfile: commentsByIdDict[value[i]].replyToCommentId == null
                                ? null
                                : commentsByIdDict[commentsByIdDict[value[i]].replyToCommentId].userProfile
                        })
                    }
                }

                return ordered
            }
        },
        computed: {
            isLogged() {
                return this.$store.getters.userData != null
            }
        },
        async mounted() {
            requests.initialize(this.$store)

            await this.getProfile()
            await this.getComments()
        }
    }
</script>

<style scoped>
    .title {
        font-size: 1.4rem;
    }

    .create-comment {
        display: flex;
        flex-flow: row;
        align-items: stretch;
        gap: 1rem;
    }

    .error {
        border-radius: 5px;
        padding: 10px;
        font-weight: 700;
    }

    .center-row {
        display: flex;
        justify-content: center;
    }

    .close {
        transition: color linear 0.2s;
        cursor: pointer;
    }

        .close:hover {
            color: var(--primary-color);
        }

    textarea {
        background-color: var(--tertiary-background) !important;
        border: 0 !important;
        min-height: 80px;
        max-height: 300px;
    }

    .add-comment-button {
        display: flex;
        justify-content: flex-end;
    }

        .add-comment-button button.btn-primary {
            width: 230px;
            padding: 16px 24px 16px 24px;
        }
</style>