<template>
    <h4 v-if="!userId" class="title">Invoices</h4>
    <div class="card card-dark">
        <h4 class="pb-24">
            Already invoiced: {{ priceSum }}
        </h4>
        <div class="card-body">
            <v-data-table :headers="headers"
                          :items="invoices"
                          item-key="id"
                          :items-per-page="pageSize"
                          density="compact">

                <template v-slot:item.profile.name="{ item }">
                    <div @click="redirectToProfile(item.profile.handleName)" class="col profile-name">{{ item.profile.name }}</div>
                </template>

                <template v-slot:item.id="{ item }">
                    <div class="col action">
                        <button @click="detail(item)" class="btn btn-sm btn-primary">
                            Detail
                        </button>
                    </div>
                </template>

                <template v-slot:bottom>
                    <div class="text-center pt-2">
                        <v-pagination v-model="page"
                                      :length="Math.ceil(totalInvoices / pageSize)"
                                      :total-visible="6"
                                      @update:modelValue="loadInvoices()"></v-pagination>
                    </div>
                </template>

            </v-data-table>
        </div>
    </div>
    <div v-if="error" class="color-red error mb-2">{{ error }}</div>

</template>

<script>
    import requests from './../api/requests.js'
    import { formatDate, formatPrice } from './../utils.js'

    export default {
        name: 'InvoicesTable',
        components: {

        },
        props: {
            userId: {
                default: null,
                type: String
            },
        },
        data() {
            return {
                invoices: [],
                totalInvoices: 0,
                priceSum: "0",
                pageSize: 5,
                page: 1,
                headers: [
                    { title: 'Invoiced', key: 'created', value: item => formatDate(item.created) },
                    { title: 'Profile', value: 'profile.name' },
                    { title: 'Title', value: 'name' },
                    { title: "Price", align: 'end', key: 'price', value: item => formatPrice(item.price) },
                    { title: "Action", align: 'center', value: 'id' },
                ],
                error: null
            }
        },
        methods: {
            redirectToProfile(profileHandle) {
                this.$router.push({ name: 'Profile', params: { handle: profileHandle } })
            },
            async loadInvoices() {
                const response = this.userId
                    ? await requests.Monetization.Invoice.GetAllInvoicesByUserIdSkipTake(this.userId, (this.page - 1) * this.pageSize, this.pageSize)
                    : await requests.Monetization.Invoice.GetAllInvoicesSkipTake((this.page - 1) * this.pageSize, this.pageSize)
                if (!response.isSuccess) {
                    this.error = response.error
                    return
                }

                let invoices = response.invoices
                this.totalInvoices = response.totalCount
                this.priceSum = formatPrice(response.priceSum)

                const userProfilesLookupResponse = await requests.UserContent.UserProfiles.LookupByUserIds(invoices.map(x => x.userId))
                if (!userProfilesLookupResponse.isSuccess) {
                    this.error = userProfilesLookupResponse.error
                    return
                }

                const profilesByUserIdsDict = {}
                for (const profile of userProfilesLookupResponse.userProfiles) {
                    profilesByUserIdsDict[profile.userId] = profile
                }

                for (const invoice of invoices) {
                    if (!profilesByUserIdsDict[invoice.userId]) {
                        invoice.profile = null
                        continue
                    }

                    invoice.profile = profilesByUserIdsDict[invoice.userId]
                }

                this.invoices = invoices
            },
            detail(item) {
                this.$router.push({ name: 'Invoice', params: { id: item.id } })
            }
        },
        async mounted() {
            requests.initialize(this.$store)

            await this.loadInvoices()
        }
    }
</script>

<style scoped>
    .title {
        margin-top: 32px;
        margin-bottom: 16px;
        letter-spacing: 1px;
    }

    .error {
        border-radius: 5px;
        padding: 10px;
    }

    .col {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
    }

        .col.profile-name {
            cursor: pointer;
        }

        .col.action {
            display: flex;
            justify-content: center;
        }

            .col.action > button {
                width: 110px;
            }

    .btn-sm.btn-primary {
        width: 10px !important;
        padding: 0 !important;
    }
</style>
