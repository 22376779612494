import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import jQuery from "jquery";

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'

import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css'

//fontawesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
    faBars, faHouse, faUser, faMagnifyingGlass,
    faRightToBracket, faUserPlus, faEnvelope, faLock,
    faLaptop, faGear, faSpinner, faUpload,
    faXmark, faCheck, faPlay, faPause,
    faTrash, faEllipsisVertical, faUpLong, faPlus,
    faHeart, faEye, faHeadphones, faPen,
    faFloppyDisk, faPaperPlane, faReply, faUserMinus,
    faUsers, faMinus, faBook, faStar,
    faHammer, faFlag, faCircleInfo, faBan,
    faChartSimple, faVolumeHigh, faCalendar, faVenusMars,
    faClock, faTemperatureHigh, faCircleExclamation, faDownload,
    faArrowDown, faArrowRight, faArrowLeft, faRightLeft
} from '@fortawesome/free-solid-svg-icons'

library.add(
    faBars, faHouse, faUser, faMagnifyingGlass,
    faRightToBracket, faUserPlus, faEnvelope, faLock,
    faLaptop, faGear, faSpinner, faUpload,
    faXmark, faCheck, faPlay, faPause,
    faTrash, faEllipsisVertical, faUpLong, faPlus,
    faHeart, faEye, faHeadphones, faPen,
    faFloppyDisk, faPaperPlane, faReply, faUserMinus,
    faUsers, faMinus, faBook, faStar,
    faHammer, faFlag, faCircleInfo, faBan,
    faChartSimple, faVolumeHigh, faCalendar, faVenusMars,
    faClock, faTemperatureHigh, faCircleExclamation, faDownload,
    faArrowDown, faArrowRight, faArrowLeft, faRightLeft
)

window.$ = jQuery

const darkTheme = {
    dark: false,
    colors: {
        //background: '#FFFFFF',
        surface: '#1E1E2F',
        //'surface-bright': '#000000',
        //'surface-light': '#EEEEEE',
        //'surface-variant': '#424242',
        //'on-surface-variant': '#EEEEEE',
        //primary: 'red',
        //'primary-darken-1': '#FFFFFF',
        //secondary: '#48A9A6',
        //'secondary-darken-1': '#018786',
        error: '#FF004F',
        info: '#F59460',
        success: '#32CD32',
        warning: '#F59460',
    },
    variables: {
        //'border-color': '#000000',
        //'border-opacity': 0.12,
        //'high-emphasis-opacity': 0.87,
        //'medium-emphasis-opacity': 0.60,
        //'disabled-opacity': 0.38,
        //'idle-opacity': 0.04,
        //'hover-opacity': 0.04,
        //'focus-opacity': 0.12,
        //'selected-opacity': 0.08,
        //'activated-opacity': 0.12,
        //'pressed-opacity': 0.12,
        //'dragged-opacity': 0.08,
        //'theme-kbd': '#212529',
        //'theme-on-kbd': '#FFFFFF',
        //'theme-code': '#F5F5F5',
        //'theme-on-code': '#000000',
    }
}

const vuetify = createVuetify({
    components,
    directives,
    theme: {
        defaultTheme: 'darkTheme',
        themes: {
            darkTheme
        }
    }
})

createApp(App)
    .use(router)
    .use(store)
    .use(vuetify)
    .component('fai', FontAwesomeIcon)
    .mount('#app')