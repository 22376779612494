<template>
    <div class="row">
        <h1>Edit Profile</h1>
        <div>
            <div class="card card-dark">
                <div class="card-body">
                    <div v-if="!isLoaded" class="loading-indicator mb-2">
                        <div class="dot-flashing"></div>
                    </div>
                    <form v-if="isLoaded" class="form">
                        <div class="name-handle mb-3">
                            <div>
                                <label>Name</label>
                                <div class="form-group mb-1">
                                    <input type="text" class="form-control" v-model="settings.name" aria-label="Profile name">
                                </div>
                            </div>
                            <div>
                                <label>Handle</label>
                                <div class="form-group mb-1">
                                    <input type="text" class="form-control" v-model="settings.handleName" aria-label="Handle">
                                </div>
                            </div>
                        </div>
                        <label>Description</label>
                        <div class="form-group mb-3">
                            <textarea class="form-control description" v-model="settings.description" aria-label="Profile description"></textarea>
                        </div>
                        <div class="form-check text-left">
                            <label class="form-check-label">
                                <input class="form-check-input" type="checkbox" v-model="settings.isPublic">
                                <span class="form-check-sign"></span>
                                Public profile
                            </label>
                        </div>
                    </form>                    
                    <form>
                        <div v-if="isLoaded" class="socials">
                            <div class="socials-title">
                                Socials
                            </div>
                            <div>
                                <div>
                                    <img src="/icons/reddit.svg" alt="reddit icon" />
                                    <span>reddit.com/user/</span>
                                </div>
                                <input type="text" class="form-control" v-model="settings.socials.reddit" aria-label="reddit">
                            </div>
                            <div>
                                <div>
                                    <img src="/icons/throne.svg" alt="throne icon" />
                                    <span>throne.com/</span>
                                </div>
                                <input type="text" class="form-control" v-model="settings.socials.throne" aria-label="throne">
                            </div>
                            <div>
                                <div>
                                    <img src="/icons/youtube.svg" alt="youtube icon" />
                                    <span>youtube.com/</span>
                                </div>
                                <input type="text" class="form-control" v-model="settings.socials.youtube" aria-label="youtube">
                            </div>
                            <div>
                                <div>
                                    <img src="/icons/instagram.svg" alt="instagram icon" />
                                    <span>instagram.com/</span>
                                </div>
                                <input type="text" class="form-control" v-model="settings.socials.instagram" aria-label="instagram">
                            </div>
                            <div>
                                <div>
                                    <img src="/icons/tiktok.svg" alt="tiktok icon" />
                                    <span>tiktok.com/</span>
                                </div>
                                <input type="text" class="form-control" v-model="settings.socials.tiktok" aria-label="tiktok">
                            </div>
                            <div>
                                <div>
                                    <img src="/icons/twitter.svg" alt="twitter icon" />
                                    <span>twitter.com/</span>
                                </div>
                                <input type="text" class="form-control" v-model="settings.socials.twitter" aria-label="twitter">
                            </div>
                            <div>
                                <div>
                                    <img src="/icons/patreon.svg" alt="patreon icon" />
                                    <span>patreon.com/</span>
                                </div>
                                <input type="text" class="form-control" v-model="settings.socials.patreon" aria-label="patreon">
                            </div>
                            <div>
                                <div>
                                    <img src="/icons/discord.svg" alt="discord icon" />
                                    <span>discord.com/invite/</span>
                                </div>
                                <input type="text" class="form-control" v-model="settings.socials.discord" aria-label="discord">
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div v-if="isLoaded" class="bottom-row" style="padding: 10px;">
                <div v-if="error" class="error error mb-2">{{ error }}</div>
                <div v-if="success" class="success success mb-2">{{ success }}</div>
                <button class="btn btn-primary" @click="saveProfile()">
                    <span v-if="!isWaitingForResult">Save</span>
                    <fai v-if="isWaitingForResult" :icon="['fas', 'spinner']" spin></fai>
                </button>
            </div>
        </div>
        <h1 class="mt-4">Edit Account</h1>
        <div class="mb-4">
            <div class="card card-dark">
                <div class="card-body">
                    <div v-if="user" class="login-options">
                        <div class="third-party-buttons">
                            <button @click="connectThirdParty('patreon')" :class="[user.isPatreonConnected ? 'connected' : 'not-connected']">
                                <img src="/icons/patreon.svg" title="Register using Patreon" alt="patreon icon" />
                                <span v-if="!user.isPatreonConnected">Connect&nbsp;Patreon</span>
                                <span v-else>
                                    Patreon&nbsp;Connected
                                </span>
                            </button>
                            <button @click="connectThirdParty('reddit')" :class="[user.isRedditConnected ? 'connected' : 'not-connected']">
                                <img src="/icons/reddit.svg" title="Register using Reddit" alt="reddit icon" />
                                <span v-if="!user.isRedditConnected">Connect&nbsp;Reddit</span>
                                <span v-else>
                                    Reddit&nbsp;Connected
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Swal from 'sweetalert2'
    import endpoints from './../api/endpoints.js'
    import requests from './../api/requests.js'
    import { setMetadata } from '../utils.js'

    export default {
        name: 'SettingsPage',
        data() {
            return {
                isLoaded: false,
                isWaitingForResult: false,
                error: null,
                success: null,
                profile: null,
                settings: {
                    name: null,
                    handleName: null,
                    description: null,
                    isPublic: false,
                    socials: {
                        reddit: null,
                        throne: null,
                        youtube: null,
                        instagram: null,
                        tiktok: null,
                        twitter: null,
                        patreon: null,
                        discord: null
                    }
                }
            }
        },
        computed: {
            user() {
                if (this.$store.getters.userData == null) {
                    return null
                }

                return this.$store.getters.userData.user
            },
            userData() {
                return this.$store.getters.userData
            }
        },
        methods: {
            async saveProfile() {
                if (this.isWaitingForResult) {
                    return
                }

                this.error = null
                this.success = null
                this.isWaitingForResult = true

                const result = await requests.UserContent.UserProfiles.ChangeUserProfileSettings(this.profile.id, this.settings)

                this.isWaitingForResult = false

                if (result && result.isSuccess) {
                    const profileResult = await requests.UserContent.UserProfiles.GetUserProfileByUserId(this.user.id)

                    if (profileResult && profileResult.isSuccess) {
                        this.profile = profileResult.userProfile
                        this.success = "Settings saved successfully!"
                        this.$store.commit("setHandle", profileResult.userProfile.handleName)
                        this.displaySettings()
                    } else if (profileResult && !profileResult.isSuccess) {
                        this.error = profileResult.error
                    }
                } else if (result && !result.isSuccess) {
                    this.error = result.error
                }
            },
            displaySettings() {
                this.settings = {}

                this.settings.name = this.profile.name
                this.settings.handleName = this.profile.handleName
                this.settings.description = this.profile.description
                this.settings.isPublic = this.profile.isPublic

                this.settings.socials = {
                    reddit: this.profile.socials.reddit,
                    throne: this.profile.socials.throne,
                    youtube: this.profile.socials.youtube,
                    instagram: this.profile.socials.instagram,
                    tiktok: this.profile.socials.tiktok,
                    twitter: this.profile.socials.twitter,
                    patreon: this.profile.socials.patreon,
                    discord: this.profile.socials.discord
                }
            },
            connectThirdParty(thirdParty) {
                if (thirdParty == "patreon" && this.user.isPatreonConnected){
                    return
                }
                if (thirdParty == "reddit" && this.user.isRedditConnected){
                    return
                }

                window.location.href = endpoints.UserAccess.Authorization.OAuth(thirdParty, this.getRedirectUri(thirdParty))
            },
            getRedirectUri(thirdParty) {
                const host = window.location.host.replace("www.", "")

                let uri = "https://" + host + "/settings/connect/"

                if (host.includes("localhost"))
                    uri = "http://localhost:8001/settings/connect/"

                return uri + thirdParty
            }
        },
        async mounted() {
            if (this.user == null) {
                this.$router.push('/')
                return
            }

            requests.initialize(this.$store)
            setMetadata("Settings | AudioLove", "Customize your profile. Be creative!")

            const profileResult = await requests.UserContent.UserProfiles.GetUserProfileByUserId(this.user.id)

            if (profileResult && profileResult.isSuccess) {
                this.profile = profileResult.userProfile
            } else if (profileResult && !profileResult.isSuccess) {
                this.error = profileResult.error
            }

            this.displaySettings()

            if (this.$route.params.thirdParty) {
                const thirdParty = this.$route.params.thirdParty

                const urlParams = new URLSearchParams(window.location.search);

                const state = urlParams.get('state')

                if (thirdParty && state) {
                    const response = await requests.UserAccess.Users.ConnectWithThirdPartyByState(this.user.id, thirdParty, state)
                    if (response.isSuccess) {
                        if (thirdParty == "patreon") {
                            this.user.isPatreonConnected = true
                        } else if (thirdParty == "reddit") {
                            this.user.isRedditConnected = true
                        }
                        this.$store.commit("setUserData", this.userData)

                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: 'Success!',
                            text: 'Account was connected.',
                            timer: 3000,
                            showConfirmButton: false,
                            background: "#262639",
                            color: "white",
                        })
                    } else if (response.isFatal) {
                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            title: 'Failure!',
                            text: response.error,
                            showConfirmButton: true,
                            background: "#262639",
                            color: "white",
                        })
                    }
                }
            }

            this.isLoaded = true
        }
    }
</script>

<style scoped>
    @media screen and (min-width: 65rem) {
        .card-dark {
            padding-left: 150px !important;
            padding-right: 150px !important;
        }
    }
    .error {
        font-weight: 500;
        color: var(--color-red);
        margin-right: 16px;
        margin-top: 6px;
    }

    .success {
        font-weight: 500;
        color: var(--color-green);
        margin-right: 16px;
        margin-top: 6px;
    }

    .spinner {
        width: 100%;
        text-align: center;
        color: #ba54f5;
    }

    .bottom-row {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

        .bottom-row button {
            width: 120px;
        }

    .description {
        min-height: 10rem;
    }

    .socials-title {
        font-size: 24px;
        line-height: 36px;
        font-weight: 600;
    }

    .socials {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-top: 2rem;
        color: white;
    }

        .socials div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 16px;
        }

        .socials div div {
            display: flex;
            justify-content: start;
            align-items: center;
            min-width: 230px;
        }

        .socials input {
        }

    @media screen and (max-width: 60rem) {
        .socials > div {
            flex-direction: column;
        }
    }

        .socials img {
            width: 24px;
        }

        label {
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
            color: white;
        }

        h1 {
            font-weight: 600;
            letter-spacing: 4px;
            color: var(--primary-color);
            margin-bottom: 24px;
        }

        .name-handle {
            display: flex;
            justify-content: space-between;
            gap: 16px;
        }

            .name-handle > div {
                width: 100%;
            }

        @media screen and (max-width: 40rem) {
            .name-handle {
                flex-direction: column;
            }
        }
    .loading-indicator {
        display: flex;
        justify-content: center;
    }

    .login-options {
        display: flex;
        flex-direction: column;
        gap: 16px;
        color: white;
    }

        .login-options .title {
            font-size: 24px;
            line-height: 36px;
            font-weight: 600;
        }

    .third-party-buttons {
        display: flex;
        justify-content: space-evenly;
        gap: 12px;
    }

    @media screen and (max-width: 100rem) {
        .third-party-buttons {
            flex-direction: column;
        }
    }

    .third-party-buttons button {
        border: 2px solid var(--secondary-color);
        border-radius: 10px;
        padding: 8px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
    }

        .third-party-buttons button.not-connected:hover {
            border-color: var(--primary-color);
        }

        .third-party-buttons button.connected {
            cursor: default;
            border-color: var(--primary-color);
        }

    .third-party-buttons img {
        width: 28px;
    }
</style>