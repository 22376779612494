<template>
    <h4 class="title">Listens By Gender</h4>
    <div class="card card-dark">
        <div class="card-body">
            <label class="tip"><fai :icon="['fas', 'circle-info']" /> Data is collected only from registered users.</label>
            <select v-model="selectedTime" class="form-select form-select-pink time-select" aria-label="Time selection" @change="loadData()">
                <option value="1">Last week</option>
                <option value="2">Last month</option>
                <option value="3">Last three months</option>
                <option value="4">Last six months</option>
                <option value="5">Last year</option>
                <option value="6">All time</option>
            </select>
            <div class="chart-container">
                <div v-if="!chartData" class="dot-flashing"></div>
                <div v-else-if="notEnoughData">Not enough data. Try again tomorrow.</div>
                <canvas v-show="chartData && !notEnoughData" ref="chart" />
            </div>
        </div>
    </div>
</template>

<script>
    import requests from './../api/requests.js'
    import { chartDataFromChartPoints, getTomorrowDate } from '../utils.js'
    import Chart from 'chart.js/auto'

    export default {
        name: 'ListenerGendersChart',
        props: {
            isAdminChart: {
                default: false,
                type: Boolean
            },
            isAudioChart: {
                default: false,
                type: Boolean
            },
            audioId: {
                default: null,
                type: String
            },
            userId: {
                default: null,
                type: String
            }
        },
        data() {
            return {
                selectedTime: "2",
                chartData: null,
                notEnoughData: false,
            }
        },
        methods: {
            async loadData() {
                const tomorrowDate = getTomorrowDate()
                const fromDate = this.getFromDate()

                const result = await requests.Statistics.Charts.ListenerGendersByTime(
                    this.audioId,
                    this.isAdminChart
                        ? null
                        : this.userId,
                    fromDate, tomorrowDate)

                if (!result.isSuccess) {
                    return
                }

                this.chartData = chartDataFromChartPoints(result.chartPoints, ["#A0A0A0", "#0E86D4", "#e14eca", "#055C9D", "#C71585", "#FFA500"])
                for (let i = 0; i < this.chartData.labels.length; i++) {
                    if (this.chartData.labels[i] == 0) {
                        this.chartData.labels[i] = "Unspecified"
                    } else if (this.chartData.labels[i] == 1) {
                        this.chartData.labels[i] = "Male"
                    } else if (this.chartData.labels[i] == 2) {
                        this.chartData.labels[i] = "Female"
                    } else if (this.chartData.labels[i] == 3) {
                        this.chartData.labels[i] = "Trans Male"
                    } else if (this.chartData.labels[i] == 4) {
                        this.chartData.labels[i] = "Trans Female"
                    } else if (this.chartData.labels[i] == 5) {
                        this.chartData.labels[i] = "Non-binary"
                    }
                }

                if (!this.$refs.chart) {
                    return
                }

                if (this.chart) {
                    this.chart.data = this.chartData

                    this.chart.update()
                    return
                }

                const ctx = this.$refs.chart.getContext('2d');

                const config = {
                    type: 'pie',
                    data: this.chartData,
                    options: {
                        plugins: {
                            legend: {
                                position: 'bottom'
                            }
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                    },
                }

                this.chart = new Chart(ctx, config);

                this.notEnoughData = this.chartData.datasets[0].data.every(x => x <= 0)
            },
            getFromDate() {
                const offset = (new Date()).getTimezoneOffset()

                if (this.selectedTime == "1") {
                    const weekBeforeDate = (new Date((new Date()).getTime() - (offset * 60 * 1000)))
                    weekBeforeDate.setDate(weekBeforeDate.getDate() - 7)
                    return weekBeforeDate.toISOString().split('T')[0]
                } else if (this.selectedTime == "2") {
                    const monthBeforeDate = (new Date((new Date()).getTime() - (offset * 60 * 1000)))
                    monthBeforeDate.setDate(monthBeforeDate.getDate() - 31)
                    return monthBeforeDate.toISOString().split('T')[0]
                } else if (this.selectedTime == "3") {
                    const threeMonthsBeforeDate = (new Date((new Date()).getTime() - (offset * 60 * 1000)))
                    threeMonthsBeforeDate.setDate(threeMonthsBeforeDate.getDate() - 93)
                    return threeMonthsBeforeDate.toISOString().split('T')[0]
                } else if (this.selectedTime == "4") {
                    const sixMonthsBeforeDate = (new Date((new Date()).getTime() - (offset * 60 * 1000)))
                    sixMonthsBeforeDate.setDate(sixMonthsBeforeDate.getDate() - 186)
                    return sixMonthsBeforeDate.toISOString().split('T')[0]
                } else if (this.selectedTime == "5") {
                    const yearBeforeDate = (new Date((new Date()).getTime() - (offset * 60 * 1000)))
                    yearBeforeDate.setDate(yearBeforeDate.getDate() - 365)
                    return yearBeforeDate.toISOString().split('T')[0]
                } else {
                    const allTimeDate = (new Date((new Date()).getTime() - (offset * 60 * 1000)))
                    allTimeDate.setDate(allTimeDate.getDate() - 10000)
                    return allTimeDate.toISOString().split('T')[0]
                }
            }
        },
        async mounted() {
            requests.initialize(this.$store)

            await this.loadData()
        }
    }
</script>

<style scoped>
    .title {
        margin-top: 32px;
        margin-bottom: 16px;
        letter-spacing: 1px;
    }

    .time-select {
        width: 12rem;
        margin-bottom: 2rem;
    }

    .chart-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 10rem;
    }

    @media only screen and (min-width: 70rem) {
        .chart-container {
            height: 20rem;
        }
    }

    .tip {
        color: var(--color-orange);
        padding-bottom: 16px;
    }
</style>