<template>
    <div class="content-table" ref="scrollElement">
        <div v-for="content in searchContents" :class="[isSingleColumn ? 'single-column' : '']">
            <SearchContentCard :audio="content.audio" 
                               :profile="content.userProfile"
                               :showDescription="isSingleColumn"
                               :statusesByTagIds="statusesByTagIds"></SearchContentCard>
        </div>
        <NothingMoreCard v-show="loadedAllTime" 
                         :scroll="scrollUpFunc" 
                         :class="[isSingleColumn ? 'single-column' : '']"></NothingMoreCard>
    </div>
    <div v-show="isLoadingMore" class="mt-4 center-row">
        <div class="dot-flashing"></div>
    </div>
    <div v-show="searchContents && !isLoadingMore && !loadedAllTime" class="mt-3 center-row">
        <button class="btn btn-secondary" @click="loadMore()">
            <fai :icon="['fas', 'plus']"></fai>
            Load more
        </button>
    </div>
</template>

<script>
    import requests from './../api/requests.js'
    import SearchContentCard from './SearchContentCard.vue'
    import NothingMoreCard from './NothingMoreCard.vue'

    export default {
        name: 'SearchContentContainer',
        emits: ['load-more'],
        props: {
            searchContents: {
                default: null,
                type: Array
            },
            isLoadingMore: {
                default: false,
                type: Boolean
            },
            isSingleColumn: {
                default: false,
                type: Boolean
            },
            loadedAllTime: {
                default: null,
                type: Date
            },
            scrollUpFunc: {
                default: null,
                type: Function
            },
            saveSearchPositionFunc: {
                default: null,
                type: Function
            },
            statusesByTagIds: {
                default: {},
                type: Object
            },
        },
        components: {
            SearchContentCard,
            NothingMoreCard,
        },
        data() {
            return {

            }
        },
        methods: {
            handleScroll(e) {
                let element = this.$refs.scrollElement
                if (!element) {
                    return
                }
                this.saveSearchPositionFunc()
                
                if (element.getBoundingClientRect().bottom < window.innerHeight) {
                    this.loadMore()
                }
            },
            loadMore() {
                this.$emit("load-more")
            }
        },
        mounted() {
            requests.initialize(this.$store)

            window.addEventListener("scroll", this.handleScroll)
        },
        beforeUnmount() {
            window.removeEventListener("scroll", this.handleScroll)
        }
    }
</script>

<style scoped>
    .content-table {
        display: flex;
        flex-flow: column;
        row-gap: 1rem;
    }

        .content-table div {
            cursor: pointer;
        }

    @media only screen and (min-width: 1550px) {
        .content-table {
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;
            column-gap: 1.2%;
        }

            .content-table div {
                flex-basis: 49%;
            }

            .content-table div.single-column {
                flex-basis: 100%;
            }
    }

    .center-row {
        display: flex;
        justify-content: center;
    }
</style>